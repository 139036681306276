import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest, call, all, takeEvery } from "redux-saga/effects";
import {
  getUserByToken,
  register,
  login,
  updateProfile,
  updateParentProfile,
  updateAdminProfile,
  loginQuest,
  checkEmailAddress,
  validateAUser,
  SessionExpireValue,
  fetchSessionExpiryDetails,
} from "./authCrud";
import { toast } from "../../../../app/components/Toastr";

export const actionTypes = {
  Login: "[Login] Action",
  Login_Quest: "[Login] Quest Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UpdateProfile: "[UpdateProfile] Action",
  UpdateParentProfile: "[UpdateParentProfile] Action",
  UpdateAdminProfile: "[UpdateAdminProfile] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetState: "[SetState] Auth",
  CheckEmail: "[User] Check EmaiL",
  ValidateUser: "[User] Validate User",
  SetUser: "[SetUser] Action",
  SessionValue: "[SessionValue] Action",
  GetSessionData: "[User] Get Session Data",
  SetSessionData: "[User] Set Session Data",
};

const initialAuthState = {
  authToken: undefined,
  user: undefined,
  show: true,
  meta: {},
  sessionData: {},
};

export const reducer = persistReducer(
  { storage, key: "auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;

        return {
          authToken: authToken ? `Bearer ${authToken}` : undefined,
          user: undefined,
        };
      }

      case actionTypes.Login_Quest: {
        const { authToken } = action.payload;

        return {
          authToken: authToken ? `Bearer ${authToken}` : undefined,
          user: undefined,
        };
      }

      case actionTypes.Logout: {
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetSessionData: {
        const { sessionData } = action.payload;
        return { ...state, sessionData };
      }

      case actionTypes.SessionValue: {
        const { value } = action.payload;
        return { ...state, value };
      }

      case actionTypes.SetState: {
        const { info, meta, show } = action.payload;

        return {
          ...state,
          show,
          authToken: { token: `Bearer ${info.token}` },
          user: info.user,
          meta: { ...meta },
        };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return {
          ...state,
          user: user,
          info: {
            ...state.info,
            user: user,
          },
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  loginUser: (result) => ({
    type: actionTypes.Login,
    payload: { result },
  }),
  loginQuest: (result) => ({
    type: actionTypes.Login_Quest,
    payload: { result },
  }),
  register: ({ authToken, history }) => ({
    type: actionTypes.Register,
    payload: { authToken, authToken },
  }),
  updateProfile: (result) => ({
    type: actionTypes.UpdateProfile,
    payload: { result },
  }),
  updateParentProfile: (result) => ({
    type: actionTypes.UpdateParentProfile,
    payload: { result },
  }),
  updateAdminProfile: (result) => ({
    type: actionTypes.UpdateAdminProfile,
    payload: { result },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  CheckEmail: (payload) => ({ type: actionTypes.CheckEmail, payload }),
  SetUser: (payload) => ({ type: actionTypes.SetUser, payload }),
  SessionValue: (payload) => ({ type: actionTypes.SessionValue, payload }),
  SetSessionData: (payload) => ({ type: actionTypes.SetSessionData, payload }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga({ payload }) {
    yield put(
      actions.setState({
        info: { user: undefined, token: undefined },
        meta: {
          login: {
            loading: true,
            error: false,
            message: null,
          },
        },
      })
    );

    try {
      const result = yield call(login, payload);
      yield put({ type: "enrolments/HIDE_MODAL" });
      yield put({ type: "trials/HIDE_MODAL" });
      return yield put(
        actions.setState({
          info: result.data.info,
        })
      );
    } catch (error) {
      console.log("Login error", error);
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error);
      }
      return yield put(
        actions.setState({
          info: { user: undefined, token: undefined },
          meta: {
            login: {
              loading: false,
              error: true,
              message: error.response.data.error,
            },
          },
        })
      );
    }
  });

  yield takeLatest(actionTypes.Login_Quest, function* loginQuestSaga({
    payload,
  }) {
    yield put(
      actions.setState({
        info: { user: undefined, token: undefined },
        meta: {
          login: {
            loading: true,
            error: false,
            message: null,
          },
        },
      })
    );

    try {
      const result = yield call(loginQuest, payload);
      yield put({ type: "enrolments/HIDE_MODAL" });
      yield put({ type: "trials/HIDE_MODAL" });

      return yield put(
        actions.setState({
          info: result.data.info,
          meta: null,
        })
      );
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error);
      }
      return yield put(
        actions.setState({
          info: { user: undefined, token: undefined },
          meta: {
            login: {
              loading: false,
              error: true,
              message: error.response.data.error,
            },
          },
        })
      );
    }
  });

  yield takeLatest(actionTypes.Register, function* registerSaga({ payload }) {
    yield put(
      actions.setState({
        info: { user: undefined, token: undefined },
        meta: {
          register: {
            loading: true,
            error: false,
            message: null,
          },
        },
      })
    );
    try {
      const { history, authToken } = payload;

      const result = yield call(register, authToken);
      if (result) {
        yield put(
          actions.setState({
            info: result.data.info,
            meta: null,
          })
        );
        return setTimeout(
          () => history.push("/settings/organisation-details"),
          200
        );
      }
    } catch (error) {
      console.log(error);
      return yield put(
        actions.setState({
          info: { user: undefined, token: undefined },
          meta: {
            register: {
              loading: false,
              error: true,
              message: error.response.data
                ? error.response.data.error
                : "Server Error" || "Internal Server Error",
            },
          },
        })
      );
    }
  });

  yield takeLatest(actionTypes.UpdateProfile, function* registerSaga({
    payload,
  }) {
    try {
      const {
        data: { results, message },
      } = yield call(updateProfile, payload);

      if (results) {
        yield put(
          actions.SetUser({
            user: results,
          })
        );
        yield put(actions.fulfillUser(results));
        toast.success(message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  });

  yield takeLatest(actionTypes.UpdateParentProfile, function* registerSaga({
    payload,
  }) {
    try {
      const {
        data: { results, message },
      } = yield call(updateParentProfile, payload);
      if (results) {
        yield put(actions.fulfillUser(results));
        toast.success(message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  });

  yield takeLatest(actionTypes.UpdateAdminProfile, function* registerSaga({
    payload,
  }) {
    try {
      const {
        data: { results, message },
      } = yield call(updateAdminProfile, payload);
      if (results) {
        yield put(actions.fulfillUser(results));
        toast.success(message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();

    yield put(actions.fulfillUser(user));
  });

  yield takeLatest(actionTypes.SessionValue, function* SessionValue(payload) {
    try {
      const results = yield call(SessionExpireValue, payload);
      if (results) {
        yield put(
          actions.setState({
            info: results.data.message,
          })
        );
      }
    } catch (error) {
      console.log(error, error.response);
      yield put(
        actions.setState({
          info: error.response.data.message,
        })
      );
    }
  });

  yield takeLatest(actionTypes.CheckEmail, function* checkEmail(payload) {
    try {
      const results = yield call(checkEmailAddress, { email: payload.email });
      if (results) {
        yield put(
          actions.setState({
            info: results.data.message,
          })
        );
      }
    } catch (error) {
      console.log(error, error.response);
      yield put(
        actions.setState({
          info: error.response.data.message,
        })
      );
    }
  });

  yield takeLatest(actionTypes.ValidateUser, function* validateALoggedInUser({
    payload,
  }) {
    try {
      const results = yield call(validateAUser, payload);
      if (results) {
        yield put(
          actions.SetUser({
            user: results.data,
          })
        );
      }
    } catch (error) { }
  });

  yield takeLatest(
    actionTypes.GetSessionData,
    function* getSessionExpiryDetails() {
      try {
        const result = yield call(fetchSessionExpiryDetails);
        let timeoutTime = result.data.result ? result.data.result.time : 1000;
        let timeout = 1000 * 60 * timeoutTime;

        yield put(
          actions.SetSessionData({
            sessionData: { ...result?.data?.result, timeout },
          })
        );
      } catch (error) {
        console.log({ error });
      }
    }
  );
}
