import {
  getAll,
  deleteCoupon,
  create,
  getSelected,
  updateSelected,
  validateCoupon,
} from "../../services/coupon";
import { actionTypes, actions } from "../actions/coupon";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    try {
      const result = yield call(getAll);
      return yield put(
        actions.setState({
          list: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.UpdateCoupon, function* updateSelectedCoupon(
    payload
  ) {
    try {
      const result = yield call(updateSelected, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.ValidateCoupon, function* validate(payload) {
    try {
      const result = yield call(validateCoupon, payload.payload);
      yield put({
        type: actionTypes.SetCouponMessage,
        selected: result.data,
      });
    } catch (error) {
      yield put({
        type: actionTypes.SetCouponMessage,
        selected: "Invalid Coupon!",
      });
    }
  });

  yield takeLatest(actionTypes.GetSelectedCoupon, function* getSelectedCoupon(
    payload
  ) {
    try {
      const result = yield call(getSelected, payload.payload);
      return yield put(
        actions.setSelectedCoupon({
          selected: result.data,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  });

  yield takeLatest(actionTypes.CreateCoupon, function* createCoupon(payload) {
    try {
      const result = yield call(create, payload.payload.values);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.DeleteCoupon, function* deleteSelectedCoupon(
    payload
  ) {
    try {
      const result = yield call(deleteCoupon, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success("Coupon deleted successfully!");
      return yield put(
        actions.setState({
          meta: null,
        })
      );
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });
}
