import { actionTypes } from "../actions/cronJobs";

const initialCouponReducer = {
  list: [],
  selected: {},
  meta: {},
};

const cronReducer = (state = initialCouponReducer, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list, selected } = action.payload;
      return {
        ...state,
        list,
        selected,
      };
    }

    default:
      return state;
  }
};

export default cronReducer;
