import {
  getAll,
  getFromParent,
  getSelected,
  createMakeupLesson,
  deleteMakeupLesson,
  updateMakeupLesson,
  updateExpiryDate,
  changeStatus,
} from "../../services/makeupLesson";
import { actionTypes, actions } from "../actions/makeupLesson";
import { actions as classActions } from "../actions/classes";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList({ payload }) {
    yield put(
      actions.SetLoading({
        loading: true,
      })
    );

    try {
      const result = yield call(getAll, payload);
      yield put(
        actions.SetLoading({
          loading: false,
        })
      );
      return yield put(
        actions.setState({
          list: result?.data?.results,
          count: result.data.count,
        })
      );
    } catch (error) {
      yield put(
        actions.SetLoading({
          loading: false,
        })
      );
      return toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.GetSelected, function* getSelectedbyId(payload) {
    try {
      const result = yield call(getSelected, payload.payload);
      return yield put(
        actions.SetSelected({
          selected: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.GetFromParent, function* getFromParentList(
    payload
  ) {
    try {
      const result = yield call(getFromParent, payload);
      return yield put(
        actions.SetFromParent({
          parentList: result.data.results,
        })
      );
    } catch (error) {
      return toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.CreateMakeupLesson, function* createMakeup(
    payload
  ) {
    try {
      const result = yield call(createMakeupLesson, payload.payload);
      return toast.success(result.data.message);
    } catch (error) {
      return toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.UpdateMakeupLesson, function* updateMakeup(
    payload
  ) {
    try {
      const result = yield call(updateMakeupLesson, payload.payload);

      toast.success(result.data.message);
      let newResult
      if (payload.payload?.makeup_page == undefined) {
        newResult = yield call(getAll, {
          payload: null,
        });
        yield put(
          actions.setState({
            list: newResult.data.results,
          })
        );
      }
      newResult = yield call(getFromParent, {
        payload: {
          id: result.data.updatedMakeupLesson.parent,
        },
      });

      return yield put(
        actions.SetFromParent({
          parentList: newResult.data.results,
        })
      );
    } catch (error) {
      return toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.ChangeStatus, function* changeStatusMakeup(
    payload
  ) {
    try {
      const result = yield call(changeStatus, payload.payload);

      toast.success(result.data.message);
      let newResult
      if (payload.payload?.makeup_page == undefined) {
        newResult = yield call(getAll, {
          payload: null,
        });
        yield put(
          actions.setState({
            list: newResult.data.results,
          })
        );
      }
      newResult = yield call(getFromParent, {
        payload: {
          id: result.data.updatedMakeupLesson.parent,
        },
      });

      return yield put(
        actions.SetFromParent({
          parentList: newResult.data.results,
        })
      );
    } catch (error) {
      return toast.error(error.response.data.error);
    }
  });

  yield takeLatest(
    actionTypes.UpdateExpiryDate,
    function* updateExpiryDateMakeup(payload) {
      try {
        const result = yield call(updateExpiryDate, payload.payload);

        toast.success(result.data.message);
        let newResult
        if (payload.payload.makeup_page == undefined) {
        newResult = yield call(getAll, {
          payload: null,
        });
        yield put(
          actions.setState({
            list: newResult.data.results,
          })
        );
        }

        newResult = yield call(getFromParent, {
          payload: {
            id: result.data.updatedMakeupLesson.parent,
          },
        });

        return yield put(
          actions.SetFromParent({
            parentList: newResult.data.results,
          })
        );
      } catch (error) {
        return toast.error(error.response.data.error);
      }
    }
  );

  yield takeLatest(actionTypes.DeletetMakeupLesson, function* deleteMakeup(
    payload
  ) {
    try {
      const result = yield call(deleteMakeupLesson, payload.payload);
      // yield put({
      //   type: actionTypes.GetList,
      // });
      return toast.success(result.data.message);
    } catch (error) {
      return toast.error(error.response.data.error);
    }
  });
}
