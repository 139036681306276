export const actionTypes = {
  GetList: "services/GET_LIST",
  SetState: "services/SET_STATE",
  CreateService: "services/CREATE_SERVICE",
  UpdateService: "services/UPDATE_SERVICE",
  DeleteService: "services/DELETE_SERVICE",
  GetSelectedService: "services/GET_SELECTED_SERVICE",
  SetSelectedService: "services/SET_SELECTED_SERVICE",
  GetCoachReport: "services/GET_COACH_REPORT",
  SetCoachReport: "services/SET_COACH_REPORT",
  GetReport: "services/GET_REPORT",
  SetReport: "services/SET_REPORT",
  GetEnrolmentsByService: "services/GET_ENROLMENTS_BY_SERVICE",
  SET_LOADING: "services/SET_LOADING",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  loading: (payload) => ({ type: actionTypes.SET_LOADING, payload }),
  CreateService: (payload) => ({
    type: actionTypes.CreateService,
    payload,
  }),
  UpdateService: (payload) => ({
    type: actionTypes.UpdateService,
    payload,
  }),
  GetSelectedService: (payload) => ({
    type: actionTypes.GetSelectedService,
    payload,
  }),
  SetSelectedService: (payload) => ({
    type: actionTypes.SetSelectedService,
    payload,
  }),

  GetReport: (payload) => ({
    type: actionTypes.GetReport,
    payload,
  }),
  SetReport: (payload) => ({
    type: actionTypes.SetReport,
    payload,
  }),
  GetList: (payload) => ({
    type: actionTypes.GetList,
    payload,
  }),

  GetCoachReport: (payload) => ({
    type: actionTypes.GetCoachReport,
    payload,
  }),
  SetCoachReport: (payload) => ({
    type: actionTypes.SetCoachReport,
    payload,
  }),
};
