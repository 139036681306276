import { getAll } from "../../services/lists";
import { actionTypes, actions } from "../actions/list";
import { put, takeLatest, call } from "redux-saga/effects";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    try {
      yield put(
        actions.SetLoading({
          loading: true,
        })
      );
      const result = yield call(getAll);
      yield put(
        actions.setState({
          list: result.data,
        })
      );
      yield put(
        actions.SetLoading({
          loading: false,
        })
      );
    } catch (error) {
      console.log(error);
      yield put(
        actions.setState({
          list: [],
        })
      );
      yield put(
        actions.SetLoading({
          loading: false,
        })
      );
    }
  });
}
