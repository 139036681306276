export const actionTypes = {
  GetList: "tutors/GET_LIST",
  GetGroupList: "tutors/GET_GROUP_LIST",
  SetState: "tutors/SET_STATE",
  CreateTutor: "tutors/CREATE_TUTOR",
  EditTutor: "tutors/EDIT_TUTOR",
  DeleteTutor: "tutors/DELETE_TUTOR",
  GetSelectedTutor: "tutors/GET_SELECTED_TUTOR",
  UpdateTutor: "tutors/UPDATE_TUTOR",
  GetSchedule: "tutors/GET_SCHEDULE",
  SetSchedule: "tutors/SET_SCHEDULE",
  AddMessage: "tutors/ADD_MESSAGE",
  EditMessage: "tutors/EDIT_MESSAGE",
  DeleteMessage: "tutors/DELETE_MESSAGE",
  GetListByUuid: "tutors/GET_LIST_BY_UUID",
  GetLessonsByTutorIds: "tutors/GET_LESSONS_BY_TUTOR_IDS",
  SetTutorLessons: "tutors/SET_TUTOR_LESSONS",
  SetLoading: "tutors/SET_LOADING",
  UpdateTutorLessons: "tutor/UPDATE_TUTOR_LESSONS",
  SetTutorLessonUpdate: "tutor/SET_TUTOR_LESSON_UPDATE",
};

export const actions = {
  SetTutorLessonUpdate: (payload) => ({
    type: actionTypes.SetTutorLessonUpdate,
    payload,
  }),
  loading: (payload) => ({ type: actionTypes.SetLoading, payload }),
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  SetTutorLessons: (payload) => ({
    type: actionTypes.SetTutorLessons,
    payload,
  }),
  createTutor: (payload) => ({ type: actionTypes.CreateTutor, payload }),
  editTutor: (payload) => ({ type: actionTypes.EditTutor, payload }),
  getSelectedTutor: (payload) => ({
    type: actionTypes.GetSelectedTutor,
    payload,
  }),
  getSchedule: (payload) => ({
    type: actionTypes.GetSchedule,
    payload,
  }),
  setSchedule: (payload) => ({
    type: actionTypes.SetSchedule,
    payload,
  }),
  AddMessage: (payload) => ({
    type: actionTypes.AddMessage,
    payload,
  }),
  EditMessage: (payload) => ({
    type: actionTypes.EditMessage,
    payload,
  }),
  DeleteMessage: (payload) => ({
    type: actionTypes.DeleteMessage,
    payload,
  }),
};
