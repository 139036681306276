import { getAll } from "../../services/lessonTypes";
import { actionTypes, actions } from "../actions/lessonTypes";
import { put, takeLatest, call } from "redux-saga/effects";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    yield put(
      actions.setState({
        meta: {
          list: {
            loading: true,
          },
        },
      })
    );
    try {
      const result = yield call(getAll);
      return yield put(
        actions.setState({
          list: result.data,
          meta: null,
        })
      );
    } catch (error) {
      return yield put(
        actions.setState({
          meta: {
            list: {
              loading: false,
            },
          },
        })
      );
    }
  });
}
