import {
  getAll,
  deleteVenue,
  create,
  getSelected,
  updateSelected,
  getAllVenuesByUuid,
  sendCSVFile,
  exportCSVFile,
  addMessage,
  editMessage,
  deleteMessage,
  getPrivateVenuesList,
} from "../../services/venues";
import { actionTypes, actions } from "../actions/venues";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    try {
      const result = yield call(getAll);
      yield put(
        actions.setState({
          list: result.data,
        })
      );
    } catch (error) {
      toast.error(error.response.data.error);
      console.log("Error: ", error);
    }
  });

  yield takeLatest(actionTypes.GetVenuePrivate, function* getPrivateVenues() {
    try {
      const result = yield call(getPrivateVenuesList);
      yield put(
        actions.setState({
          selectedLocations: result.data.results,
        })
      );
    } catch (error) {
      toast.error(error.response.data.error);
      console.log("Error: ", error);
    }
  });

  yield takeLatest(actionTypes.GetListByUuid, function* getListByUuid(payload) {
    yield put(
      actions.SetLoading({
        loading: true,
      })
    );
    try {
      const result = yield call(getAllVenuesByUuid, payload);

      yield put(
        actions.SetLoading({
          loading: false,
        })
      );

      yield put(
        actions.setState({
          selectedLocations: result.data.results,
        })
      );
    } catch (error) {
      yield put(
        actions.SetLoading({
          loading: false,
        })
      );
    }
  });

  yield takeLatest(actionTypes.AddMessage, function* addNewMessage(payload) {
    try {
      const result = yield call(addMessage, payload.payload);

      toast.success(result.data.message);

      return yield put(
        actions.getSelectedVenue({
          selected: result.data.results,
        })
      );
    } catch (error) {
      return toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.EditMessage, function* editNewMessage(payload) {
    try {
      const result = yield call(editMessage, payload.payload);

      toast.success(result.data.message);

      return yield put(
        actions.getSelectedVenue({
          selected: result.data.results,
        })
      );
    } catch (error) {
      return toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.DeleteMessage, function* deleteNewMessage(
    payload
  ) {
    try {
      const result = yield call(deleteMessage, payload.payload);

      toast.success(result.data.message);

      return yield put(
        actions.getSelectedVenue({
          selected: result.data.results,
        })
      );
    } catch (error) {
      return toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.ExportCSVFile, function* exportCsvFileItem(
    payload
  ) {
    try {
      toast.success("Your download will start shortly!");
      const result = yield call(exportCSVFile);
      return window.open(result.data.results, "_blank");
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.UpdateVenue, function* updateSelectedVenue(
    payload
  ) {
    try {
      const result = yield call(updateSelected, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.GetSelectedVenue, function* getSelectedVenue(
    payload
  ) {
    try {
      const result = yield call(getSelected, payload.payload);
      return yield put(
        actions.getSelectedVenue({
          selected: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.CreateVenue, function* createVenue(payload) {
    try {
      const result = yield call(create, payload.payload.values);
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.DeleteVenue, function* deleteSelectedVenue(
    payload
  ) {
    try {
      const result = yield call(deleteVenue, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
      return yield put(
        actions.setState({
          meta: null,
        })
      );
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.SendCSVFile, function* sendCSVFileInput(
    payload
  ) {
    try {
      const result = yield call(sendCSVFile, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });
}
