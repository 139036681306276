import baseApi from "../api/baseApi";

// const authToken = () =>
//   JSON.parse(localStorage.getItem("persist:auth"))
//     ? JSON.parse(localStorage.getItem("persist:auth")).authToken
//     : "";

// baseApi.defaults.headers.common["Authorization"] = authToken()
//   ? JSON.parse(authToken()).token
//   : "";

export function getAll() {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.get("/tutors/all");
}

export function getCoachesByUuid(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.get(`/tutors/getByUuid/${payload?.uuid}`);
}

export function getSchedule() {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.get("/tutors/getschedule");
}

export function getSelected(payload) {
  if (payload.id) {
    const authToken = () =>
      JSON.parse(localStorage.getItem("persist:auth"))
        ? JSON.parse(localStorage.getItem("persist:auth")).authToken
        : "";

    baseApi.defaults.headers.common["Authorization"] = authToken()
      ? JSON.parse(authToken()).token
      : "";
    return baseApi.get(`/tutors/get/${payload.id}`);
  }
}

export function addMessage(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.post("/tutors/addmessage", payload);
}

export function editMessage(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.post("/tutors/editmessage", payload);
}

export function deleteMessage(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.post("/tutors/deletemessage", payload);
}

export function create(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  let params = new FormData();

  params.append("firstName", payload.firstName);
  params.append("lastName", payload.lastName);
  params.append("email", payload.email);
  params.append("password", payload.password);
  params.append("phone", payload.phone);
  params.append("photo", payload.photo);
  params.append("address", payload.address);
  params.append("confirmPassword", payload.confirmPassword);
  params.append("fullAddress", payload.fullAddress);
  params.append("postcode", payload.postcode);
  params.append("state", payload.state);
  params.append("suburb", payload.suburb);
  params.append("wwNumber", payload.wwNumber);
  params.append("bio", payload.bio);
  params.append("status", payload.status);
  params.append("business", payload.business);
  params.append("employmentHeroLink", payload.employmentHeroLink);

  return baseApi.post(`/tutors/create`, params);
}

export function deleteTutor(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.delete(`/tutors/delete/${payload.id}`);
}

export function updateSelected(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  let params = new FormData();

  params.append("firstName", payload.firstName);
  params.append("lastName", payload.lastName);
  params.append("email", payload.email);
  params.append("phone", payload.phone);
  params.append("photo", payload.photo);
  params.append("bio", payload.bio);
  params.append("status", payload.status);
  params.append("business", payload.business);
  params.append("password", payload.password);
  params.append("employmentHeroLink", payload.employmentHeroLink);

  // params.append("address", payload.address);
  // params.append("fullAddress", payload.fullAddress);
  // params.append("postcode", payload.postcode);
  // params.append("state", payload.state);
  // params.append("suburb", payload.suburb);

  return baseApi.put(`/tutors/update/${payload.id}`, params);
}

export function getLessonsByTutors(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.post(`/tutors/getLessonsByTutors`, payload);
}
