import { getAll, exportCSVFile } from "../../services/contact";
import { actionTypes, actions } from "../actions/contact";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    try {
      yield put(
        actions.SetLoading({
          loading: true,
        })
      );
      const result = yield call(getAll);
      yield put(
        actions.setState({
          list: result.data,
        })
      );
      yield put(
        actions.SetLoading({
          loading: false,
        })
      );
    } catch (error) {
      console.log(error);
      yield put(
        actions.setState({
          list: [],
        })
      );
      yield put(
        actions.SetLoading({
          loading: false,
        })
      );
    }
  });

  yield takeLatest(actionTypes.ExportCsvFile, function* exportCsvFileItem(
    payload
  ) {
    try {
      toast.success("Your download will start shortly!");
      const result = yield call(exportCSVFile, payload);
      return window.open(result.data.results, "_blank");
    } catch (error) {
      console.log(error);
    }
  });
}
