import {
  getAll,
  deleteLocationSurcharge,
  create,
  getSelected,
  updateSelected,
} from "../../services/locationSurcharge";
import { actionTypes, actions } from "../actions/locationSurcharge";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    try {
      const result = yield call(getAll);
      return yield put(
        actions.setState({
          list: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(
    actionTypes.UpdateLocationSurcharge,
    function* updateSelectedLocationSurcharge(payload) {
      try {
        const result = yield call(updateSelected, payload.payload);
        yield put({
          type: actionTypes.GetList,
        });
        toast.success(result.data.message);
      } catch (error) {
        toast.error(error.response.data.error);
      }
    }
  );

  yield takeLatest(
    actionTypes.GetSelectedLocationSurcharge,
    function* getSelectedLocationSurcharge(payload) {
      try {
        const result = yield call(getSelected, payload.payload);
        return yield put(
          actions.setSelectedLocationSurcharge({
            selected: result.data,
          })
        );
      } catch (error) {
        console.log(error.response);
      }
    }
  );

  yield takeLatest(
    actionTypes.CreateLocationSurcharge,
    function* createLocationSurcharge(payload) {
      try {
        const result = yield call(create, payload.payload.values);
        yield put({
          type: actionTypes.GetList,
        });
        toast.success(result.data.message);
      } catch (error) {
        toast.error(error.response.data.error);
      }
    }
  );

  yield takeLatest(
    actionTypes.DeleteLocationSurcharge,
    function* deleteSelectedLocationSurcharge(payload) {
      try {
        const result = yield call(deleteLocationSurcharge, payload.payload);
        yield put({
          type: actionTypes.GetList,
        });
        toast.success("Location surcharge deleted successfully!");
        return yield put(
          actions.setState({
            meta: null,
          })
        );
      } catch (error) {
        toast.error(error.response.data.error);
      }
    }
  );
}
