export const actionTypes = {
  GetList: "students/GET_LIST",
  GetGroupList: "students/GET_GROUP_LIST",
  GetStudentsByParent: "students/GET_STUDENTS_BY_PARENT",
  SetState: "students/SET_STATE",
  CreateStudent: "students/CREATE_STUDENT",
  EditStudent: "students/EDIT_STUDENT",
  DeleteStudent: "students/DELETE_STUDENT",
  GetSelectedStudent: "students/GET_SELECTED_STUDENT",
  UpdateStudent: "students/UPDATE_STUDENT",
  ExportCsvFile: "students/EXPORT_CSV_FILE",
  AddMessage: "students/ADD_MESSAGE",
  EditMessage: "students/EDIT_MESSAGE",
  DeleteMessage: "students/DELETE_MESSAGE",
  SearchStudent: "students/SEARCH_STUDENT",
  GetBirthdaysList: "students/GET_BIRTHDAYS_LIST",
  SetBirthdayPartyList: "students/SET_BIRTHDAY_PARTY_LIST",
  SetBirthdayLoader: "students/SET_BIRTHDAY_LOADER",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  SetBirthdayLoader: (payload) => ({
    type: actionTypes.SetBirthdayLoader,
    payload,
  }),
  SetBirthdayPartyList: (payload) => ({
    type: actionTypes.SetBirthdayPartyList,
    payload,
  }),
  GetBirthdaysList: (payload) => ({
    type: actionTypes.GetBirthdaysList,
    payload,
  }),
  createStudent: (payload) => ({ type: actionTypes.CreateStudent, payload }),
  SearchStudent: (payload) => ({ type: actionTypes.SearchStudent, payload }),
  editStudent: (payload) => ({ type: actionTypes.EditStudent, payload }),
  getSelectedStudent: (payload) => ({
    type: actionTypes.GetSelectedStudent,
    payload,
  }),
  GetStudentsByParent: (payload) => ({
    type: actionTypes.GetStudentsByParent,
    payload,
  }),
  exportCsvFile: (payload) => ({
    type: actionTypes.ExportCsvFile,
    payload,
  }),
  AddMessage: (payload) => ({
    type: actionTypes.AddMessage,
    payload,
  }),
  EditMessage: (payload) => ({
    type: actionTypes.EditMessage,
    payload,
  }),
  DeleteMessage: (payload) => ({
    type: actionTypes.DeleteMessage,
    payload,
  }),
};
