export const actionTypes = {
  GetList: "venues/GET_LIST",
  GetVenuePrivate: "venues/GET_VENUES_PRIVATE",
  GetListByUuid: "venues/GET_LIST_BY_UUID",
  GetGroupList: "venues/GET_GROUP_LIST",
  SetState: "venues/SET_STATE",
  CreateVenue: "venues/CREATE_VENUE",
  EditVenue: "venues/EDIT_VENUE",
  DeleteVenue: "venues/DELETE_VENUE",
  GetSelectedVenue: "venues/GET_SELECTED_VENUE",
  UpdateVenue: "venues/UPDATE_VENUE",
  SendCSVFile: "venues/SEND_CSV_FILE",
  ExportCSVFile: "venues/EXPORT_CSV_FILE",
  AddMessage: "venues/ADD_MESSAGE",
  EditMessage: "venues/EDIT_MESSAGE",
  DeleteMessage: "venues/DELETE_MESSAGE",
  SetLoading: "venues/SET_LOADING",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  SetLoading: (payload) => ({ type: actionTypes.SetLoading, payload }),
  GetListByUuid: (payload) => ({ type: actionTypes.GetListByUuid, payload }),
  createVenue: (payload) => ({ type: actionTypes.CreateVenue, payload }),
  editVenue: (payload) => ({ type: actionTypes.EditVenue, payload }),
  getSelectedVenue: (payload) => ({
    type: actionTypes.GetSelectedVenue,
    payload,
  }),
  SendCSVFile: (payload) => ({
    type: actionTypes.SendCSVFile,
    payload,
  }),
  ExportCSVFile: (payload) => ({
    type: actionTypes.ExportCSVFile,
    payload,
  }),
  AddMessage: (payload) => ({
    type: actionTypes.AddMessage,
    payload,
  }),
  EditMessage: (payload) => ({
    type: actionTypes.EditMessage,
    payload,
  }),
  DeleteMessage: (payload) => ({
    type: actionTypes.DeleteMessage,
    payload,
  }),
};
