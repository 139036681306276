import React, { useState, useEffect } from "react";
import KTWizard from "../../../_metronic/_assets/js/components/wizard";
import "../../../_metronic/_assets/sass/pages/wizard/wizard-4.scss";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { useHistory, withRouter } from "react-router-dom";
import StepOne from "../ParentPortal/ParentShop/components/CheckoutForm/StepOne";
import StepTwo from "../ParentPortal/ParentShop/components/CheckoutForm/StepTwo";
import StepThree from "../ParentPortal/ParentShop/components/CheckoutForm/StepThree";
import baseApi from "../../../api/baseApi";
import LoginForm from "../../components/LoginForm/LoginForm";

const ParentShopCheckout = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [show, setShow] = useState(false);

  const [formWizard, setFormWizard] = useState();
  const user = useSelector((state) => state.auth.user);
  const cart = useSelector((state) => state.products.cart);
  const [stripeToken, setStripeToken] = useState(null);
  const [couponValidateMessage, setCouponValidateMessage] = useState("");
  const [coupon, setCoupon] = useState({});

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [exist, setExist] = useState(undefined);
  const [error, setError] = useState(undefined);

  const [address, setAddress] = useState({
    address: "",
    postcode: "",
    city: "",
    state: "",
  });
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (match?.params?.uuid) {
      console.log(match.params);
      dispatch({
        type: "settings/GET_PUBLIC_SETTING",
        payload: { uuid: match.params.uuid, business: match.params.business },
      });
    }

    const wizard = new KTWizard("kt_wizard", {
      startStep: 1,
      clickableSteps: false,
    });

    setFormWizard(wizard);
  }, []);

  useEffect(() => {
    let tempAddress = {
      address: "",
      postcode: "",
      suburb: "",
      state: "",
    };

    if (user) {
      if (user.address) {
        tempAddress.address = user.address;
      }
      if (user.suburb) {
        tempAddress.suburb = user.suburb;
      }
      if (user.state) {
        tempAddress.state = user.state;
      }
      if (user.postcode) {
        tempAddress.postcode = user.postcode;
      }
    }

    setAddress(tempAddress);
  }, [user]);

  useEffect(() => {
    let tempTotal = 0;
    if (cart && cart.length > 0) {
      cart.map((item) => {
        tempTotal =
          tempTotal +
          parseInt(parseInt(item.product.price) * parseInt(item.qty));
      });
    }

    setTotal(tempTotal);
  }, [cart]);

  const shopSubmit = (e) => {
    e.preventDefault();

    let data = {
      address: address,
      cart: cart,
      parent: user?._id && user?.roleId?.name === "parent" ? user : null,
      newParent:
        !user?._id || user?.roleId?.name !== "parent"
          ? {
              firstName,
              lastName,
              email,
              phone,
            }
          : null,
      amount: parseFloat(total),
      publicShop: true,
      business: match.params.business,
      stripeToken,
      coupon: coupon?._id ? coupon?._id : null,
      uuid: match.params.uuid,
    };

    dispatch({
      type: "orders/CREATE_ORDER",
      payload: data,
      history: history,
    });
  };

  const onCouponChangeHandler = (e) => {
    baseApi
      .post(`/coupon/validate`, { coupon: e.target.value })
      .then((result) => {
        setCouponValidateMessage("Coupon Applied");
        setCoupon(result.data);

        // dispatch({
        //   type: "coupon/VALIDATE_COUPON",
        //   payload: { coupon: e.target.value },
        // });
      })
      .catch((error) => {
        console.log(error);
        setCouponValidateMessage("Invalid Coupon!");
        setCoupon({});
      });
  };

  const reRenderHandler = () => {
    const { history } = this.props;

    history.push(history.location.pathname);
  };

  const onClickLogout = () => {
    localStorage.removeItem("persist:auth");

    window.location.reload();
  };

  const onEmailChangeHandler = (e) => {
    baseApi
      .post(`/auth/validate`, {
        email: e.target.value.toLowerCase(),
        business: match.params.business,
      })
      .then((response) => {
        setMessage(response.data.message);
        setExist(false);
        setError(false);
      })
      .catch((error) => {
        if (error.response.data.message === "Invalid email") {
          setMessage(error.response.data.message);
          setExist(false);
          setError(true);
        } else {
          setMessage(error.response.data.message);
          setExist(true);
          setError(true);
        }
      });

    setEmail(e.target.value);
  };

  return (
    <>
      {show ? (
        <div style={{ width: 600, margin: "auto", marginTop: 50 }}>
          <h4>Login</h4>
          <br />
          <LoginForm
            reRenderHandler={reRenderHandler}
            history={history}
            user={user}
            match={match}
            showCancel
            onCancel={() => setShow(false)}
          />
        </div>
      ) : (
        <div className="flex-row-fluid ml-lg-8" style={{ padding: "20px" }}>
          <div className="card card-custom card-transparent">
            <div className="card-body p-0">
              <div
                className="wizard wizard-4"
                id="kt_wizard"
                data-wizard-state="first"
                data-wizard-clickable="false"
              >
                <div className="wizard-nav">
                  <div
                    className="wizard-steps"
                    data-total-steps="3"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      className="wizard-step"
                      data-wizard-type="step"
                      data-wizard-state="current"
                      style={{
                        flex: "0.33",
                        marginRight: "7px",
                      }}
                    >
                      <div className="wizard-wrapper">
                        <div className="wizard-number">1</div>
                        <div className="wizard-label">
                          <div className="wizard-title">Address</div>
                          <div className="wizard-desc">Set Your Address</div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="wizard-step"
                      data-wizard-type="step"
                      data-wizard-state="pending"
                      style={{
                        flex: "0.33",
                        marginRight: "7px",
                      }}
                    >
                      <div className="wizard-wrapper">
                        <div className="wizard-number">2</div>
                        <div className="wizard-label">
                          <div className="wizard-title">Payment</div>
                          <div className="wizard-desc">Payment Options</div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="wizard-step"
                      data-wizard-type="step"
                      data-wizard-state="pending"
                      style={{
                        flex: "0.34",
                      }}
                    >
                      <div className="wizard-wrapper">
                        <div className="wizard-number">3</div>
                        <div className="wizard-label">
                          <div className="wizard-title">Purchase</div>
                          <div className="wizard-desc">Review and Submit</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card card-custom card-shadowless rounded-top-0">
                  <div className="card-body p-0">
                    <div className="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                      <div className="col-xl-12 col-xxl-7">
                        <div
                          className="pb-5"
                          data-wizard-type="step-content"
                          data-wizard-state="current"
                        >
                          {user &&
                          user.roleId.name === "parent" &&
                          user.business === match.params.business ? (
                            <h5>
                              <strong>
                                Already Logged in?{" "}
                                <a
                                  href="javascript:void(null)"
                                  onClick={onClickLogout}
                                >
                                  Logout
                                </a>
                              </strong>
                            </h5>
                          ) : (
                            <h5>
                              <strong>
                                Already have an account?{" "}
                                <a
                                  href="javascript:void(null)"
                                  onClick={() => setShow(true)}
                                >
                                  Log in here
                                </a>
                              </strong>
                            </h5>
                          )}
                          <br />
                          <StepOne
                            formWizard={formWizard}
                            address={address}
                            setAddress={setAddress}
                            user={user?.roleId?.name === "parent" ? user : {}}
                            setFirstName={setFirstName}
                            setLastName={setLastName}
                            setEmail={setEmail}
                            setPhone={setPhone}
                            match={match}
                            onEmailChangeHandler={onEmailChangeHandler}
                            message={message}
                            exist={exist}
                            error={error}
                          />
                        </div>

                        <div className="pb-5" data-wizard-type="step-content">
                          <StepTwo
                            formWizard={formWizard}
                            setStripeToken={setStripeToken}
                            match={match}
                          />
                        </div>
                        <div className="pb-5" data-wizard-type="step-content">
                          <StepThree
                            shopSubmit={shopSubmit}
                            address={address}
                            cart={cart}
                            total={total}
                            formWizard={formWizard}
                            onCouponChangeHandler={onCouponChangeHandler}
                            couponValidateMessage={couponValidateMessage}
                            coupon={coupon}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withRouter(ParentShopCheckout);
