import { actionTypes } from "../actions/bookingSettings";

const initialBookingReducer = {
  list: [],
  listLoading: false,
  availabilities: [],
  availabilitiesLoading: false,
  eventExtra: [],
  EventExtraLoading: false,
  inflatableItems: [],
  inflatableItemsLoading: false,
};

const bookingSettingsReducer = (state = initialBookingReducer, action) => {
  switch (action.type) {

    case actionTypes.SetEventExtra: {
      const { eventExtra } = action.payload;
      return {
        ...state,
        eventExtra,
      };
    }

    case actionTypes.SetEventExtraLoading: {
      const { EventExtraLoading } = action.payload;
      return {
        ...state,
        EventExtraLoading,
      };
    }

    case actionTypes.SetInflatableItems: {
      const { inflatableItems } = action.payload;
      return {
        ...state,
        inflatableItems,
      };
    }

    case actionTypes.SetInflatableItemsLoading: {
      const { inflatableItemsLoading } = action.payload;
      return {
        ...state,
        inflatableItemsLoading,
      };
    }

    case actionTypes.SetState: {
      const { list } = action.payload;
      return {
        ...state,
        list,
      };
    }

    case actionTypes.SetListLoading: {
      const { listLoading } = action.payload;
      return {
        ...state,
        listLoading,
      };
    }

    case actionTypes.SetAvailabilitiesLoading: {
      const { availabilitiesLoading } = action.payload;
      return {
        ...state,
        availabilitiesLoading,
      };
    }

    case actionTypes.SetAvailabilities: {
      const { availabilities } = action.payload;
      return {
        ...state,
        availabilities,
      };
    }

    default:
      return state;
  }
};

export default bookingSettingsReducer;
