/* eslint-disable no-underscore-dangle */
import { Ability, AbilityBuilder } from "@casl/ability";
import store from "../../redux/store";
import { PERMISSIONS, ROLES_ACCESS_KEYS } from "../Common/rolesConstants";

// Defines how to detect object's type
const subjectName = (item) => {
  if (!item || typeof item === "string") {
    return item;
  }
  return item.__type;
};

const ability = new Ability([], { subjectName });

const abilityHandler = (action, subject) => {
  return ability.can(action, subject);
};

let currentAuth;
store.subscribe(() => {
  const prevAuth = currentAuth;
  currentAuth = store?.getState()?.auth;

  if (prevAuth !== currentAuth) {
    ability.update(defineRulesFor(currentAuth));
  }
});

const defineRulesFor = (auth) => {
  const { can, rules } = new AbilityBuilder(Ability);

  const permissions = auth?.user?.roleId?.permissions;

  if (auth?.authToken && auth?.user?.roleId) {
    can(PERMISSIONS.view, ROLES_ACCESS_KEYS.dashboard, {
      userId: auth?.user?._id,
    });
  }

  // if (auth?.authToken && auth?.user?.roleId?.name === "super admin") {
  //   can(PERMISSIONS.view, ROLES_ACCESS_KEYS.roles, {
  //     userId: auth?.user?._id,
  //   });
  //   can(PERMISSIONS.create, ROLES_ACCESS_KEYS.roles, {
  //     userId: auth?.user?._id,
  //   });
  //   can(PERMISSIONS.edit, ROLES_ACCESS_KEYS.roles, {
  //     userId: auth?.user?._id,
  //   });
  //   can(PERMISSIONS.delete, ROLES_ACCESS_KEYS.roles, {
  //     userId: auth?.user?._id,
  //   });
  // }

  if (auth?.user?.roleId?.name) {
    for (let key in permissions) {
      const keys = Object.keys(permissions[key]);

      for (let k of keys) {
        if (permissions[key][k]) {
          can(k, key, { userId: auth?.user?._id });
        }
      }
    }
  }

  return rules;
};

export { ability, abilityHandler };
