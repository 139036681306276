import baseApi from "../api/baseApi";

export const exportAllTransaction = (payload) => {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";
  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get("/transactions/exportToCsv", {
    params: {
      startDate: payload?.startDate,
      endDate: payload?.endDate,
    },
  });
};

export const refundReports = (payload) => {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";
  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.post("/transactions/getRefundReports", payload);
};
