import { actionTypes } from "../actions/products";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialProductState = {
  list: [],
  selected: {},
  productsByCategories: [],
  cart: [],
  reportsData: {},
  // meta: {},
};

const productReducer = persistReducer(
  { storage, key: "products", whitelist: ["cart"] },
  (state = initialProductState, action) => {
    switch (action.type) {
      case actionTypes.SetState: {
        const { list, productsByCategories, publicProducts } = action.payload;
        return {
          ...state,
          list,
          productsByCategories,
          publicProducts,
        };
      }
      case actionTypes.SetProductsByCategory: {
        const { productsByCategory } = action.payload;
        return {
          ...state,
          productsByCategory,
        };
      }
      case actionTypes.SetCart: {
        const { cart } = action.payload;
        return {
          ...state,
          cart,
        };
      }
      case actionTypes.GetSelectedProduct: {
        return {
          ...state,
          selected: { ...action.payload.selected },
        };
      }

      case actionTypes.SetProductsReportsData: {
        return {
          ...state,
          reportsData: { ...action.payload.data },
        };
      }

      default:
        return state;
    }
  }
);

export default productReducer;
