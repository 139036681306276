import { actionTypes } from "../actions/bookacoach";

const initialBookaCoachReducer = {
  list: [],
  pastList: [],
  upcomingList: [],
  selected: {},
  cancelledList: [],
  scheduleList: [],
};

const bookaCoachReducer = (state = initialBookaCoachReducer, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list } = action.payload;
      return {
        ...state,
        list,
      };
    }
    case actionTypes.SetPastBookaCoach: {
      const { pastList } = action.payload;
      return {
        ...state,
        pastList,
      };
    }
    case actionTypes.SetFutureBookaCoach: {
      const { upcomingList } = action.payload;
      return {
        ...state,
        upcomingList,
      };
    }
    case actionTypes.SetSelectedBookaCoach: {
      const { selected } = action.payload;
      return {
        ...state,
        selected,
      };
    }

    case actionTypes.SetCancelledList: {
      const { cancelledList } = action.payload;
      return {
        ...state,
        cancelledList,
      };
    }

    case actionTypes.SetScheduleBookaCoach: {
      const { scheduleList } = action.payload;
      return {
        ...state,
        scheduleList,
      };
    }

    default:
      return state;
  }
};

export default bookaCoachReducer;
