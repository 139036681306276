import { actionTypes } from "../actions/settings";

const initialSettingState = {
  list: [],
  setting: {},
  gingerSetting: {},
  questSetting: {},
  publicSetting: {},
  mailchimpLists: [],
  singleMailchimpList: {},
  meta: {},
  orgDetails: {},
  getSettingForPublic: {},
};

const settingsReducer = (state = initialSettingState, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list, setting, meta } = action.payload;
      return {
        ...state,
        list,
        setting,
        meta: { ...meta },
      };
    }
    
    case actionTypes.SetPublicState: {
      const { publicSetting } = action.payload;
      return {
        ...state,
        publicSetting,
      };
    }

    case actionTypes.SetSettingForPublic: {
      const { setting } = action.payload;
      return {
        ...state,
        getSettingForPublic: setting,
      };
    }

    case actionTypes.SetGingerState: {
      const { setting } = action.payload;
      return {
        ...state,
        gingerSetting: setting,
      };
    }

    case actionTypes.SetQuestState: {
      const { setting } = action.payload;
      return {
        ...state,
        questSetting: setting,
      };
    }
    case actionTypes.SetMailchimp: {
      const { mailchimpLists } = action.payload;
      return {
        ...state,
        mailchimpLists,
      };
    }

    case actionTypes.SetSingleMailchimp: {
      const { singleMailchimpList } = action.payload;
      return {
        ...state,
        singleMailchimpList,
      };
    }

    case actionTypes.SetLogoAndDetails: {
      const { orgDetails } = action.payload;
      return {
        ...state,
        orgDetails,
      };
    }

    // case actionTypes.setMailChimp: {
    //   const {
    //     mailchimpLists,
    //   } = action.payload;
    //   return {
    //     ...state,
    //     mailchimpLists,
    //   };
    // }

    default:
      return state;
  }
};

export default settingsReducer;
