export const actionTypes = {
  SetState: "payments/SET_STATE",
  DeletePayment: "payments/DELETE_PAYMENT",
  GetListFailedPayments: "payments/GET_LIST_FAILED_PAYMENTS",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  DeleteFailedPayment: (payload) => ({
    type: actionTypes.DeletePayment,
    payload,
  }),
};
