import axios from "axios";
import baseApi from "../../../../api/baseApi";

export const LOGIN_URL = "/auth/login";
export const REGISTER_URL = "/auth/register";
export const REQUEST_PASSWORD_URL = "/auth/forgot-password";
export const PROFILE_UPDATE_URL = "/auth/update-profile";
export const PARENT_PROFILE_UPDATE_URL = "/auth/update-parent";
export const ADMIN_PROFILE_UPDATE_URL = "/auth/update-admin";
export const GET_SESSION_DETAILS = "/auth/session-settings";

export const LOGIN_QUEST_URL = "/auth/quest/login";
export const VALIDATE_USER_URL = "/auth/validateUser";
export const RESET_PASSWORD_URL = "/auth/resetPassword";

export const SESSION_VALUE = "/auth/sessionValue";

export const ME_URL = "api/me";

export function SessionExpireValue(values) {
  return baseApi.post(SESSION_VALUE, values);
}

export function login(values) {
  return baseApi.post(LOGIN_URL, values);
}

export function loginQuest(values) {
  return baseApi.post(LOGIN_QUEST_URL, values);
}

export function checkEmailAddress(payload) {
  return baseApi.post(`/auth/validate`, payload);
}

export function updateProfile(payload) {
  let params = new FormData();

  params.append("firstName", payload.firstName);
  params.append("lastName", payload.lastName);
  params.append("email", payload.email);
  params.append("phone", payload.phone);
  params.append("photo", payload.photo);
  params.append("bio", payload.bio);
  // params.append("status", payload.status);
  params.append("password", payload.password);

  return baseApi.post(PROFILE_UPDATE_URL, params);
}

export function updateParentProfile(values) {
  const formdata = new FormData();
  for (let key in values) {
    formdata.append(key, values[key]);
  }

  return baseApi.post(PARENT_PROFILE_UPDATE_URL + `/${values.id}`, formdata);
}

export function updateAdminProfile(values) {
  return baseApi.post(ADMIN_PROFILE_UPDATE_URL + `/${values.id}`, values);
}

export function register(values) {
  return baseApi.post(REGISTER_URL, values);
}

export function requestPassword(values) {
  return baseApi.post(REQUEST_PASSWORD_URL, { ...values });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function resetPassword(payload) {
  return baseApi.post(RESET_PASSWORD_URL, payload);
}

export function validateAUser(payload) {
  const { token } = payload.token;

  if (token && token.length > 0) {
    baseApi.defaults.headers.common["Authorization"] = `${token}`;

    return baseApi.post(VALIDATE_USER_URL);
  }
}

export function fetchSessionExpiryDetails() {
  return baseApi.get(GET_SESSION_DETAILS);
}
