import { actionTypes } from "../actions/locations";

const initialLocationReducer = {
  list: [],
  selected: {},
  selectedLocations: [],
  searched: [],
  zoom: 11,
  selectedInMap: {},
  locationsAndVenues: [],
  locationsByOrgId: [],
  loadingLocations: false,
  enrolmentsByLocations: [],
  report: [],
  loading: false,
  searchedForTrials: [],
  selectedLocationsForTrials: [],
  locationsForTrialsLoading: false,
};

const locationReducer = (state = initialLocationReducer, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const {
        list,
        selectedLocations,
        locationsAndVenues,
        locationsByOrgId,
      } = action.payload;

      return {
        ...state,
        list,
        selectedLocations,
        locationsAndVenues,
        locationsByOrgId,
      };
    }
 
    case actionTypes.SetForTrialsState : {
      const { selectedLocationsForTrials } = action.payload;
      return {
        ...state,
        selectedLocationsForTrials,
      }
    }
    case actionTypes.SetSelectedLocation: {
      const { selectedInMap } = action.payload;
      return {
        ...state,
        selectedInMap,
      };
    }

    case actionTypes.SetSearchedResults: {
      const { searched } = action.payload;
      return {
        ...state,
        searched,
      };
    }


    case actionTypes.SetSearchedForTrialResults: {
      const { searchedForTrials } = action.payload;
      return {
        ...state,
        searchedForTrials,
      };
    }

    case actionTypes.SetMapZoom: {
      const { zoom } = action.payload;
      return {
        ...state,
        zoom,
      };
    }

    case actionTypes.SetSelectedLocations: {
      const { selected } = action.payload;
      return {
        ...state,
        selected,
      };
    }

    case actionTypes.SetSelectedLocations: {
      const { selected } = action.payload;
      return {
        ...state,
        selected,
      };
    }

    case actionTypes.GetLocationsLoading: {
      const { loading } = action.payload;
      return {
        ...state,
        loadingLocations: loading,
      };
    }

    case actionTypes.SetEnrolmentsByLocation: {
      const { enrolmentsBylocations } = action.payload;
      return {
        ...state,
        enrolmentsByLocations: enrolmentsBylocations,
      };
    }

    case actionTypes.SetReport: {
      const { report } = action.payload;

      return {
        ...state,
        report,
      };
    }

    case actionTypes.SetLoading: {
      const { loading } = action.payload;

      return {
        ...state,
        loading,
      };
    }

    case actionTypes.GetLocationsForTrialsLoading: {
      const { loading } = action.payload;

      return {
        ...state,
        locationsForTrialsLoading: loading,
      };
    }

    default:
      return state;
  }
};

export default locationReducer;
