import { actionTypes } from "../actions/notes";

const initialNoteReducer = {
  list: [],
  parentList: [],
  selected: {},
  // meta: {},
};

const noteReducer = (state = initialNoteReducer, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list } = action.payload;
      return {
        ...state,
        list,
      };
    }

    case actionTypes.SetSelectedNote: {
      const { selected } = action.payload;
      return {
        ...state,
        selected,
      };
    }

    case actionTypes.SetParentList: {
      const { parentList } = action.payload;
      return {
        ...state,
        parentList,
      };
    }

    default:
      return state;
  }
};

export default noteReducer;
