export const actionTypes = {
  GetList: "embedcode/GET_LIST",
  GetGroupList: "embedcode/GET_GROUP_LIST",
  SetState: "embedcode/SET_STATE",
  CreateEmbedCode: "embedcode/CREATE_EMBED_CODE",
  EditEmbedCode: "embedcode/EDIT_EMBED_CODE",
  DeleteEmbedCode: "embedcode/DELETE_EMBED_CODE",
  GetSelectedEmbedCode: "embedcode/GET_SELECTED_EMBED_CODE",
  UpdateEmbedCode: "embedcode/UPDATE_EMBED_CODE",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  createEmbedCode: (payload) => ({
    type: actionTypes.CreateEmbedCode,
    payload,
  }),
  editEmbedCode: (payload) => ({
    type: actionTypes.EditEmbedCode,
    payload,
  }),
  getSelectedEmbedCode: (payload) => ({
    type: actionTypes.GetSelectedEmbedCode,
    payload,
  }),
};
