export const actionTypes = {
  GetList: "notification/GET_LIST",
  GetGroupList: "notification/GET_GROUP_LIST",
  SetGroupList: "notification/SET_GROUP_LIST",
  SetState: "notification/SET_STATE",
  SetDrawer: "notification/SET_DRAWER",
  CreateNotifications: "notification/CREATE_NOTIFICATION",
  UpdateNotifications: "notification/UPDATE_NOTIFICATION",
  DeleteNotifications: "notification/DELETE_NOTIFICATION",
  OpenDrawer: "notification/OPEN_DRAWER",
  CloseDrawer: "notification/CLOSE_DRAWER",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  SetDrawer: (payload) => ({ type: actionTypes.SetDrawer, payload }),

  GetGroupList: (payload) => ({ type: actionTypes.GetGroupList, payload }),
  SetGroupList: (payload) => ({ type: actionTypes.SetGroupList, payload }),

  CreateNotifications: (payload) => ({
    type: actionTypes.CreateNotifications,
    payload,
  }),
  DeleteNotifications: (payload) => ({
    type: actionTypes.DeleteNotifications,
    payload,
  }),
  UpdateNotifications: (payload) => ({
    type: actionTypes.UpdateNotifications,
    payload,
  }),
};
