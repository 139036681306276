import { actionTypes } from "../actions/payments";

const initialParentsState = {
  failedPaymentsList: [],
  show: false,
};

const paymentsReducer = (state = initialParentsState, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { failedPaymentsList } = action.payload;

      return {
        ...state,
        failedPaymentsList,
      };
    }

    default:
      return state;
  }
};

export default paymentsReducer;
