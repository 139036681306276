import { actionTypes } from '../actions/age'

const initialAgeReducer = {
  age: [],
  selected: {},
}

const ageReducer = (state = initialAgeReducer, action) => {
  switch (action.type) {
    case actionTypes.SetAgeList: {
      const { age } = action.payload
      return {
        ...state,
        age,
      }
    }
    case actionTypes.SetSelectedAge: {
      const { selected } = action.payload
      return {
        ...state,
        selected,
      }
    }
    case actionTypes.CreateAge: {
      const { age } = action.payload
      return {
        ...state,
        age,
      }
    }

    default:
      return state
  }
}

export default ageReducer
