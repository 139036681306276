import {
  update,
  GetOrganisation,
  getCurrentOrganisation,
  getUuid,
} from "../../services/organisations";
import { actionTypes, actions } from "../actions/organisations";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.UpdateOrganisation, function* updateOrganisation(
    payload
  ) {
    try {
      const result = yield call(update, payload.payload);
      yield put({
        type: actionTypes.GetCurrentOrganisation,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.GetCurrentOrganisation, function* getCurrent(
    payload
  ) {
    try {
      const result = yield call(getCurrentOrganisation, null);

      return yield put(
        actions.setState({
          organisation: result.data.result,
        })
      );
    } catch (error) {
      console.error(error.response);
    }
  });

  yield takeLatest(actionTypes.GetOrganisation, function* GetOrganisationById(
    payload
  ) {
    try {
      const result = yield call(GetOrganisation, payload);

      return yield put(
        actions.setOrganisation({
          organisation: result.data.result,
        })
      );
    } catch (error) {
      console.error(error.response);
    }
  });

  yield takeLatest(actionTypes.GetUuid, function* getUuidOfOrgainization() {
    try {
      const result = yield call(getUuid);

      return yield put(
        actions.setState({
          uuid: result.data.result,
        })
      );
    } catch (error) {
      console.error(error.response);
    }
  });
}
