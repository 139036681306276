import {
  getAll,
  deleteLocations,
  create,
  getSelected,
  updateSelected,
  getAllLocationsByUuid,
  getAllLocationsForTrialsByUuid,
  searchNearLocation,
  sendCSVFile,
  exportCSVFile,
  addMessage,
  editMessage,
  deleteMessage,
  getAllLocationsPrivate,
  getAllLocationsAndVenue,
  getAllLocationsByOrgId,
  getEnrolmentsByLocationOrVenue,
  getRevenueReport,
} from "../../services/locations";
import { actionTypes, actions } from "../actions/locations";
import * as classActions from "./classes";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    try {
      const result = yield call(getAll);
      return yield put(
        actions.setState({
          list: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(
    actionTypes.GetEnrolmentsByLocation,
    function* getAllEnrolmentsReportsByLocation() {
      try {
        const result = yield call(getEnrolmentsByLocationOrVenue);
        return yield put(
          actions.SetEnrolmentsByLocation({
            enrolmentsBylocations: result.data,
          })
        );
      } catch (error) {
        console.log(error);
        if (error?.response?.data?.error) {
          return toast.error(error.response.data.error);
        }
      }
    }
  );

  yield takeLatest(actionTypes.GetReport, function* getServiceReport({
    payload,
  }) {
    yield put(
      actions.loading({
        loading: true,
      })
    );
    try {
      const result = yield call(getRevenueReport, payload);
      yield put(
        actions.loading({
          loading: false,
        })
      );

      return yield put(
        actions.SetReport({
          report: result.data.result,
        })
      );
    } catch (error) {
      yield put(
        actions.loading({
          loading: false,
        })
      );
    }
  });

  yield takeLatest(actionTypes.GetLocationsByOrgId, function* getList({
    payload,
  }) {
    try {
      const result = yield call(getAllLocationsByOrgId, payload);
      return yield put(
        actions.setState({
          locationsByOrgId: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.GetLocationsPrivate, function* getListPrivate() {
    try {
      const result = yield call(getAllLocationsPrivate);
      return yield put(
        actions.setState({
          selectedLocations: result.data.results,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.ExportCSVFile, function* exportCsvFileItem(
    payload
  ) {
    try {
      toast.success("Your download will start shortly!");
      const result = yield call(exportCSVFile);
      return window.open(result.data.results, "_blank");
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.AddMessage, function* addNewMessage(payload) {
    try {
      const result = yield call(addMessage, payload.payload);

      toast.success(result.data.message);

      return yield put(
        actions.setSelectedLocations({
          selected: result.data.results,
        })
      );
    } catch (error) {
      return toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.EditMessage, function* editNewMessage(payload) {
    try {
      const result = yield call(editMessage, payload.payload);

      toast.success(result.data.message);

      return yield put(
        actions.setSelectedLocations({
          selected: result.data.results,
        })
      );
    } catch (error) {
      return toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.DeleteMessage, function* deleteNewMessage(
    payload
  ) {
    try {
      const result = yield call(deleteMessage, payload.payload);

      toast.success(result.data.message);

      return yield put(
        actions.setSelectedLocations({
          selected: result.data.results,
        })
      );
    } catch (error) {
      return toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.SetMapSelectedLocation, function* setLocation({
    payload,
  }) {
    // yield put(
    //   actions.SetMapZoom({
    //     zoom: 11,
    //   })
    // );
    yield put(
      actions.SetSelectedLocation({
        selectedInMap: payload,
      })
    );
  });

  yield takeLatest(
    actionTypes.SearchNearLocation,
    function* getSearchNearLocation(payload) {
      try {
        const result = yield call(searchNearLocation, payload);
        // yield put(
        //   actions.SetMapZoom({
        //     zoom: 14,
        //   })
        // );
        yield put(
          actions.setSearchedResults({
            searched: result.data,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  );

  yield takeLatest(
    actionTypes.GetSelectedLocations,
    function* getSelectedLocationById(payload) {
      try {
        const result = yield call(getSelected, payload.payload);
        return yield put(
          actions.setSelectedLocations({
            selected: result.data,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  );

  yield takeLatest(
    actionTypes.UpdateLocations,
    function* updateSelectedLocations(payload) {
      try {
        const result = yield call(updateSelected, payload.payload);
        yield put({
          type: actionTypes.GetList,
        });
        toast.success(result.data.message);
      } catch (error) {
        toast.error(error.response.data.error);
      }
    }
  );

  yield takeLatest(actionTypes.GetListByUuid, function* getListByUuid(payload) {
    const { tag } = payload.payload;
    yield put(
      actions.GetLocationsLoading({
        loading: true,
      })
    );

    try {
      const result = yield call(getAllLocationsByUuid, payload);
      // yield put(
      //   actions.SetMapZoom({
      //     zoom: 14,
      //   })
      // );
      yield put(
        actions.GetLocationsLoading({
          loading: false,
        })
      );
      yield put(
        actions.setSearchedResults({
          searched: result.data.results,
        })
      );

      yield put(
        actions.setState({
          selectedLocations: result.data.results,
        })
      );
    } catch (error) {
      yield put(
        actions.GetLocationsLoading({
          loading: false,
        })
      );
    }
  });

  yield takeLatest(actionTypes.GetListForTrialsByUuid, function* getListForTrialsByUuid(payload) {
    const { tag } = payload.payload;
    yield put(
      actions.GetLocationsForTrialsLoading({
        loading: true,
      })
    );

    try {
      const result = yield call(getAllLocationsForTrialsByUuid, payload);
      // yield put(
      //   actions.SetMapZoom({
      //     zoom: 14,
      //   })
      // );
      yield put(
        actions.GetLocationsForTrialsLoading({
          loading: false,
        })
      );
      yield put(
        actions.setSearchedForTrialsResults({
          searchedForTrials: result.data.results,
        })
      );

      yield put(
        actions.setForTrialsState({
          selectedLocationsForTrials: result.data.results,
        })
      );
    } catch (error) {
      yield put(
        actions.GetLocationsForTrialsLoading({
          loading: false,
        })
      );
    }
  });


  yield takeLatest(actionTypes.CreateLocations, function* createLocations(
    payload
  ) {
    try {
      const result = yield call(create, payload.payload.values);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.SendCSVFile, function* sendCSVFileInput(
    payload
  ) {
    try {
      const result = yield call(sendCSVFile, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(
    actionTypes.DeleteLocations,
    function* deleteSelectedLocations(payload) {
      try {
        const result = yield call(deleteLocations, payload.payload);
        yield put({
          type: actionTypes.GetList,
        });
        toast.success(result.data.message);
        return yield put(
          actions.setState({
            meta: null,
          })
        );
      } catch (error) {
        toast.error(error.response.data.error);
      }
    }
  );

  yield takeLatest(
    actionTypes.GetAllLocationsAndVenue,
    function* getAllVenuesAndLocations({ payload }) {
      try {
        const result = yield call(getAllLocationsAndVenue, payload);
        yield put(
          actions.setState({
            locationsAndVenues: result.data,
          })
        );
      } catch (error) {
        yield put(
          actions.setState({
            locationsAndVenues: [],
          })
        );
        toast.error(error.response.data.error);
      }
    }
  );
}
