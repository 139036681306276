export const actionTypes = {
  GetList: "tasks/GET_LIST",
  GetGroupList: "tasks/GET_GROUP_LIST",
  SetState: "tasks/SET_STATE",
  CreateTask: "tasks/CREATE_TASK",
  EditTask: "tasks/EDIT_TASK",
  DeleteTask: "tasks/DELETE_TASK",
  GetSelectedTask: "tasks/GET_SELECTED_TASK",
  UpdateTask: "tasks/UPDATE_TASK",
  UpdateNormalTask: "tasks/UPDATE_TASK_NORMAL",
  GetSchedule: "tasks/GET_SCHEDULE",
  SetSchedule: "tasks/SET_SCHEDULE",
  GetTutorTask: "tasks/GET_TUTOR_TASK",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  createTask: (payload) => ({ type: actionTypes.CreateTask, payload }),
  editTask: (payload) => ({ type: actionTypes.EditTask, payload }),
  updateNormalTask: (payload) => ({
    type: actionTypes.UpdateNormalTask,
    payload,
  }),
  getSelectedTask: (payload) => ({
    type: actionTypes.GetSelectedTask,
    payload,
  }),
  getSchedule: (payload) => ({
    type: actionTypes.GetSchedule,
    payload,
  }),
  setSchedule: (payload) => ({
    type: actionTypes.SetSchedule,
    payload,
  }),
  getTutorTask: (payload) => ({
    type: actionTypes.GetTutorTask,
    payload,
  }),
};
