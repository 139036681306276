import { actionTypes } from "../actions/levels";

const initialLevelsState = {
  list: [],
  meta: {},
  selected: {},
};

const levelReducer = (state = initialLevelsState, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list, meta } = action.payload;
      return {
        ...state,
        list,
        meta: { ...meta },
      };
    }


    case actionTypes.GetSelectedLevel: {
      return {
        ...state,
        selected: { ...action.payload.selected },
      };
    }

    default:
      return state;
  }
};

export default levelReducer;
