import {
  getAll,
  deleteEmailTemplate,
  create,
  getSelected,
  updateSelected,
} from "../../services/emailTemplate";
import { actionTypes, actions } from "../actions/emailTemplate";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    try {
      const result = yield call(getAll);
      return yield put(
        actions.setState({
          list: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(
    actionTypes.UpdateEmailTemplate,
    function* updateSelectedEmailTemplate(payload) {
      try {
        const result = yield call(updateSelected, payload.payload);
        yield put({
          type: actionTypes.GetList,
        });
        toast.success(result.data.message);
      } catch (error) {
        toast.error(error.response.data.error);
      }
    }
  );

  yield takeLatest(
    actionTypes.GetSelectedEmailTemplate,
    function* getSelectedEmailTemplate(payload) {
      try {
        const result = yield call(getSelected, payload.payload);
        return yield put(
          actions.setSelectedEmailTemplate({
            selected: result.data,
          })
        );
      } catch (error) {
        console.log(error.response);
      }
    }
  );

  yield takeLatest(
    actionTypes.CreateEmailTemplate,
    function* createEmailTemplate(payload) {
      try {
        const result = yield call(create, payload.payload.values);
        yield put({
          type: actionTypes.GetList,
        });
        toast.success(result.data.message);
      } catch (error) {
        toast.error(error.response.data.error);
      }
    }
  );

  yield takeLatest(
    actionTypes.DeleteEmailTemplate,
    function* deleteSelectedEmailTemplate(payload) {
      try {
        const result = yield call(deleteEmailTemplate, payload.payload);
        yield put({
          type: actionTypes.GetList,
        });
        toast.success("Email Template deleted successfully!");
        return yield put(
          actions.setState({
            meta: null,
          })
        );
      } catch (error) {
        toast.error(error.response.data.error);
      }
    }
  );
}
