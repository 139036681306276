import React, { useState, useRef, useEffect } from "react";
import IdleTimer from "react-idle-timer";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router";

const IdleTimerContainer = ({ sessionData }) => {
  const [value, setValue] = React.useState(null);
  const [time, setTime] = React.useState(null);
  const idleTimerRef = useRef(null);
  const sessionTimeoutRef = useRef(null);

  let history = useHistory();
  const onIdle = () => {
    sessionTimeoutRef.current = setTimeout(logOut, 1000);
  };

  const logOut = () => {
    clearTimeout(sessionTimeoutRef.current);
    localStorage.clear("authToken");
    history.push("/auth/login");
    window.location.reload();
  };

  useEffect(() => {
    if (sessionData?.timeout) {
      setValue(sessionData?.value);
      setTime(sessionData?.timeout);
    }
  }, [sessionData]);

  if (value == true) {
    return (
      <div>
        <IdleTimer ref={idleTimerRef} timeout={time} onIdle={onIdle} />
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default withRouter(IdleTimerContainer);