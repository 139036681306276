import baseApi from "../api/baseApi";

export function getAll() {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.get("/task/all");
}

export function getTutorTasks(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  if (
    !JSON.parse(authToken()).token ||
    JSON.parse(authToken()).token === undefined
  ) {
    baseApi.defaults.headers.common["Authorization"] = payload.auth;
  }
  return baseApi.get(`/task/gettutortask/${payload.id}`);
}

export function getSchedule() {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.get("/task/getschedule");
}

export function getSelected(payload) {
  if (payload.id) {
    const authToken = () =>
      JSON.parse(localStorage.getItem("persist:auth"))
        ? JSON.parse(localStorage.getItem("persist:auth")).authToken
        : "";

    baseApi.defaults.headers.common["Authorization"] = authToken()
      ? JSON.parse(authToken()).token
      : "";
    return baseApi.get(`/task/get/${payload.id}`);
  }
}

export function create(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  let params = new FormData();

  payload.values.attachments.map((item) => {
    params.append("attachments", item);
  });
  payload.values.assigned.map((item) => {
    params.append("assigned", item);
  });
  params.append("description", payload.values.description);
  params.append("endDate", payload.values.endDate);
  params.append("startDate", payload.values.startDate);
  params.append("startTime", payload.values.startTime);
  params.append("endTime", payload.values.endTime);
  params.append("task", payload.values.task);

  return baseApi.post(`/task/create`, params);
}

export function deleteTask(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.delete(`/task/delete/${payload.id}`);
}

export function updateSelected(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  let params = new FormData();

  payload.attachments.map((item) => {
    params.append("attachments", item);
  });
  payload.assigned.map((item) => {
    params.append("assigned", item);
  });
  params.append("description", payload.description);
  params.append("endDate", payload.endDate);
  params.append("startDate", payload.startDate);
  params.append("startTime", payload.startTime);
  params.append("endTime", payload.endTime);
  params.append("task", payload.task);
  return baseApi.put(`/task/update/${payload.id}`, params);
}

export function updateNormalSelected(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.put(`/task/update/${payload.id}`, payload);
}
