export const actionTypes = {
  GetList: "publicholidays/GET_LIST",
  GetGroupList: "publicholidays/GET_GROUP_LIST",
  SetState: "publicholidays/SET_STATE",
  CreatePublicHoliday: "publicholidays/CREATE_PUBLIC_HOLIDAY",
  EditPublicHoliday: "publicholidays/EDIT_PUBLIC_HOLIDAY",
  DeletePublicHoliday: "publicholidays/DELETE_PUBLIC_HOLIDAY",
  GetSelectedPublicHoliday: "publicholidays/GET_SELECTED_PUBLIC_HOLIDAY",
  UpdatePublicHoliday: "publicholidays/UPDATE_PUBLIC_HOLIDAY",
  SendCSVFile: "publicholidays/SEND_CSV_FILE",
  GetEnrolmentsByHoliday: "publicholidays/GET_ENROLMENTS_BY_HOLIDAY",
  SetEnrolmentsByHoliday: "publicholidays/SET_ENROLMENTS_BY_HOLIDAY",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  createPublicHoliday: (payload) => ({
    type: actionTypes.CreatePublicHoliday,
    payload,
  }),
  editPublicHoliday: (payload) => ({
    type: actionTypes.EditPublicHoliday,
    payload,
  }),
  getSelectedPublicHoliday: (payload) => ({
    type: actionTypes.GetSelectedPublicHoliday,
    payload,
  }),
  SetEnrolmentsByHoliday: (payload) => ({
    type: actionTypes.SetEnrolmentsByHoliday,
    payload,
  }),
  sendCSVFile: (payload) => ({
    type: actionTypes.SendCSVFile,
    payload,
  }),
};
