import { actionTypes } from "../actions/organisations";

const initialOrganisationState = {
  list: [],
  uuid: "",
  organisation: {},
  meta: {},
};

const organisationReducer = (state = initialOrganisationState, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list, organisation, meta, uuid } = action.payload;
      return {
        ...state,
        list,
        uuid,
        organisation,
        meta: { ...meta },
      };
    }

    case actionTypes.SetOrganisation: {
      const { organisation, meta } = action.payload;
      return {
        ...state,
        organisation,
        meta: { ...meta },
      };
    }

    default:
      return state;
  }
};

export default organisationReducer;
