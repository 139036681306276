export const actionTypes = {
  GetList: "locationSurcharge/GET_LIST",
  GetGroupList: "locationSurcharge/GET_GROUP_LIST",
  SetState: "locationSurcharge/SET_STATE",
  CreateLocationSurcharge: "locationSurcharge/CREATE_LOCATION_SURCHARGE",
  EditLocationSurcharge: "locationSurcharge/EDIT_LOCATION_SURCHARGE",
  DeleteLocationSurcharge: "locationSurcharge/DELETE_LOCATION_SURCHARGE",
  GetSelectedLocationSurcharge:
    "locationSurcharge/GET_SELECTED_LOCATION_SURCHARGE",
  SetSelectedLocationSurcharge:
    "locationSurcharge/SET_SELECTED_LOCATION_SURCHARGE",
  UpdateLocationSurcharge: "locationSurcharge/UPDATE_LOCATION_SURCHARGE",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  createLocationSurcharge: (payload) => ({
    type: actionTypes.CreateLocationSurcharge,
    payload,
  }),
  editLocationSurcharge: (payload) => ({
    type: actionTypes.EditLocationSurcharge,
    payload,
  }),
  getSelectedLocationSurcharge: (payload) => ({
    type: actionTypes.GetSelectedLocationSurcharge,
    payload,
  }),
  setSelectedLocationSurcharge: (payload) => ({
    type: actionTypes.SetSelectedLocationSurcharge,
    payload,
  }),
  deleteLocationSurcharge: (payload) => ({
    type: actionTypes.DeleteLocationSurcharge,
    payload,
  }),
};
