import { loaderActionTypes } from "../actions/loader";

const initialLoaderState = {
  loader: false,
  key: "Business",
};

const loaderReducer = (state = initialLoaderState, action) => {
  switch (action.type) {
    case loaderActionTypes.SetLoader: {
      const loader = action.payload;
      return {
        ...state,
        loader,
      };
    }

    case loaderActionTypes.SetKey: {
      const key = action.payload.key;
      return {
        ...state,
        key,
      };
    }

    default:
      return state;
  }
};

export default loaderReducer;
