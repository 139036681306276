export const actionTypes = {
  GetList: "transactions/GET_LIST",
  GetReportList: "transactions/GET_REPORT_LIST",
  SetState: "transactions/SET_STATE",
  SetLoader: "transactions/SET_LOADER",
  SetReportLoader: "transactions/SET_REPORT_LOADER",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  SetLoader: (payload) => ({ type: actionTypes.SetLoader, payload }),
  SetReportLoader: (payload) => ({
    type: actionTypes.SetReportLoader,
    payload,
  }),
};
