export const actionTypes = {
  GetList: "feedback/GET_LIST",
  SetState: "feedback/SET_STATE",
  GetSelectedFeedback: "feedback/GET_SELECTED_FEEDBACK",
  SetSelectedFeedback: "feedback/SET_SELECTED_FEEDBACK",
  CreateFeedback: "feedback/CREATE_FEEDBACK",
  DeleteFeedback: "feedback/DELETE_FEEDBACK",
  UpdateFeedback: "feedback/UPDATE_FEEDBACK",
  ChangeStatus: "feedback/CHANGE_STATUS",
  GetTutorList: "feedback/GET_TUTOR_LIST",
  SetTutorList: "feedback/SET_TUTOR_LIST",
  GetParentList: "feedback/GET_PARENT_LIST",
  SetParentList: "feedback/SET_PARENT_LIST",
};

export const actions = {
  GetList: (payload) => ({ type: actionTypes.GetList, payload }),
  SetState: (payload) => ({ type: actionTypes.SetState, payload }),
  GetSelectedFeedback: (payload) => ({
    type: actionTypes.GetSelectedFeedback,
    payload,
  }),
  SetSelectedFeedback: (payload) => ({
    type: actionTypes.SetSelectedFeedback,
    payload,
  }),
  CreateFeedback: (payload) => ({ type: actionTypes.CreateFeedback, payload }),
  DeleteFeedback: (payload) => ({ type: actionTypes.DeleteFeedback, payload }),
  UpdateFeedback: (payload) => ({ type: actionTypes.UpdateFeedback, payload }),
  ChangeStatus: (payload) => ({ type: actionTypes.ChangeStatus, payload }),

  GetTutorList: (payload) => ({
    type: actionTypes.GetTutorList,
    payload,
  }),
  SetTutorList: (payload) => ({
    type: actionTypes.SetTutorList,
    payload,
  }),
  GetParentList: (payload) => ({
    type: actionTypes.GetParentList,
    payload,
  }),
  SetParentList: (payload) => ({
    type: actionTypes.SetParentList,
    payload,
  }),
};
