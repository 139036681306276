import { actionTypes } from "../actions/programs";

const initialProgramsState = {
  list: [],
  meta: {},
  selected: {},
};

const programsReducer = (state = initialProgramsState, action) => {
  
  switch (action.type) {
    case actionTypes.SetState: {
      const { list, meta } = action.payload;
      return {
        ...state,
        list,
        meta: { ...meta },
      };
    }

    case actionTypes.GetSelectedProgram: {
      return {
        ...state,
        selected: { ...action.payload.selected },
      };
    }

    default:
      return state;
  }
};

export default programsReducer;
