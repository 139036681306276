export const actionTypes = {
  GetList: "regions/GET_LIST",
  GetSelected: "regions/GET_SELECTED",
  SetState: "regions/SET_STATE",
  CreateRegion: "regions/CREATE_REGION",
  EditRegion: "regions/EDIT_REGION",
  DeleteRegion: "regions/DELETE_REGION",
  GetReport: "regions/GET_REPORT",
  SetReport: "regions/SET_REPORT",
  GetEnrolmentsByRegion: "regions/GET_ENROLMENTS_BY_REGION",
  SetEnrolmentsByRegion: "regions/SET_ENROLMENTS_BY_REGION",
  SET_LOADING: "regions/SET_LOADING",
};

export const actions = {
  loading: (payload) => ({ type: actionTypes.SET_LOADING, payload }),
  SetEnrolmentsByRegion: (payload) => ({
    type: actionTypes.SetEnrolmentsByRegion,
    payload,
  }),
  SetReport: (payload) => ({
    type: actionTypes.SetReport,
    payload,
  }),
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  CreateRegion: (payload) => ({
    type: actionTypes.CreateRegion,
    payload,
  }),
  EditRegion: (payload) => ({
    type: actionTypes.EditRegion,
    payload,
  }),
  DeleteRegion: (payload) => ({
    type: actionTypes.DeleteRegion,
    payload,
  }),
  GetSelected: (payload) => ({
    type: actionTypes.GetSelected,
    payload,
  }),
};
