import { actionTypes } from "../actions/trials";

const initialTrialState = {
  list: [],
  count: "",
  show: false,
  selected: {},
  loading: false,
  allList: [],
  reportsData: {},
};

const trialsTypesReducer = (state = initialTrialState, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list, count, meta, show } = action.payload;
      return {
        ...state,
        list,
        count,
        show,
        meta: { ...meta },
      };
    }

    case actionTypes.SetAllTrailsState: {
      const { allList } = action.payload;
      return {
        ...state,
        allList
      };
    }

    case actionTypes.SetSelected: {
      const { selected } = action.payload;
      return {
        ...state,
        selected,
      };
    }

    case actionTypes.SetLoading: {
      const { loading } = action.payload;
      return {
        ...state,
        loading,
      };
    }

    case actionTypes.SetTrialsReportsData: {
      const { reportsData } = action.payload;
      return {
        ...state,
        reportsData,
      };
    }

    default:
      return state;
  }
};

export default trialsTypesReducer;
