export const actionTypes = {
  GetList: "products/GET_LIST",
  GetGroupList: "products/GET_GROUP_LIST",
  GetProductsByCategory: "products/GET_PRODUCTS_BY_CATEGORY",
  SetProductsByCategory: "products/GET_PRODUCTS_BY_CATEGORY",
  SetState: "products/SET_STATE",
  CreateProduct: "products/CREATE_PRODUCT",
  EditProduct: "products/EDIT_PRODUCT",
  DeleteProduct: "products/DELETE_PRODUCT",
  GetSelectedProduct: "products/GET_SELECTED_PRODUCT",
  UpdateProduct: "products/UPDATE_PRODUCT",
  GetProductsByUuid: "products/GET_PRODUCTS_BY_UUID",
  SetCart: "products/SET_CART",
  GetProductsReportsData: "products/GET_PRODUCTS_REPORTS_DATA",
  SetProductsReportsData: "products/SET_PRODUCTS_REPORTS_DATA",
  SetReportsLoad: "products/SET_REPORTS_LOAD",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  SetReportsLoad: (payload) => ({ type: actionTypes.SetReportsLoad, payload }),
  SetProductsReportsData: (payload) => ({
    type: actionTypes.SetProductsReportsData,
    payload,
  }),
  createProduct: (payload) => ({ type: actionTypes.CreateProduct, payload }),
  editProduct: (payload) => ({ type: actionTypes.EditProduct, payload }),
  getSelectedProduct: (payload) => ({
    type: actionTypes.GetSelectedProduct,
    payload,
  }),
  GetProductsByCategory: (payload) => ({
    type: actionTypes.GetProductsByCategory,
    payload,
  }),
  SetProductsByCategory: (payload) => ({
    type: actionTypes.SetProductsByCategory,
    payload,
  }),
  GetProductsByUuid: (payload) => ({
    type: actionTypes.GetProductsByUuid,
    payload,
  }),
  SetCart: (payload) => ({
    type: actionTypes.SetCart,
    payload,
  }),
};
