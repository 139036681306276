import { actionTypes } from "../actions/play";

const initialPlayReducer = {
  list: [],
  pastList: [],
  upcomingList: [],
  selected: {},
  locationSurcharge: [],
  cancelledList: [],
  loading: false,
};

const playReducer = (state = initialPlayReducer, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list } = action.payload;
      return {
        ...state,
        list,
      };
    }
    case actionTypes.SetPastPlay: {
      const { pastList } = action.payload;

      return {
        ...state,
        pastList,
      };
    }
    case actionTypes.SetFuturePlay: {
      const { upcomingList } = action.payload;
      return {
        ...state,
        upcomingList,
      };
    }

    case actionTypes.SetSelectedPlay: {
      const { selected } = action.payload;
      return {
        ...state,
        selected,
      };
    }

    case actionTypes.SetCancelledList: {
      const { cancelledList } = action.payload;
      return {
        ...state,
        cancelledList,
      };
    }

    case actionTypes.SetLocationSurcharge: {
      const { locationSurcharge } = action.payload;
      return {
        ...state,
        locationSurcharge,
      };
    }

    case actionTypes.SetLoading: {
      const { loading } = action.payload;
      return {
        ...state,
        loading,
      };
    }

    default:
      return state;
  }
};

export default playReducer;
