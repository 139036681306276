import baseApi from "../api/baseApi";

export function getAll() {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.get("/price/all");
}

export function create(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.post("/price/create", payload);
}

export function update(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.put("/price/update", payload);
}

export function deletePrice(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.delete(`/price/delete/${payload?.id}`);
}
