import { actionTypes } from "../actions/alert";

const initialAlertReducer = {
  list: [],
  selected: {},
  // meta: {},
};

const alertReducer = (state = initialAlertReducer, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list } = action.payload;
      return {
        ...state,
        list,
      };
    }
    case actionTypes.SetSelectedAlert: {
      const { selected } = action.payload;
      return {
        ...state,
        selected,
      };
    }

    default:
      return state;
  }
};

export default alertReducer;
