export const actionTypes = {
  GetList: "categories/GET_LIST",
  SetState: "categories/SET_STATE",
  CreateCategory: "categories/CREATE_CATEGORY",
  UpdateCategory: "categories/UPDATE_CATEGORY",
  DeleteCategory: "categories/DELETE_CATEGORY",
  GetSelectedCategory: "categories/GET_SELECTED_CATEGORY",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  createCategory: (payload) => ({ type: actionTypes.CreateCategory, payload }),
  updateCategory: (payload) => ({ type: actionTypes.UpdateCategory, payload }),
  deleteCategory: (payload) => ({ type: actionTypes.DeleteCategory, payload }),
  getSelectedCategory: (payload) => ({
    type: actionTypes.GetSelectedCategory,
    payload,
  }),
};
