import {
  getAll,
  deleteEmbedCode,
  create,
  getSelected,
  updateSelected,
} from "../../services/embedCode";
import { actionTypes, actions } from "../actions/embedCode";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    try {
      const result = yield call(getAll);
      return yield put(
        actions.setState({
          list: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(
    actionTypes.UpdateEmbedCode,
    function* updateSelectedEmbedCode(payload) {
      try {
        const result = yield call(updateSelected, payload.payload);
        yield put({
          type: actionTypes.GetList,
        });
        toast.success(result.data.message);
      } catch (error) {
        toast.error(error.response.data.error);
      }
    }
  );

  yield takeLatest(
    actionTypes.GetSelectedEmbedCode,
    function* getSelectedEmbedCode(payload) {
      try {
        const result = yield call(getSelected, payload.payload);
        return yield put(
          actions.getSelectedEmbedCode({
            selected: result.data,
          })
        );
      } catch (error) {
        console.log(error.response);
      }
    }
  );

  yield takeLatest(actionTypes.CreateEmbedCode, function* createEmbedCode(
    payload
  ) {
    try {
      const result = yield call(create, payload.payload.values);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(
    actionTypes.DeleteEmbedCode,
    function* deleteSelectedEmbedCode(payload) {
      try {
        const result = yield call(deleteEmbedCode, payload.payload);
        yield put({
          type: actionTypes.GetList,
        });
        toast.success(result.data.message);
        return yield put(
          actions.setState({
            meta: null,
          })
        );
      } catch (error) {
        toast.error(error.response.data.error);
      }
    }
  );
}
