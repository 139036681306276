export const actionTypes = {
  GetCurrent: "terms/GET_CURRENT",
  GetSelected: "terms/GET_SELECTED",
  GetList: "terms/GET_LIST",
  SetState: "terms/SET_STATE",
  SetCurrent: "terms/SET_CURRENT",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  setCurrent: (payload) => ({ type: actionTypes.SetCurrent, payload }),
  GetSelected: (payload) => ({ type: actionTypes.GetSelected, payload }),
  //   getCurrent: (payload) => ({
  //     type: actionTypes.GetCurrent,
  //     payload,
  //   }),
};
