import baseApi from "../api/baseApi";

export function getAllCoachesReports(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  if (!payload || (!payload.from && !payload.to && !payload.timeframe)) {
    return baseApi.get("/service/coaches");
  } else {
    return baseApi.get(
      `/service/coaches/${payload.from ? payload.from : "none"}/${
        payload.to ? payload.to : "none"
      }/${payload.timeframe ? payload.timeframe : "none"}`
    );
  }
  // return baseApi.get("/service/coaches");
}

export function getEnrolmentsByService() {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get("/service/enrolmentsReport");
}

export function getAll(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  if (payload && payload.tag) {
    return baseApi.get(`/service/all`, {
      params: { tag: payload.tag },
    });
  } else {
    return baseApi.get("/service/all");
  }
}

export function getReport(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  if (!payload || (!payload.from && !payload.to && !payload.timeframe)) {
    return baseApi.get(`/service/revenueReport/`);
  } else {
    return baseApi.get(
      `/service/revenueReport/${payload.from ? payload.from : "none"}/${
        payload.to ? payload.to : "none"
      }/${payload.timeframe ? payload.timeframe : "none"}`
    );
  }
}

export function getSelected(payload) {
  if (payload.id) {
    const authToken = () =>
      JSON.parse(localStorage.getItem("persist:auth"))
        ? JSON.parse(localStorage.getItem("persist:auth")).authToken
        : "";

    baseApi.defaults.headers.common["Authorization"] = authToken()
      ? JSON.parse(authToken()).token
      : "";
    return baseApi.get(`/service/get/${payload.id}`);
  }
}

export function create(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.post(`/service/create`, payload);
}

export function deleteService(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.delete(`/service/delete/${payload.id}`);
}

export function updateSelected(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.put(`/service/update/${payload.id}`, payload);
}
