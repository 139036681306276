export const actionTypes = {
  GetList: "enrolments/GET_LIST", 
  GetAllEnrolment: "enrolments/GET_ALL_ENROLMENTS",
  GetActiveEnrolmentReport: "enrolments/GET_ACTIVE_ENROLMENT_REPORT",
  SetActiveEnrolmentReport: "enrolments/SET_ACTIVE_ENROLMENT_REPORT",
  GetFullPaymentsEnrolmentReport: "enrolments/GET_FULL_PAYMENTS_ENROLMENT_REPORT",
  SetFullPaymentsEnrolmentReport: "enrolments/SET_FULL_PAYMENTS_ENROLMENT_REPORT",
  GetPendingPaymentsEnrolmentReport: "enrolments/GET_PENDING_PAYMENTS_ENROLMENT_REPORT",
  GetNextMonthPaymentsEnrolmentReport: "enrolments/GET_NEXT_MONTH_PAYMENTS_ENROLMENT_REPORT",
  
  GetEnrolmentsOfFutureLessons: "enrolments/GET_ENROLMENTS_OF_FUTURE_LESSONS",
  GetFilteredList: "enrolments/GET_FILTERED_LIST",
  SetFilteredList: "enrolments/SET_FILTERED_LIST",

  GetFilteredGraphData: "enrolments/GET_FILTERED_GRAPH_DATA",
  SetFilteredGraphData: "enrolments/SET_FILTERED_GRAPH_DATA",

  GetFilteredCompareData: "enrolments/GET_FILTERED_COMPARE_DATA",
  SetFilteredCompareData: "enrolments/SET_FILTERED_COMPARE_DATA",

  GetCancelledList: "enrolments/GET_CANCELLED_LIST",
  SetCancelledList: "enrolments/SET_CANCELLED_LIST",

  CreateEnrolment: "enrolments/CREATE_ENROLMENT",
  GetEnrolmentAmount: "enrolments/GET_ENROLMENT_AMOUNT",
  UpdateEnrolment: "enrolments/UPDATE_ENROLMENT",

  GetPrevEnrolmentByParent: "enrolments/GET_PREV_ENROLMENT_BY_PARENT",
  SetPrevEnrolmentByParent: "enrolments/SET_PREV_ENROLMENT_BY_PARENT",
  GetFutureEnrolmentByParent: "enrolments/GET_FUTURE_ENROLMENT_BY_PARENT",
  SetFutureEnrolmentByParent: "enrolments/SET_FUTURE_ENROLMENT_BY_PARENT",

  GetEnrolmentByParent: "enrolments/GET_ENROLMENT_BY_PARENT",
  SetEnrolmentByParent: "enrolments/SET_ENROLMENT_BY_PARENT",

  GetEnrolmentByClass: "enrolments/GET_ENROLMENT_BY_CLASS",
  SetEnrolmentByClass: "enrolments/SET_ENROLMENT_BY_CLASS",

  GetUpcomingEnrolment: "enrolments/GET_UPCOMING_ENROLMENTS",

  SetState: "enrolments/SET_STATE",

  ShowModal: "enrolments/SHOW_MODAL",
  HideModal: "enrolments/HIDE_MODAL",

  ExportCsvFile: "enrolments/EXPORT_CSV_FILE",

  GetGraph: "enrolments/GET_GRAPH",
  SetGraph: "enrolments/SET_GRAPH",

  GetSelected: "enrolments/GET_SELECTED",
  SetSelected: "enrolments/SET_SELECTED",

  GetSelectedPage: "enrolments/GET_SELECTED_PAGE",
  SetSelectedPage: "enrolments/SET_SELECTED_PAGE",

  AddMessage: "enrolments/ADD_MESSAGE",
  EditMessage: "enrolments/EDIT_MESSAGE",
  DeleteMessage: "enrolments/DELETE_MESSAGE",

  DeleteLesson: "enrolments/DELETE_LESSON",
  GetEnrolmentsOfALesson: "enrolments/GET_ENROLMENTS_OF_A_LESSON",
  GetParentsByMultipleClassesOrLocation:
    "enrolments/GET_PARENTS_BY_MULTIPLE_CLASSES_OR_LOCATION",
  GetEnrolmentsByLocationOrVenue:
    "enrolments/GET_ENROLMENTS_BY_LOCATION_OR_VENUE",
  SetEnrolmentsByLocationOrVenue:
    "enrolments/SET_ENROLMENTS_BY_LOCATION_OR_VENUE",
  SetEnrolmentByLocationLoader: "enrolments/SET_ENROLMENTS_BY_LOCATION_LOADER",
  GetReportsByAge: "enrolments/GET_REPORTS_BY_AGE",
  SetReportsByAge: "enrolments/SET_REPORTS_BY_AGE",
  GetReportsByCancellations: "enrolments/GET_REPORTS_BY_CANCELLATIONS",
  SetReportsByCancellations: "enrolments/SET_REPORTS_BY_CANCELLATIONS",
  GetAllUnpaidPaymentsLink: "enrolments/GET_ALL_UNPAID_PAYMENTS_LINK",
  SetAllUnpaidPaymentsLink: "enrolments/SET_ALL_UNPAID_PAYMENTS_LINK",
  SetLoadingFutureEnrolments: "enrolments/SET_LOADING_FUTURE_ENROLMENTS",
  SetLoading: "enrolments/SET_LOADING",
  SetLoadingEnrolments: "enrolments/SET_LOADING_ENROLMENTS",
  GetHearAboutUsReport: "enrolments/GET_HEAR_ABOUT_US_REPORT",
  SetHearAboutUsReport: "enrolments/SET_HEAR_ABOUT_US_REPORT",
  SetHearAboutUsReportLoading : "enrolments/SET_HEAR_ABOUT_US_LOADING",
};

export const actions = {

  // GetAllEnrolment: (payload) => ({
  //   type: actionTypes.SetAllEnrolments,
  //   payload,
  // }),
  SetHearAboutUsReport: (payload) => ({
    type: actionTypes.SetHearAboutUsReport,
    payload,
  }),
  SetHearAboutUsReportLoading: (payload) => ({
    type: actionTypes.SetHearAboutUsReportLoading,
    payload,
  }),
  SetActiveEnrolmentReport: (payload) => ({
    type: actionTypes.SetActiveEnrolmentReport,
    payload,
  }),
  SetFullPaymentsEnrolmentReport: (payload) => ({
    type: actionTypes.SetFullPaymentsEnrolmentReport
  }),
  SetLoadingEnrolments: (payload) => ({
    type: actionTypes.SetLoadingEnrolments,
    payload,
  }),
  SetLoading: (payload) => ({
    type: actionTypes.SetLoading,
    payload,
  }),
  SetLoadingFutureEnrolments: (payload) => ({
    type: actionTypes.SetLoadingFutureEnrolments,
    payload,
  }),
  SetReportsByCancellations: (payload) => ({
    type: actionTypes.SetReportsByCancellations,
    payload,
  }),
  SetAllUnpaidPaymentsLink: (payload) => ({
    type: actionTypes.SetAllUnpaidPaymentsLink,
    payload,
  }),
  SetReportsByAge: (payload) => ({
    type: actionTypes.SetReportsByAge,
    payload,
  }),
  SetEnrolmentsByLocationOrVenue: (payload) => ({
    type: actionTypes.SetEnrolmentsByLocationOrVenue,
    payload,
  }),
  SetEnrolmentByLocationLoader: (payload) => ({
    type: actionTypes.SetEnrolmentByLocationLoader,
    payload,
  }),
  GetCancelledList: (payload) => ({
    type: actionTypes.GetCancelledList,
    payload,
  }),

  GetFilteredList: (payload) => ({
    type: actionTypes.GetFilteredList,
    payload,
  }),

  GetFilteredGraphData: (payload) => ({
    type: actionTypes.GetFilteredGraphData,
    payload,
  }),

  GetFilteredCompareData: (payload) => ({
    type: actionTypes.GetFilteredCompareData,
    payload,
  }),

  SetCancelledList: (payload) => ({
    type: actionTypes.SetCancelledList,
    payload,
  }),
  GetParentsByMultipleClassesOrLocation: (payload) => ({
    type: actionTypes.GetParentsByMultipleClassesOrLocation,
    payload,
  }),
  CreateEnrolment: (payload) => ({
    type: actionTypes.CreateEnrolment,
    payload,
  }),

  GetEnrolmentAmount: (payload) => ({
    type: actionTypes.GetEnrolmentAmount,
    payload,
  }),

  UpdateEnrolment: (payload) => ({
    type: actionTypes.UpdateEnrolment,
    payload,
  }),
  GetPrevEnrolmentByParent: (payload) => ({
    type: actionTypes.GetPrevEnrolmentByParent,
    payload,
  }),
  SetPrevEnrolmentByParent: (payload) => ({
    type: actionTypes.SetPrevEnrolmentByParent,
    payload,
  }),
  GetFutureEnrolmentByParent: (payload) => ({
    type: actionTypes.GetFutureEnrolmentByParent,
    payload,
  }),
  SetFutureEnrolmentByParent: (payload) => ({
    type: actionTypes.SetFutureEnrolmentByParent,
    payload,
  }),
  GetSelected: (payload) => ({
    type: actionTypes.GetSelected,
    payload,
  }),

  GetEnrolmentByParent: (payload) => ({
    type: actionTypes.GetEnrolmentByParent,
    payload,
  }),

  SetFilteredList: (payload) => ({
    type: actionTypes.SetFilteredList,
    payload,
  }),

  SetFilteredGraphData: (payload) => ({
    type: actionTypes.SetFilteredGraphData,
    payload,
  }),

  SetFilteredCompareData: (payload) => ({
    type: actionTypes.SetFilteredCompareData,
    payload,
  }),

  SetEnrolmentByParent: (payload) => ({
    type: actionTypes.SetEnrolmentByParent,
    payload,
  }),

  GetUpcomingEnrolment: (payload) => ({
    type: actionTypes.GetUpcomingEnrolment,
    payload,
  }),
  GetEnrolmentByClass: (payload) => ({
    type: actionTypes.GetEnrolmentByClass,
    payload,
  }),
  SetEnrolmentByClass: (payload) => ({
    type: actionTypes.SetEnrolmentByClass,
    payload,
  }),

  GetSelectedPage: (payload) => ({
    type: actionTypes.GetSelectedPage,
    payload,
  }),
  SetSelectedPage: (payload) => ({
    type: actionTypes.SetSelectedPage,
    payload,
  }),

  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  exportCsvFile: (payload) => ({
    type: actionTypes.ExportCsvFile,
    payload,
  }),

  getGraph: (payload) => ({
    type: actionTypes.GetGraph,
    payload,
  }),
  setGraph: (payload) => ({
    type: actionTypes.SetGraph,
    payload,
  }),
  setSelected: (payload) => ({
    type: actionTypes.SetSelected,
    payload,
  }),
  AddMessage: (payload) => ({
    type: actionTypes.AddMessage,
    payload,
  }),
  EditMessage: (payload) => ({
    type: actionTypes.EditMessage,
    payload,
  }),
  DeleteMessage: (payload) => ({
    type: actionTypes.DeleteMessage,
    payload,
  }),

  DeleteLesson: (payload) => ({
    type: actionTypes.DeleteLesson,
    payload,
  }),
  GetEnrolmentsOfALesson: (payload) => ({
    type: actionTypes.GetEnrolmentsOfALesson,
    payload,
  }),
};
