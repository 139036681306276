import { actionTypes } from "../actions/booking";

const initialBookingReducer = {
  AssignFortutor: [],
  list: [],
  pastList: [],
  upcomingList: [],
  selected: {},
  cancelledList: [],
  scheduleList: [],
  counts: {},
  createBookingLoading: false,
};

const bookingReducer = (state = initialBookingReducer, action) => {
  switch (action.type) {

    case actionTypes.SetAssignFortutor: {
      const { AssignFortutor } = action.payload;
      return {
        ...state,
        AssignFortutor,
      };
    }
    
    case actionTypes.SetState: {
      const { list } = action.payload;
      return {
        ...state,
        list,
      };
    }

    case actionTypes.LoadingCreateBooking: {
      const { loading } = action.payload;
      return {
        ...state,
        createBookingLoading: loading,
      };
    }

    case actionTypes.SetPastBooking: {
      const { pastList } = action.payload;
      return {
        ...state,
        pastList,
      };
    }
    case actionTypes.SetFutureBooking: {
      const { upcomingList } = action.payload;
      return {
        ...state,
        upcomingList,
      };
    }
    case actionTypes.SetSelectedBooking: {
      const { selected } = action.payload;
      return {
        ...state,
        selected,
      };
    }

    case actionTypes.SetCancelledList: {
      const { cancelledList } = action.payload;
      return {
        ...state,
        cancelledList,
      };
    }

    case actionTypes.SetScheduleBooking: {
      const { scheduleList } = action.payload;
      return {
        ...state,
        scheduleList,
      };
    }

    case actionTypes.SetCounts: {
      const { counts } = action.payload;
      return {
        ...state,
        counts,
      };
    }

    default:
      return state;
  }
};

export default bookingReducer;
