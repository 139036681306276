export const actionTypes = {
  GetList: "play/GET_LIST",
  GetGroupList: "play/GET_GROUP_LIST",

  GetPastPlay: "play/GET_PAST_LIST",
  SetPastPlay: "play/SET_PAST_LIST",

  GetFuturePlay: "play/GET_UPCOMING_LIST",
  SetFuturePlay: "play/SET_UPCOMING_LIST",

  SetState: "play/SET_STATE",
  CreatePlay: "play/CREATE_PLAY",
  DeletePlay: "play/DELETE_PLAY",

  GetSelectedPlay: "play/GET_SELECTED_PLAY",
  SetSelectedPlay: "play/SET_SELECTED_PLAY",

  GetCancelledList: "play/GET_CANCELLED_LIST",
  SetCancelledList: "play/SET_CANCELLED_LIST",

  UpdatePlay: "play/UPDATE_PLAY",

  GetLocationSurcharge: "play/GET_LOCATION_SURCHARGE",
  SetLocationSurcharge: "play/SET_LOCATION_SURCHARGE",
  SetLoading: "play/SET_LOADING",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  SetLoading: (payload) => ({ type: actionTypes.SetLoading, payload }),
  CreatePlay: (payload) => ({
    type: actionTypes.CreatePlay,
    payload,
  }),
  UpdatePlay: (payload) => ({
    type: actionTypes.UpdatePlay,
    payload,
  }),
  GetSelectedPlay: (payload) => ({
    type: actionTypes.GetSelectedPlay,
    payload,
  }),
  SetSelectedPlay: (payload) => ({
    type: actionTypes.SetSelectedPlay,
    payload,
  }),
  GetList: (payload) => ({
    type: actionTypes.GetList,
    payload,
  }),
  GetPastPlay: (payload) => ({
    type: actionTypes.GetPastPlay,
    payload,
  }),
  SetPastPlay: (payload) => ({
    type: actionTypes.SetPastPlay,
    payload,
  }),
  GetFuturePlay: (payload) => ({
    type: actionTypes.GetFuturePlay,
    payload,
  }),
  SetFuturePlay: (payload) => ({
    type: actionTypes.SetFuturePlay,
    payload,
  }),

  GetCancelledList: (payload) => ({
    type: actionTypes.GetCancelledList,
    payload,
  }),
  SetCancelledList: (payload) => ({
    type: actionTypes.SetCancelledList,
    payload,
  }),

  GetLocationSurcharge: (payload) => ({
    type: actionTypes.GetLocationSurcharge,
    payload,
  }),
  SetLocationSurcharge: (payload) => ({
    type: actionTypes.SetLocationSurcharge,
    payload,
  }),
};
