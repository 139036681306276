import React from "react";

function ParentShopLeft({
  filteredProducts,
  selectedSizes,
  setSelectedSizes,
  selectedQuantity,
  setSelectedQuantity,
  toast,
  addToCart,
}) {
  return (
    <div className="card card-custom card-stretch gutter-b">
      <div className="card-body">
        <div className="row">
          {filteredProducts &&
            filteredProducts.map((product, index) => (
              <div className="col-md-4 mb-8">
                <div className="card card-custom card-shadowless">
                  <div className="card-body p-0">
                    <div className="overlay">
                      <div className="overlay-wrapper rounded text-center">
                        <img
                          src={product.featureImage}
                          alt={product.name}
                          style={{ maxHeight: "180px", width: "auto" }}
                        />
                      </div>
                    </div>

                    <div className="row mt-5 mb-md-0 mb-lg-5 mb-md-0 mb-lg-5 mb-lg-0 mb-5 d-flex flex-column">
                      <p className="col-md-12 font-size-h5 font-weight-bolder text-dark-75 mb-1">
                        {product.name}
                      </p>
                      <p className="col-md-12 font-size-h5 font-weight-bolder text-dark-75 mb-1">
                        ${product.price}
                      </p>
                    </div>

                    <div className="row">
                      <div className="col-6">
                        <div className="form-group">
                          <label for="sizeSelect">
                            Size
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control"
                            id="sizeSelect"
                            value={selectedSizes[index]}
                            onChange={(e) => {
                              let tempSizes = [...selectedSizes];
                              tempSizes[index] = e.target.value;
                              setSelectedSizes(tempSizes);
                            }}
                          >
                            <option value="">Select</option>
                            {product.attributes &&
                              product.attributes.map((attr) => (
                                <option value={`${attr.label}`}>
                                  {attr.label}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label for="qtySelect">
                            Qty
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="number"
                            id="qtySelect"
                            placeholder="Qty"
                            step="any"
                            // oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                            max={10}
                            min={1}
                            value={selectedQuantity[index]}
                            onChange={(e) => {
                              if (
                                parseInt(e.target.value) <= 10 &&
                                parseInt(e.target.value) >= 1
                              ) {
                                let tempQuantity = [...selectedQuantity];
                                tempQuantity[index] = e.target.value;
                                setSelectedQuantity(tempQuantity);
                              } else {
                                let tempQuantity = [...selectedQuantity];
                                tempQuantity[index] = "1";
                                setSelectedQuantity(tempQuantity);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <button
                      className="btn btn-primary btn-block font-size-sm"
                      onClick={() => {
                        if (
                          (!selectedQuantity[index] ||
                            selectedQuantity[index] === "") &&
                          (!selectedSizes[index] || selectedSizes[index] === "")
                        ) {
                          toast.error(
                            "Please enter <strong>all details</strong> for item"
                          );
                        } else if (selectedQuantity[index] % 1 != 0) {
                          toast.error(
                            "Please enter a <strong>quantity</strong> without decimal point"
                          );
                        } else {
                          if (
                            !selectedQuantity[index] ||
                            selectedQuantity[index] === ""
                          ) {
                            toast.error(
                              "Please enter <strong>quantity</strong> for item"
                            );
                          }
                          if (
                            !selectedSizes[index] ||
                            selectedSizes[index] === ""
                          ) {
                            toast.error(
                              "Please select <strong>size</strong> for item"
                            );
                          }
                          if (
                            selectedQuantity[index] &&
                            selectedSizes[index] &&
                            product
                          ) {
                            addToCart({
                              qty: selectedQuantity[index],
                              size: selectedSizes[index],
                              product,
                            });

                            let tempQuantity = [...selectedQuantity];
                            tempQuantity[index] = "";
                            setSelectedQuantity(tempQuantity);

                            let tempSizes = [...selectedSizes];
                            tempSizes[index] = "";
                            setSelectedSizes(tempSizes);

                            toast.success("Item added to cart!");
                          }
                        }
                      }}
                    >
                      <i className="flaticon2-shopping-cart-1"></i> Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default ParentShopLeft;
