export const actionTypes = {
  GetList: "notes/GET_LIST",
  SetState: "notes/SET_STATE",
  GetParentList: "notes/GET_PARENT_LIST",
  SetParentList: "notes/SET_PARENT_LIST",
  CreateNote: "notes/CREATE_NOTE",
  DeleteNote: "notes/DELETE_NOTE",
  GetSelectedNote: "notes/GET_SELECTED_NOTE",
  SetSelectedNote: "notes/SET_SELECTED_NOTE",
  UpdateNote: "notes/UPDATE_NOTE",
};

export const actions = {
  GetList: (payload) => ({ type: actionTypes.GetList, payload }),
  SetState: (payload) => ({ type: actionTypes.SetState, payload }),
  CreateNote: (payload) => ({ type: actionTypes.CreateNote, payload }),
  DeleteNote: (payload) => ({ type: actionTypes.DeleteNote, payload }),
  GetSelectedNote: (payload) => ({
    type: actionTypes.GetSelectedNote,
    payload,
  }),
  SetSelectedNote: (payload) => ({
    type: actionTypes.SetSelectedNote,
    payload,
  }),
  UpdateNote: (payload) => ({ type: actionTypes.UpdateNote, payload }),
  GetParentList: (payload) => ({
    type: actionTypes.GetParentList,
    payload,
  }),
  SetParentList: (payload) => ({
    type: actionTypes.SetParentList,
    payload,
  }),
};
