import { actionTypes } from "../actions/teams";

const initialTeamReducer = {
  list: [],
  selected: {},
  // meta: {},
};

const teamReducer = (state = initialTeamReducer, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list } = action.payload;
      return {
        ...state,
        list,
      };
    }

    case actionTypes.GetSelectedTeam: {
      return {
        ...state,
        selected: { ...action.payload.selected },
      };
    }

    default:
      return state;
  }
};

export default teamReducer;
