/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from "react";
import { Redirect, Switch, Route, useHistory } from "react-router-dom";
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";
import Logout from "./modules/Auth";
import "./common-styles.scss";
import AppStylesSelector from "./hoc/AppStylesSelector";
import { isPublicPage } from "./Common/_routehepers";
import Can from "./Ability/Can";
import { PERMISSIONS, ROLES_ACCESS_KEYS } from "./Common/rolesConstants";
import { abilityHandler } from "./Ability/ability";
import ShopPage from "./pages/Shop";
const UnsubscribePage = React.lazy(() => import("./pages/UnsubscribePage"));

const AuthPage = React.lazy(() => import("./modules/Auth/pages/AuthPage"));
const ParentBasePage = React.lazy(() =>
  import("./pages/ParentPortal/ParentPortalBasePage")
);
const BasePage = React.lazy(() => import("./BasePage"));

const TutorBasePage = React.lazy(() => import("./TutorBasePage"));

const ErrorsPage = React.lazy(() =>
  import("./modules/ErrorsExamples/ErrorsPage")
);

const Layout = React.lazy(() =>
  import("../_metronic/layout/components/Layout")
);

const EnrolmentPage = React.lazy(() =>
  import("./pages/EnrolmentFormPages/ChildCareEnrolmentPage")
);

const SecondEnrolmentPage = React.lazy(() =>
  import("./pages/EnrolmentFormPages/WeeklyEnrolmentsPage")
);
const TrialsPage = React.lazy(() => import("./pages/Trials/WeeklyTrialsPage"));

const BookingThankYou = React.lazy(() =>
  import("./components/ThankYou/BookingThankYou")
);

const BirthdayPartyPage = React.lazy(() =>
  import("./pages/BirthdayParty/BirthdayPartyCrud/CreateBirthdayParty")
);

const BookingPage = React.lazy(() =>
  import("./pages/Booking/BookingCrud/CreateBooking")
);

const BookaCoachPage = React.lazy(() =>
  import("./pages/BookaCoach/BookaCoachCrud/CreateBookaCoach")
);

const EditBookaCoach = React.lazy(() => 
import("./pages/BookaCoach/BookaCoachCrud/EditBookaCoachFullForm")
);

const BACPage = React.lazy(() =>
  import("./pages/BookaCoach/BookaCoachCrud/CreateBookaCoachHalfForm")
);

const InflatablePage = React.lazy(() =>
  import("./pages/Inflatable/InflatableCrud/CreateInflatable")
);

const PlayPage = React.lazy(() => import("./pages/Play/PlayCrud/CreatePlay"));

const PaymentLinkPage = React.lazy(() =>
  import("./pages/PaymentLinkPage/index")
);

const BirthDayPartyAdditionalInfoLinkPage = React.lazy(() =>
  import(
    "./pages/BirthdayParty/BirthDayPartyAdditionalLinkPage/BirthDayPartyAdditionalLinkPage"
  )
);

const Routes = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (props?.auth?.authToken?.token?.length > 0 && props?.auth?.user?._id) {
      dispatch({
        type: "[User] Validate User",
        payload: {
          token: props.auth.authToken,
        },
      });

      dispatch({
        type: "[User] Get Session Data",
      });
    }
  }, [props?.auth?.authToken?.token]);

  const history = useHistory();
  const { isAuthorized, role } = useSelector(
    ({ auth }) => ({
      isAuthorized:
        auth.user != null && auth.user && auth.user.business !== "quest",
      role: auth.user && auth.user.roleId ? auth.user.roleId.name : "",
    }),
    shallowEqual
  );

  return (
    <AppStylesSelector>
      {isPublicPage(history) ? (
        <Switch>
         <Route
            exact
            key={Date.now()}
            path="/:uuid/public-shop/thankyou/:business"
            component={ShopPage}
          />
          <Route
            exact
            key={Date.now()}
            path="/:uuid/public-shop/:business"
            component={ShopPage}
          />
          <Route
            exact
            key={Date.now()}
            path="/:uuid/public-shop/checkout/:business"
            component={ShopPage}
          />
          <Route
            exact
            path="/:uuid/updateBirthDayParty/birthday/:birthdayId/:business"
            component={BirthDayPartyAdditionalInfoLinkPage}
          />
          <Route
            exact
            key={Date.now()}
            path="/:uuid/weekly/enrollment/trial/:business"
            component={TrialsPage}
          />
          <Route
            exact
            key={Date.now()}
            path="/:uuid/:location/weekly/enrollment/trial/:business"
            component={TrialsPage}
          />

          <Route
            exact
            key={Date.now()}
            path="/:uuid/:location/selected-location/weekly/:business"
            component={SecondEnrolmentPage}
          />

          <Route
            exact
            key={Date.now()}
            path="/:uuid/class/weekly/:classId/:business"
            component={SecondEnrolmentPage}
          />
          <Route
            exact
            key={Date.now()}
            path="/:uuid/enrolment/:business"
            component={EnrolmentPage}
          />
          <Route
            exact
            key={Date.now()}
            path="/:uuid/:venue/enrolment/:business"
            component={EnrolmentPage}
          />
          <Route
            exact
            key={Date.now()}
            path="/:uuid/enrolment/:tag/:business"
            component={EnrolmentPage}
          />
          <Route
            exact
            key={Date.now()}
            path="/:uuid/enrolment/selected-class/:classId/:business"
            component={EnrolmentPage}
          />

          <Route
            exact
            key={Date.now()}
            path="/:uuid/weekly/:tag/:business"
            component={SecondEnrolmentPage}
          />

          <Route
            exact
            key={Date.now()}
            path="/:uuid/weekly/:business"
            component={SecondEnrolmentPage}
          />

          <Route
            exact
            key={Date.now()}
            path="/:uuid/:location/weekly/:business"
            component={SecondEnrolmentPage}
          />

          <Route
            exact
            key={Date.now()}
            path="/thankyou/:bookingType/:business"
            component={BookingThankYou}
          />
          <Route
            exact
            key={Date.now()}
            path="/thankyou/:bookingType/:business/:product"
            component={BookingThankYou}
          />

          <Route
            exact
            key={Date.now()}
            path="/:organizationId/birthday/:business"
            component={BirthdayPartyPage}
          />

          <Route
            exact
            key={Date.now()}
            path="/:organizationId/booking/:business"
            component={BookingPage}
          />

          <Route
            exact
            key={Date.now()}
            path="/:organizationId/inflatable/:business"
            component={InflatablePage}
          />
          <Route
            exact
            key={Date.now()}
            path="/:organizationId/bookacoach/:business"
            component={BookaCoachPage}
          />
          <Route
            exact
            key={Date.now()}
            path="/editbookacoach/:bookacoachId"
            component={EditBookaCoach}
          />
          <Route
            exact
            key={Date.now()}
            path="/:organizationId/play/:product/:business"
            component={PlayPage}
          />

          <Route
            exact
            path="/payment-link/:uuid/:enrolment"
            component={PaymentLinkPage}
          />
          <Route
            exact
            path="/unsubscribe/:type/:id"
            component={UnsubscribePage}
          />
          <Route
            exact
            key={Date.now()}
            path="/:organizationId/book-a-coach-half-form/:business"
            component={BACPage}
          />
        </Switch>
      ) : (
        <Switch>
          {!isAuthorized ? (
            /*Render auth page when user at `/auth` and not authorized.*/
            <Route>
              <AuthPage props={props} />
            </Route>
          ) : (
            /*Otherwise redirect to root page (`/`)*/
            <Redirect from="/auth" to="/" />
          )}

          <Route path="/error" component={ErrorsPage} />
          <Route path="/logout" component={Logout} />

          {!isAuthorized ? (
            /*Redirect to `/auth` when user is not authorized*/
            <Redirect to="/auth/login" />
          ) : (
            <>
              <Layout>
                {role === "tutor" ? (
                  <TutorBasePage />
                ) : role === "parent" ? (
                  <ParentBasePage />
                ) : abilityHandler(
                    PERMISSIONS.view,
                    ROLES_ACCESS_KEYS.dashboard
                  ) ? (
                  <BasePage />
                ) : (
                  <Redirect to="/auth/login" />
                )}
              </Layout>
            </>
          )}
        </Switch>
      )}
    </AppStylesSelector>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const WrappedRoutes = connect(mapStateToProps)(Routes);

export { WrappedRoutes as Routes };
