import {
  create,
  getSelected,
  getPublicSettings,
  getMailChimpList,
  getMailChimpSelected,
  getSimpleSetting,
  getsimplePublicSettings,
  getLogoAndLinks,
  getSelectedSetting,
} from "../../services/settings";
import { actionTypes, actions } from "../actions/settings";

import { loaderActions } from "../actions/loader";
import { put, takeLatest, call, takeEvery } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.CreateSetting, function* createStudent({
    payload,
  }) {
    try {
      const result = yield call(create, payload.values);

      //used to stop tabs from changing and showing the loader
      yield put(
        loaderActions.SetKey({
          key: payload?.tabToChange,
        })
      );

      yield put(
        loaderActions.SetLoader({
          loader: true,
        })
      );

      yield put(
        actions.setState({
          setting: result.data.result,
        })
      );

      const mclist = yield call(getMailChimpList, null);
      yield put(
        actions.SetMailchimp({
          mailchimpLists: mclist.data.result,
        })
      );

      // actionTypes.

      toast.success(result?.data?.message);

      //set loader equals to false
      yield put(
        loaderActions.SetLoader({
          loader: false,
        })
      );

      yield put(
        actions.GetLogoAndDetails({
          business: payload?.values?.business,
        })
      );
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error);
    }
  });

  yield takeEvery(actionTypes.GetSetting, function* getSelectedParent(payload) {
    try {
      const result = yield call(getSelected, payload);

      if (payload.payload.business.toLowerCase() === "ginger") {
        yield put(
          actions.SetGingerState({
            setting: result.data.result,
          })
        );
      } else if (payload.payload.business.toLowerCase() === "quest") {
        yield put(
          actions.SetQuestState({
            setting: result.data.result,
          })
        );
      }
      return yield put(
        actions.setState({
          setting: result.data.result,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeEvery(actionTypes.GetSettingForPublic, function* getPublicSettingsForPublic(payload) {
    try {
      const result = yield call(getSelectedSetting, payload);
      if (payload.payload.business.toLowerCase() === "ginger") {
        yield put(
          actions.SetGingerState({
            setting: result.data.result,
          })
        );
      } else if (payload.payload.business.toLowerCase() === "quest") {
        yield put(
          actions.SetQuestState({
            setting: result.data.result,
          })
        );
      }
      return yield put(
        actions.setState({
          setting: result.data.result,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeEvery(
    actionTypes.GetLogoAndDetails,
    function* getLogoAndLinksForSelectedBusiness(payload) {
      try {
        const result = yield call(getLogoAndLinks, payload);

        yield put(
          actions.SetLogoAndDetails({
            orgDetails: result.data.result,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  );

  yield takeEvery(
    actionTypes.GetSimplePublicSetting,
    function* getSimplePublicSingle(payload) {
      try {
        const result = yield call(getsimplePublicSettings, payload);

        if (payload.payload.business.toLowerCase() === "ginger") {
          yield put(
            actions.SetGingerState({
              setting: result.data.result,
            })
          );
        } else if (payload.payload.business.toLowerCase() === "quest") {
          yield put(
            actions.SetQuestState({
              setting: result.data.result,
            })
          );
        }
        return yield put(
          actions.setState({
            setting: result.data.result,
          })
        );
      } catch (error) {
        console.log(error.response);
      }
    }
  );

  yield takeLatest(actionTypes.GetSimpleSetting, function* getSelectedParent(
    payload
  ) {
    try {
      const result = yield call(getSimpleSetting, payload);

      return yield put(
        actions.SetSettingForPublic({
          setting: result.data.result,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  });

  yield takeLatest(
    actionTypes.GetMailChimpSetting,
    function* getMailChimpSettings() {
      try {
        const result = yield call(getMailChimpList, null);
        return yield put(
          actions.SetMailchimp({
            mailchimpLists: result.data.result,
          })
        );
      } catch (error) {
        console.log("There was an error");
        console.log(error.response);
      }
    }
  );

  yield takeLatest(actionTypes.GetMailChimpList, function* getMailChimpLst(
    payload
  ) {
    try {
      //used to stop tabs from changing and showing the loader
      yield put(
        loaderActions.SetKey({
          key: "Integration",
        })
      );

      yield put(
        loaderActions.SetLoader({
          loader: true,
        })
      );

      const result = yield call(getMailChimpSelected, payload);

      yield put(
        actions.SetSingleMailchimp({
          singleMailchimpList: result.data.result,
        })
      );

      //set loader equals to false
      yield put(
        loaderActions.SetLoader({
          loader: false,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  });

  yield takeLatest(
    actionTypes.GetPublicSetting,
    function* getPublicSettingsByUuid(payload) {
      try {
        const result = yield call(getPublicSettings, payload);
        return yield put(
          actions.SetPublicState({
            publicSetting: result.data.result,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  );
}
