import {
  getAll,
  deleteCategory,
  create,
  getSelected,
  updateSelected,
} from "../../services/categories";
import { actionTypes, actions } from "../actions/categories";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    yield put(
      actions.setState({
        meta: {
          list: {
            loading: true,
          },
        },
      })
    );
    try {
      const result = yield call(getAll);
      return yield put(
        actions.setState({
          list: result.data,
          meta: null,
        })
      );
    } catch (error) {
      return yield put(
        actions.setState({
          meta: {
            list: {
              loading: false,
            },
          },
        })
      );
    }
  });

  yield takeLatest(
    actionTypes.GetSelectedCategory,
    function* getSelectedCategory(payload) {
      try {
        const result = yield call(getSelected, payload.payload);
        if (result)
          return yield put(
            actions.getSelectedCategory({
              selected: result.data,
            })
          );
      } catch (error) {
        console.log(error);
      }
    }
  );

  yield takeLatest(actionTypes.UpdateCategory, function* updateSelectedCategory(
    payload
  ) {
    try {
      const result = yield call(updateSelected, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.CreateCategory, function* createCategory(
    payload
  ) {
    try {
      const result = yield call(create, payload.payload.values);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.DeleteCategory, function* deleteSelectedCategory(
    payload
  ) {
    try {
      const result = yield call(deleteCategory, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
      return yield put(
        actions.setState({
          meta: null,
        })
      );
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });
}
