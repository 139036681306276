export const actionTypes = {
  GetList: "parents/GET_LIST",
  GetGroupList: "parents/GET_GROUP_LIST",
  ParentToChargeThisMonth: "parents/PARENT_TO_CHARGE_THIS_MONTH",
  SetState: "parents/SET_STATE",
  CreateParent: "parents/CREATE_PARENT",
  EditParent: "parents/EDIT_PARENT",
  DeleteParent: "parents/DELETE_PARENT",
  GetSelectedParent: "parents/GET_SELECTED_PARENT",
  GetParentMedia: "parent/GET_Media",
  UpdateParent: "parents/UPDATE_PARENT",
  ExportCsvFile: "parents/EXPORT_CSV_FILE",
  AddMessage: "parents/ADD_MESSAGE",
  EditMessage: "parents/EDIT_MESSAGE",
  DeleteMessage: "parents/DELETE_MESSAGE",
  SearchParent: "parents/SEARCH_PARENT",
  GetParentsPaymentDetails: "parents/GET_PARENTS_PAYMENT_DETAILS",
  SetParentsPaymentDetails: "parents/SET_PARENTS_PAYMENT_DETAILS",
  SetCalculationLoader: "parents/SET_CALCULATION_LOADER",
  ParentToChargeThisTerm: "parents/PARENT_TO_CHARGE_THIS_TERM",
  GetParentsTermPaymentsDetails: "parents/GET_PARENTS_TERM_PAYMENTS_DETAILS",
  SetParentsTermPaymentsDetails: "parents/SET_PARENTS_TERM_PAYMENTS_DETAILS",
  GetPaymentMethods: "parents/GET_PAYMENT_METHODS",
  SetPaymentMethods: "parents/SET_PAYMENT_METHODS",
  Loading: "parents/LOADING",
  GetCredistReport: "parents/GET_CREDITS_REPORT",
  SetCredistReport: "parents/SET_CREDITS_REPORT",
  SetCreditsLoading: "parents/SET_CREDITS_LOADING",
  GetGmailMessages: "parents/GET_GMAIL_MESSAGES_METHODS",
  SetGmailMessages: "parents/SET_GMAIL_MESSAGES_METHODS",
};

export const actions = {
  loading: (payload) => ({ type: actionTypes.Loading, payload }),
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  SetCredistReport: (payload) => ({
    type: actionTypes.SetCredistReport,
    payload,
  }),
  SetCreditsLoading: (payload) => ({
    type: actionTypes.SetCreditsLoading,
    payload,
  }),
  setPaymentsMethods: (payload) => ({
    type: actionTypes.SetPaymentMethods,
    payload,
  }),

  GetGmailMessages: (payload) => ({
    type: actionTypes.GetGmailMessages,
    payload,
  }),
  SetGmailMessages: (payload) => ({
    type: actionTypes.SetGmailMessages,
    payload,
  }),
  setGmailMessagesMethods: (payload) => ({
    type: actionTypes.SetGmailMessageMethods,
    payload,
  }),
  createParent: (payload) => ({ type: actionTypes.CreateParent, payload }),
  editParent: (payload) => ({ type: actionTypes.EditParent, payload }),
  SetParentsPaymentDetails: (payload) => ({
    type: actionTypes.SetParentsPaymentDetails,
    payload,
  }),
  ParentToChargeThisTerm: (payload) => ({
    type: actionTypes.ParentToChargeThisTerm,
    payload,
  }),
  SetParentsTermPaymentsDetails: (payload) => ({
    type: actionTypes.SetParentsTermPaymentsDetails,
    payload,
  }),
  GetParentsTermPaymentsDetails: (payload) => ({
    type: actionTypes.GetParentsTermPaymentsDetails,
  }),
  setCalculationLoader: (payload) => ({
    type: actionTypes.SetCalculationLoader,
    payload,
  }),
  getSelectedParent: (payload) => ({
    type: actionTypes.GetSelectedParent,
    payload,
  }),
  getParentMedia: (payload) => ({
    type: actionTypes.GetParentMedia,
    payload,
  }),
  ParentToChargeThisMonth: (payload) => ({
    type: actionTypes.ParentToChargeThisMonth,
    payload,
  }),
  getList: (payload) => ({
    type: actionTypes.GetList,
    payload,
  }),
  exportCsvFile: (payload) => ({
    type: actionTypes.ExportCsvFile,
    payload,
  }),
  AddMessage: (payload) => ({
    type: actionTypes.AddMessage,
    payload,
  }),
  EditMessage: (payload) => ({
    type: actionTypes.EditMessage,
    payload,
  }),
  DeleteMessage: (payload) => ({
    type: actionTypes.DeleteMessage,
    payload,
  }),
  SearchParent: (payload) => ({
    type: actionTypes.SearchParent,
    payload,
  }),
};
