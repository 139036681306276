export const actionTypes = {
  GetList: "roles/GET_LIST",
  GetSelected: "roles/GET_SELECTED",
  SetState: "roles/SET_STATE",
  CreateRole: "roles/CREATE_ROLE",
  EditRole: "roles/EDIT_ROLE",
  DeleteRole: "roles/DELETE_ROLE",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  CreateRole: (payload) => ({
    type: actionTypes.CreateRole,
    payload,
  }),
  EditRole: (payload) => ({
    type: actionTypes.EditRole,
    payload,
  }),
  DeleteRole: (payload) => ({
    type: actionTypes.DeleteRole,
    payload,
  }),
  GetSelected: (payload) => ({
    type: actionTypes.GetSelected,
    payload,
  }),
};
