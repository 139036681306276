import { isEmpty } from "lodash";
import baseApi from "../api/baseApi";

// const authToken = () =>
//   JSON.parse(localStorage.getItem("persist:auth"))
//     ? JSON.parse(localStorage.getItem("persist:auth")).authToken
//     : "";

// baseApi.defaults.headers.common["Authorization"] = authToken()
//   ? JSON.parse(authToken()).token
//   : "";

export function getAll() {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.get("/venues/all");
}

export function getPrivateVenuesList() {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.get("/venues/getByOrganization");
}

export function getAllVenuesByUuid({ payload }) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  if (payload.tag) {
    return baseApi.get(`/venues/location/${payload.uuid}`, {
      params: { tag: payload.tag },
    });
  } else {
    return baseApi.get(`/venues/location/${payload.uuid}`);
  }
}

export function getSelected(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  if (payload.id) {
    return baseApi.get(`/venues/get/${payload.id}`);
  }
}

export function exportCSVFile(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get(`/venues/exportcsvfile`);
}

export function addMessage(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.post("/venues/addmessage", payload);
}

export function editMessage(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.post("/venues/editmessage", payload);
}

export function deleteMessage(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.post("/venues/deletemessage", payload);
}

export function create(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  let params = new FormData();

  params.append("address", payload.address);
  params.append("address1", payload.address1);
  params.append("city", payload.city);
  params.append("name", payload.name);
  params.append("contacts", JSON.stringify(payload.contacts));
  params.append("postcode", payload.postcode);
  params.append("state", payload.state);
  params.append("suburb", payload.suburb);
  params.append("status", payload.status);
  params.append("latitude", payload.latitude);
  params.append("longitude", payload.longitude);
  params.append("bac", payload.bac);
  params.append("region", payload.region);

  if (payload.venueImage) {
    params.append("venueImage", payload.venueImage);
  } else {
    params.append("venueImage", "");
  }

  if (payload.attachments && payload.attachments.length > 0) {
    payload.attachments.map((item) => {
      params.append("attachments", item);
    });
  } else {
    params.append("attachments", []);
  }

  return baseApi.post(`/venues/create`, params, {
    headers: {
      "Content-Type": "multipart/form-data, charset=utf-8",
    },
  });
}

export function deleteVenue(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.delete(`/venues/delete/${payload.id}`);
}

export function updateSelected(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  let params = new FormData();

  if (payload.address !== undefined && payload.address !== "") {
    params.append("address", payload.address);
  }
  if (payload.address1 !== undefined && payload.address1 !== "") {
    params.append("address1", payload.address1);
  }
  if (payload.city !== undefined && payload.city !== "") {
    params.append("city", payload.city);
  }
  if (payload.name !== undefined && payload.name !== "") {
    params.append("name", payload.name);
  }
  if (
    payload.contacts !== undefined &&
    payload.contacts !== "" &&
    !isEmpty(payload.contacts)
  ) {
    params.append("contacts", JSON.stringify(payload.contacts));
  }
  if (payload.postcode !== undefined && payload.postcode !== "") {
    params.append("postcode", payload.postcode);
  }
  if (payload.state !== undefined && payload.state !== "") {
    params.append("state", payload.state);
  }
  if (payload.suburb !== undefined && payload.suburb !== "") {
    params.append("suburb", payload.suburb);
  }
  if (payload.status !== undefined && payload.status !== "") {
    params.append("status", payload.status);
  }
  if (payload.latitude !== undefined && payload.latitude !== "") {
    params.append("latitude", payload.latitude);
  }
  if (payload.longitude !== undefined && payload.longitude !== "") {
    params.append("longitude", payload.longitude);
  }
  if (payload.bac !== undefined && payload.bac !== "") {
    params.append("bac", payload.bac);
  }

  if (payload.venueImage) {
    params.append("venueImage", payload.venueImage);
  } else {
    params.append("venueImage", "");
  }

  if (payload.attachments && payload.attachments.length > 0) {
    payload.attachments.map((item) => {
      params.append("attachments", item);
    });
  } else {
    params.append("attachments", []);
  }

  if (payload.region) {
    params.append("region", payload.region);
  }

  return baseApi.put(`/venues/update/${payload.id}`, params, {
    headers: {
      "Content-Type": "multipart/form-data, charset=utf-8",
    },
  });
}

export function sendCSVFile(payload) {
  let params = new FormData();
  params.append("csvFile", payload.csvFile);

  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.post(`/venues/sendcsvfile`, params);
}
