import baseApi from '../api/baseApi'

export function getAll() {
  const authToken = () =>
    JSON.parse(localStorage.getItem('persist:auth'))
      ? JSON.parse(localStorage.getItem('persist:auth')).authToken
      : ''

  baseApi.defaults.headers.common['Authorization'] = authToken()
    ? JSON.parse(authToken()).token
    : ''
  return baseApi.get('/age/all')
}

export function addAge(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem('persist:auth'))
      ? JSON.parse(localStorage.getItem('persist:auth')).authToken
      : ''

  baseApi.defaults.headers.common['Authorization'] = authToken()
    ? JSON.parse(authToken()).token
    : ''
  return baseApi.post('/age/add', payload)
}

export function deleteAge(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem('persist:auth'))
      ? JSON.parse(localStorage.getItem('persist:auth')).authToken
      : ''

  baseApi.defaults.headers.common['Authorization'] = authToken()
    ? JSON.parse(authToken()).token
    : ''

  return baseApi.delete(`age/remove/${payload.id}`, payload)
}

export function getSelected(payload) {
  if (payload.id) {
    const authToken = () =>
      JSON.parse(localStorage.getItem('persist:auth'))
        ? JSON.parse(localStorage.getItem('persist:auth')).authToken
        : ''

    baseApi.defaults.headers.common['Authorization'] = authToken()
      ? JSON.parse(authToken()).token
      : ''
    return baseApi.get(`/age/get/${payload.id}`)
  }
}

export function updateSelected(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem('persist:auth'))
      ? JSON.parse(localStorage.getItem('persist:auth')).authToken
      : ''

  baseApi.defaults.headers.common['Authorization'] = authToken()
    ? JSON.parse(authToken()).token
    : ''
  return baseApi.put(`/age/update/${payload.id}`, payload)
}
