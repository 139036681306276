import {
  getAll,
  create,
  update,
  deleteRegion,
  getEnrolmentsByRegion,
  getReport,
} from "../../services/regions";
import { actionTypes, actions } from "../actions/regions";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    try {
      const result = yield call(getAll);
      return yield put(
        actions.setState({
          list: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(
    actionTypes.GetEnrolmentsByRegion,
    function* getAllEnrolmentsReportsByRegions() {
      try {
        const result = yield call(getEnrolmentsByRegion);
        return yield put(
          actions.SetEnrolmentsByRegion({
            enrolmentsByRegions: result.data,
          })
        );
      } catch (error) {
        console.log(error);
        console.log(error.response);
        if (error?.response?.data?.error) {
          return toast.error(error.response.data.error);
        }
      }
    }
  );

  yield takeLatest(actionTypes.GetReport, function* getServiceReport(payload) {
    yield put(
      actions.loading({
        loading: true,
      })
    );
    try {
      const result = yield call(getReport, payload.payload);
      yield put(
        actions.loading({
          loading: false,
        })
      );

      return yield put(
        actions.SetReport({
          report: result.data.result,
        })
      );
    } catch (error) {
      yield put(
        actions.loading({
          loading: false,
        })
      );
    }
  });

  yield takeLatest(actionTypes.CreateRegion, function* createRegion({
    payload,
  }) {
    try {
      const result = yield call(create, payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.EditRegion, function* editRegion({ payload }) {
    try {
      const result = yield call(update, payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.DeleteRegion, function* deleteSelectedRegion({
    payload,
  }) {
    try {
      const result = yield call(deleteRegion, payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });
}
