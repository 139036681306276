export const actionTypes = {
  GetList: "coupon/GET_LIST",
  GetGroupList: "coupon/GET_GROUP_LIST",
  SetState: "coupon/SET_STATE",
  CreateCoupon: "coupon/CREATE_COUPON",
  EditCoupon: "coupon/EDIT_COUPON",
  DeleteCoupon: "coupon/DELETE_COUPON",
  GetSelectedCoupon: "coupon/GET_SELECTED_COUPON",
  SetSelectedCoupon: "coupon/SET_SELECTED_COUPON",
  UpdateCoupon: "coupon/UPDATE_COUPON",
  ValidateCoupon: "coupon/VALIDATE_COUPON",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  ValidateCoupon: (payload) => ({ type: actionTypes.ValidateCoupon, payload }),
  SetCouponMessage: (payload) => ({
    type: actionTypes.SetCouponMessage,
    payload,
  }),
  createCoupon: (payload) => ({
    type: actionTypes.CreateCoupon,
    payload,
  }),
  editCoupon: (payload) => ({
    type: actionTypes.EditCoupon,
    payload,
  }),
  getSelectedCoupon: (payload) => ({
    type: actionTypes.GetSelectedCoupon,
    payload,
  }),
  setSelectedCoupon: (payload) => ({
    type: actionTypes.SetSelectedCoupon,
    payload,
  }),
  deleteCoupon: (payload) => ({
    type: actionTypes.DeleteCoupon,
    payload,
  }),
};
