import {
  getAll,
  deleteAlert,
  getSelected,
  changeStatus,
} from "../../services/alert";
import { actionTypes, actions } from "../actions/alert";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    try {
      const result = yield call(getAll);
      return yield put(
        actions.SetState({
          list: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(
    actionTypes.ChangeStatus,
    function* updateSelectedChangeStatus(payload) {
      try {
        const result = yield call(changeStatus, payload.payload);
        yield put({
          type: actionTypes.GetList,
        });
      } catch (error) {
        toast.error(error.response.data.error);
      }
    }
  );

  yield takeLatest(actionTypes.GetSelectedAlert, function* getSelectedAlert(
    payload
  ) {
    try {
      const result = yield call(getSelected, payload.payload);
      return yield put(
        actions.SetSelectedAlert({
          selected: result.data,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  });

  yield takeLatest(actionTypes.DeleteAlert, function* deleteSelectedAlert(
    payload
  ) {
    try {
      const result = yield call(deleteAlert, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });
}
