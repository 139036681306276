import { actions, actionTypes } from "../actions/parents";

const initialParentsState = {
  list: [],
  selected: {},
  parentsToChargeThisMonth: [],
  count: 0,
  totalRevenueOfMonth: 0,
  totalEnrolmentsThisMonth: 0,
  loading: true,
  totalRevenueOfTerm: 0,
  countTerm: 0,
  parentsToChargeThisTerm: [],
  totalEnrolmentsThisTerm: 0,
  parentListCount: 0,
  paymentMethods: {},
  listLoading: false,
  creditsReport: [],
  creditsReportLoading: false,
};

const parentReducer = (state = initialParentsState, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const {
        list,
        parentsToChargeThisMonth,
        parentsToChargeThisTerm,
        parentListCount,
      } = action.payload;
      return {
        ...state,
        list,
        parentsToChargeThisMonth,
        parentsToChargeThisTerm,
        parentListCount,
      };
    }

    case actionTypes.SetParentsPaymentDetails: {
      const {
        count,
        totalRevenueOfMonth,
        totalEnrolmentsThisMonth,
      } = action.payload;
      return {
        ...state,
        count,
        totalEnrolmentsThisMonth,
        totalRevenueOfMonth,
      };
    }

    case actionTypes.SetParentsTermPaymentsDetails: {
      const {
        countTerm,
        totalRevenueOfTerm,
        totalEnrolmentsThisTerm,
      } = action.payload;
      return {
        ...state,
        countTerm,
        totalEnrolmentsThisTerm,
        totalRevenueOfTerm,
      };
    }

    case actionTypes.GetSelectedParent: {
      return {
        ...state,
        selected: { ...action.payload.selected },
      };
    }

    case actionTypes.SetPaymentMethods: {
      return {
        ...state,
        paymentMethods: { ...action.payload.paymentMethods },
      };
    }

    case actionTypes.SetCalculationLoader: {
      const { loading } = action.payload;
      return {
        ...state,
        loading,
      };
    }

    case actionTypes.Loading: {
      const { loading } = action.payload;
      return {
        ...state,
        listLoading: loading,
      };
    }

    case actionTypes.SetCreditsLoading: {
      const { loading } = action.payload;
      return {
        ...state,
        creditsReportLoading: loading,
      };
    }

    case actionTypes.SetCredistReport: {
      const { creditsReport } = action.payload;
      return {
        ...state,
        creditsReport,
      };
    }

    default:
      return state;
  }
};

export default parentReducer;
