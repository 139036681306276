export const actionTypes = {
  GetList: "locations/GET_LIST",
  GetLocationsPrivate: "locations/GET_LOCATIONS_PRIVATE",
  GetGroupList: "locations/GET_GROUP_LIST",
  GetListByUuid: "locations/GET_LIST_BY_UUID",
  GetListForTrialsByUuid: "locations/GET_LIST_FOR_TRIALS_BY_UUID",
  SetState: "locations/SET_STATE",
  SetForTrialsState: "locations/SET_STATE_FOR_TRIALS",
  CreateLocations: "locations/CREATE_LOCATION",
  EditLocations: "locations/EDIT_LOCATION",
  DeleteLocations: "locations/DELETE_LOCATION",
  GetSelectedLocations: "locations/GET_SELECTED_LOCATION",
  SetSelectedLocations: "locations/SET_SELECTED_LOCATIONS",
  UpdateLocations: "locations/UPDATE_LOCATION",
  SearchNearLocation: "locations/SEARCH_NEAR_LOCATION",
  SetSearchedResults: "locations/SET_SEARCHED_RESULTS",
  SetSearchedForTrialResults: "locations/SET_SEARCHED_FOR_TRIALS",
  SetMapZoom: "locations/SET_MAP_ZOOM",
  SetMapSelectedLocation: "locations/SET_MAP_SELECTED_LOCATION",
  SetSelectedLocation: "locations/SET_SELECTED_LOCATION",
  SendCSVFile: "locations/SEND_CSV_FILE",
  ExportCSVFile: "locations/EXPORT_CSV_FILE",
  AddMessage: "locations/ADD_MESSAGE",
  EditMessage: "locations/EDIT_MESSAGE",
  DeleteMessage: "locations/DELETE_MESSAGE",
  GetAllLocationsAndVenue: "locations/GET_ALL_VENUES_AND_LOCATIONS",
  GetLocationsByOrgId: "locations/GET_LOCATIONS_BY_ORG_ID",
  GetLocationsLoading: "locations/GET_LOCATIONS_LOADING",
  GetEnrolmentsByLocation: "locations/GET_ENROLMENTS_BY_LOCATION",
  SetEnrolmentsByLocation: "locations/SET_ENROLMENTS_BY_LOCATION",
  SetLoading: "locations/SET_LOADING",
  SetReport: "locations/SET_REPORT",
  GetReport: "locations/GET_REPORT",
  GetLocationsForTrialsLoading: "locations/GET_LOCATIONS_FOR_TRIALS_LOADING",
};

export const actions = {
  GetLocationsForTrialsLoading: (payload) => ({
    type: actionTypes.GetLocationsForTrialsLoading,
    payload,
  }),
  SetReport: (payload) => ({
    type: actionTypes.SetReport,
    payload,
  }),
  loading: (payload) => ({
    type: actionTypes.SetLoading,
    payload,
  }),
  SetEnrolmentsByLocation: (payload) => ({
    type: actionTypes.SetEnrolmentsByLocation,
    payload,
  }),
  SetMapSelectedLocation: (payload) => ({
    type: actionTypes.SetMapSelectedLocation,
    payload,
  }),
  GetLocationsLoading: (payload) => ({
    type: actionTypes.GetLocationsLoading,
    payload,
  }),
  SetSelectedLocation: (payload) => ({
    type: actionTypes.SetSelectedLocation,
    payload,
  }),
  SetMapZoom: (payload) => ({
    type: actionTypes.SetMapZoom,
    payload,
  }),
  setSearchedResults: (payload) => ({
    type: actionTypes.SetSearchedResults,
    payload,
  }), 
  setSearchedForTrialsResults: (payload) => ({
    type: actionTypes.SetSearchedForTrialResults,
    payload,
  }),
  setForTrialsState: (payload) => ({ type: actionTypes.SetForTrialsState, payload }),
  setState: (payload) => ({ type: actionTypes.SetState, payload }),

  GetListByUuid: (payload) => ({ type: actionTypes.GetListByUuid, payload }),
  GetListForTrialsByUuid: (payload) => ({ type: actionTypes.GetListForTrialsByUuid, payload }),
  
  createLocation: (payload) => ({
    type: actionTypes.CreateLocations,
    payload,
  }),
  editLocation: (payload) => ({
    type: actionTypes.EditLocations,
    payload,
  }),
  getSelectedLocation: (payload) => ({
    type: actionTypes.GetSelectedLocations,
    payload,
  }),
  setSelectedLocations: (payload) => ({
    type: actionTypes.SetSelectedLocations,
    payload,
  }),
  setSelectedLocation: (payload) => ({
    type: actionTypes.SetSelectedLocation,
    payload,
  }),
  SearchNearLocation: (payload) => ({
    type: actionTypes.SearchNearLocation,
    payload,
  }),
  SendCSVFile: (payload) => ({
    type: actionTypes.SendCSVFile,
    payload,
  }),
  ExportCSVFile: (payload) => ({
    type: actionTypes.ExportCSVFile,
    payload,
  }),
  AddMessage: (payload) => ({
    type: actionTypes.AddMessage,
    payload,
  }),
  EditMessage: (payload) => ({
    type: actionTypes.EditMessage,
    payload,
  }),
  DeleteMessage: (payload) => ({
    type: actionTypes.DeleteMessage,
    payload,
  }),
};
