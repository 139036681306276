import {
  getAll,
  deletePublicHoliday,
  create,
  getSelected,
  updateSelected,
  sendCSVFile,
  getEnrolmentsOfHoliday,
} from "../../services/publicholidays";
import { actionTypes, actions } from "../actions/publicholidays";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    // yield put(
    //   actions.setState({
    //     meta: {
    //       list: {
    //         loading: true,
    //       },
    //     },
    //   })
    // );
    try {
      const result = yield call(getAll);
      return yield put(
        actions.setState({
          list: result.data,
          // meta: null,
        })
      );
    } catch (error) {
      console.log(error);
      // return yield put(
      //   actions.setState({
      //     meta: {
      //       list: {
      //         loading: false,
      //       },
      //     },
      //   })
      // );
    }
  });

  yield takeLatest(
    actionTypes.GetEnrolmentsByHoliday,
    function* getEnrolmentofHolidayData({ payload }) {
      try {
        const result = yield call(getEnrolmentsOfHoliday, payload);
        return yield put(
          actions.SetEnrolmentsByHoliday({
            enrolmentOnHoliday: result.data,
          })
        );
      } catch (error) {}
    }
  );

  yield takeLatest(
    actionTypes.UpdatePublicHoliday,
    function* updateSelectedPublicHoliday(payload) {
      try {
        const result = yield call(updateSelected, payload.payload);
        yield put({
          type: actionTypes.GetList,
        });
        toast.success(result.data.message);
      } catch (error) {
        toast.error(error.response.data.error);
      }
    }
  );

  yield takeLatest(
    actionTypes.GetSelectedPublicHoliday,
    function* getSelectedPublicHoliday(payload) {
      try {
        const result = yield call(getSelected, payload.payload);
        return yield put(
          actions.getSelectedPublicHoliday({
            selected: result.data,
          })
        );
      } catch (error) {
        console.log(error.response);
      }
    }
  );

  yield takeLatest(actionTypes.SendCSVFile, function* sendCSVFileInput(
    payload
  ) {
    try {
      const result = yield call(sendCSVFile, payload.payload);
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(
    actionTypes.CreatePublicHoliday,
    function* createPublicHoliday(payload) {
      try {
        const result = yield call(create, payload.payload.values);
        yield put({
          type: actionTypes.GetList,
        });
        toast.success(result.data.message);
      } catch (error) {
        toast.error(error.response.data.error);
      }
    }
  );

  yield takeLatest(
    actionTypes.DeletePublicHoliday,
    function* deleteSelectedPublicHoliday(payload) {
      try {
        const result = yield call(deletePublicHoliday, payload.payload);
        yield put({
          type: actionTypes.GetList,
        });
        toast.success(result.data.message);
        return yield put(
          actions.setState({
            meta: null,
          })
        );
      } catch (error) {
        toast.error(error.response.data.error);
      }
    }
  );
}
