import baseApi from "../api/baseApi";

export const getAll = () => {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get(`/region/all`);
};

export const getOne = (payload) => {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get(`/region/get/${payload._id}`);
};

export const create = (payload) => {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.post(`/region/create`, payload);
};

export const update = (payload) => {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.put(`/region/update/${payload.id}`, payload);
};

export const deleteRegion = (payload) => {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.delete(`/region/delete/${payload.id}`);
};

export function getEnrolmentsByRegion() {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get("/region/regionReport");
}

export function getReport(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  if (!payload || (!payload.from && !payload.to && !payload.timeframe)) {
    return baseApi.get(`/region/revenueReport/`);
  } else {
    return baseApi.get(
      `/region/revenueReport/${payload.from ? payload.from : "none"}/${
        payload.to ? payload.to : "none"
      }/${payload.timeframe ? payload.timeframe : "none"}`
    );
  }
}
