import { actionTypes } from "../actions/transaction";

const initialTrialState = {
  list: [],
  loading: false,
  reportLoading: false,
  refundReport: [],
};

const trialsTypesReducer = (state = initialTrialState, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list, refundReport } = action.payload;
      return {
        ...state,
        list,
        refundReport,
      };
    }

    case actionTypes.SetLoader: {
      const { loading } = action.payload;
      return {
        ...state,
        loading,
      };
    }

    case actionTypes.SetReportLoader: {
      const { loading } = action.payload;
      return {
        ...state,
        reportLoading: loading,
      };
    }

    default:
      return state;
  }
};

export default trialsTypesReducer;
