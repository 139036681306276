import {
  getAll,
  deleteTutor,
  create,
  getSelected,
  updateSelected,
  getSchedule,
  addMessage,
  editMessage,
  deleteMessage,
  getCoachesByUuid,
  getLessonsByTutors,
} from "../../services/tutor";
import { actionTypes, actions } from "../actions/tutors";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    try {
      const result = yield call(getAll);
      return yield put(
        actions.setState({
          list: result.data,
        })
      );
    } catch (error) {
      console.log("error is ", error);
    }
  });

  yield takeLatest(actionTypes.UpdateTutorLessons, function* UpdateTutorLesson({
    payload,
  }) {
    try {
      return yield put(
        actions.SetTutorLessonUpdate({
          lesson: payload?.lesson,
        })
      );
    } catch (error) {
      console.log("error is ", error);
    }
  });

  yield takeLatest(
    actionTypes.GetLessonsByTutorIds,
    function* getLessonsByTutorIds({ payload }) {
      yield put(
        actions.loading({
          loading: true,
        })
      );
      try {
        const result = yield call(getLessonsByTutors, payload);
        yield put(
          actions.loading({
            loading: false,
          })
        );
        return yield put(
          actions.SetTutorLessons({
            tutorLessons: result.data,
          })
        );
      } catch (error) {
        yield put(
          actions.loading({
            loading: false,
          })
        );
        console.log("error is ", error);
      }
    }
  );

  yield takeLatest(actionTypes.GetListByUuid, function* getListByUuid({
    payload,
  }) {
    try {
      const result = yield call(getCoachesByUuid, payload);
      return yield put(
        actions.setState({
          coachesByUuid: result.data,
        })
      );
    } catch (error) {
      console.log("error is ", error);
    }
  });

  yield takeLatest(actionTypes.UpdateTutor, function* updateSelectedTutor(
    payload
  ) {
    try {
      const result = yield call(updateSelected, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
      return result;
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.GetSelectedTutor, function* getSelectedTutor(
    payload
  ) {
    try {
      const result = yield call(getSelected, payload.payload);
      return yield put(
        actions.getSelectedTutor({
          selected: result.data,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  });

  yield takeLatest(actionTypes.AddMessage, function* addNewMessage(payload) {
    try {
      const result = yield call(addMessage, payload.payload);

      toast.success(result.data.message);

      return yield put(
        actions.getSelectedTutor({
          selected: result.data.results,
        })
      );
    } catch (error) {
      return toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.EditMessage, function* editNewMessage(payload) {
    try {
      const result = yield call(editMessage, payload.payload);

      toast.success(result.data.message);

      return yield put(
        actions.getSelectedTutor({
          selected: result.data.results,
        })
      );
    } catch (error) {
      return toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.DeleteMessage, function* deleteNewMessage(
    payload
  ) {
    try {
      const result = yield call(deleteMessage, payload.payload);

      toast.success(result.data.message);

      return yield put(
        actions.getSelectedTutor({
          selected: result.data.results,
        })
      );
    } catch (error) {
      return toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.GetSchedule, function* getSchedueleList(
    payload
  ) {
    try {
      const result = yield call(getSchedule, payload.payload);
      return yield put(
        actions.setSchedule({
          scheduleList: result.data,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  });

  yield takeLatest(actionTypes.CreateTutor, function* createTutor(payload) {
    try {
      const result = yield call(create, payload.payload.values);
      if (result.data) {
        yield put({
          type: actionTypes.GetList,
        });
      }
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.DeleteTutor, function* deleteSelectedVenue(
    payload
  ) {
    try {
      const result = yield call(deleteTutor, payload.payload);
      toast.success(result.data.message);
      yield put({
        type: actionTypes.GetList,
      });
      return yield put(
        actions.setState({
          meta: null,
        })
      );
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });
}
