import { actionTypes } from "../actions/contact";

const initialContactReducer = {
  list: [],
  loading: false,
};

const contactReducer = (state = initialContactReducer, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list } = action.payload;

      return {
        ...state,
        list,
      };
    }

    case actionTypes.SetLoading: {
      const { loading } = action.payload;
      return {
        ...state,
        loading: loading,
      };
    }

    default:
      return state;
  }
};

export default contactReducer;
