import { actionTypes } from "../actions/emailTemplate";

const initialEmailTemplateReducer = {
  list: [],
  selected: {},
  meta: {},
};

const emailTemplateReducer = (state = initialEmailTemplateReducer, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list } = action.payload;
      return {
        ...state,
        list,
      };
    }

    case actionTypes.SetSelectedEmailTemplate: {
      return {
        ...state,
        selected: { ...action.payload.selected },
      };
    }

    default:
      return state;
  }
};

export default emailTemplateReducer;
