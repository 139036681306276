export const actionTypes = {
  GetCurrentOrganisation: "organisations/GET_CURRENT_ORGANISATION",
  GetOrganisation: "organisations/GET_ORGANISATION",
  SetOrganisation: "organisations/SET_ORGANISATION",
  SetState: "organisations/SET_STATE",
  UpdateOrganisation: "organisations/UPDATE_ORGANISATION",
  GetUuid: "organisations/GET_UUID",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  updateOrganisation: (payload) => ({
    type: actionTypes.UpdateOrganisation,
    payload,
  }),
  getCurrentOrganisation: (payload) => ({
    type: actionTypes.GetCurrentOrganisation,
    payload,
  }),
  getOrganisation: (payload) => ({
    type: actionTypes.GetOrganisation,
    payload,
  }),

  setOrganisation: (payload) => ({
    type: actionTypes.SetOrganisation,
    payload,
  }),
};
