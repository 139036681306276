import { getAll, updateSelected, getSelected } from "../../services/cronJobs";
import { actionTypes, actions } from "../actions/cronJobs";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    try {
      const result = yield call(getAll);
      return yield put(
        actions.setState({
          list: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.GetSelectedCron, function* getSelectedCron({
    payload,
  }) {
    try {
      const result = yield call(getSelected, payload);
      return yield put(
        actions.setState({
          selected: result.data,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  });

  yield takeLatest(actionTypes.UpdateCrons, function* updateSelectedCron({
    payload,
  }) {
    try {
      const result = yield call(updateSelected, payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });
}
