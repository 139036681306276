export const actionTypes = {
  GetList: "price/GET_LIST",
  SetState: "price/SET_STATE",
  SetLoading: "price/SET_LOADING",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  SetLoading: (payload) => ({ type: actionTypes.SetLoading, payload }),
};
