import { actionTypes } from "../actions/students";

const initialStudentState = {
  list: [],
  selected: {},
  studentsByParents: [],
  count: 0,
  birthdayList: [],
  // meta: {},
  loading: false,
};

const studentReducer = (state = initialStudentState, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list, studentsByParents, count } = action.payload;
      return {
        ...state,
        list,
        studentsByParents,
        count,
      };
    }

    case actionTypes.SetBirthdayPartyList: {
      const { birthdayList } = action.payload;
      return {
        ...state,
        birthdayList,
      };
    }

    case actionTypes.SetBirthdayLoader: {
      const { loading } = action.payload;
      return {
        ...state,
        loading,
      };
    }

    case actionTypes.GetSelectedStudent: {
      return {
        ...state,
        selected: { ...action.payload.selected },
      };
    }

    default:
      return state;
  }
};

export default studentReducer;
