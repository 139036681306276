import {
  getAll,
  deleteLevel,
  create,
  getSelected,
  updateSelected,
} from "../../services/levels";
import { actionTypes, actions } from "../actions/levels";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    yield put(
      actions.setState({
        meta: {
          list: {
            loading: true,
          },
        },
      })
    );
    try {
      const result = yield call(getAll);
      return yield put(
        actions.setState({
          list: result.data,
          meta: null,
        })
      );
    } catch (error) {
      return yield put(
        actions.setState({
          meta: {
            list: {
              loading: false,
            },
          },
        })
      );
    }
  });

  yield takeLatest(actionTypes.GetSelectedLevel, function* getSelectedLevel(
    payload
  ) {
    try {
      const result = yield call(getSelected, payload.payload);
      if (result)
        return yield put(
          actions.getSelectedLevel({
            selected: result.data,
          })
        );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.UpdateLevel, function* updateSelectedLevel(
    payload
  ) {
    try {
      const result = yield call(updateSelected, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.CreateLevel, function* createLevel(payload) {
    try {
      const result = yield call(create, payload.payload.values);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.DeleteLevel, function* deleteSelectedVenue(
    payload
  ) {
    try {
      const result = yield call(deleteLevel, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
      return yield put(
        actions.setState({
          meta: null,
        })
      );
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });
}
