import baseApi from "../api/baseApi";

export function getAll() {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get("/organisations/all");
}

export function getCurrentOrganisation() {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get(`/organisations/get-current`);
}

export function GetOrganisation(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get(`/organisations/getorganisation/${payload.id}`);
}

export function update(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  let params = new FormData();
  // for (var i in payload) {
  //   params.set(i, payload[i]);
  // }

  params.append("abn", payload.values.abn);
  params.append("address", payload.values.address);
  params.append("address1", payload.values.address1);
  params.append("city", payload.values.city);
  params.append("email", payload.values.email);
  params.append("id", payload.values.id);
  params.append("logo", payload.values.logo);
  params.append("name", payload.values.name);
  params.append("phone", payload.values.phone);
  params.append("postcode", payload.values.postcode);
  params.append("state", payload.values.state);
  params.append("suburb", payload.values.suburb);
  params.append("country", payload.values.country);

  return baseApi.post(`/organisations/update/${payload.values.id}`, params);
}

export function getUuid() {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get(`/organisations/get/uuid`);
}
