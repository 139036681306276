export const actionTypes = {
  GetSetting: "settings/GET_SETTING",
  GetSettingForPublic: "settings/GET_SETTING_FOR_PUBLIC",
  SetSettingForPublic: "settings/SET_SETTING_FOR_PUBLIC",
  GetMailChimpSetting: "settings/GET_MAILCHIMP_LIST",
  GetMailChimpList: "settings/GET_SINGLE_MAILCHIMP_LIST",
  SetState: "settings/SET_STATE",
  SetGingerState: "settings/SET_GINGER_STATE",
  SetQuestState: "settings/SET_QUEST_STATE",
  SetSingleMailchimp: "settings/SET_SINGLE_MAILCHIMP",
  SetMailchimp: "settings/SET_MAILCHIMP",
  CreateSetting: "settings/CREATE_SETTING",
  GetPublicSetting: "settings/GET_PUBLIC_SETTING",
  GetSimpleSetting: "settings/GET_SIMPLE_SETTING",
  GetSimplePublicSetting: "settings/GET_SIMPLE_PUBLIC_SETTING",
  SetLogoAndDetails: "settings/SET_LOGO_AND_DETAILS",
  GetLogoAndDetails: "settings/GET_LOGO_AND_DETAILS",
  SetPublicState: "settings/SET_PUBLIC_SETTING",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  SetPublicState: (payload) => ({ type: actionTypes.SetPublicState, payload }),
  // setMailChimp: (payload) => ({ type: actionTypes.setMailChimp, payload }),

  SetMailchimp: (payload) => ({
    type: actionTypes.SetMailchimp,
    payload,
  }),

  SetSettingForPublic: (payload) => ({
    type: actionTypes.SetSettingForPublic,
    payload,
  }),
  
  SetGingerState: (payload) => ({
    type: actionTypes.SetGingerState,
    payload,
  }),

  SetLogoAndDetails: (payload) => ({
    type: actionTypes.SetLogoAndDetails,
    payload,
  }),

  SetQuestState: (payload) => ({
    type: actionTypes.SetQuestState,
    payload,
  }),

  SetSingleMailchimp: (payload) => ({
    type: actionTypes.SetSingleMailchimp,
    payload,
  }),
  createSetting: (payload) => ({ type: actionTypes.CreateSetting, payload }),
  getSimpleSetting: (payload) => ({
    type: actionTypes.GetSimpleSetting,
    payload,
  }),
  getPublicSetting: (payload) => ({
    type: actionTypes.GetPublicSetting,
    payload,
  }),
  getSettingForPublic: (payload) => ({
    type: actionTypes.GetSettingForPublic,
    payload,
  }),
  GetSimplePublicSetting: (payload) => ({
    type: actionTypes.GetSimplePublicSetting,
    payload,
  }),
};
