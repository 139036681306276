import { actionTypes } from "../actions/term";

const initialTermsState = {
  current: {},
};

const termsReducer = (state = initialTermsState, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list, selected } = action.payload;
      return {
        ...state,
        list,
        selected,
      };
    }

    case actionTypes.SetCurrent: {
      const { current } = action.payload;
      console.log({ current });

      return {
        ...state,
        current,
      };
    }

    default:
      return state;
  }
};

export default termsReducer;
