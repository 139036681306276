import { actionTypes } from "../actions/classes";

const initialLessonState = {
  list: [],
  weekly: [],
  childcare: [],
  classesByLocation: [],
  groups: [],
  meta: {},
  lessonsByClass: [],
  selected: {},
  classesByService: [],
  classesByServiceEnrolment: [],
  lessonByClassesEnrolment: [],
  classesByServiceForCommunications: [],
  session: [],
  count: 0,
  loading: false,
  getByServiceLoadingForCommunications: false,
  classesWithEnrolPercentage: [],
  classWithEnrolLoading: false,
  loadingExport: false,
  csvData: [],
  getByServiceLoading: false,
  getByServiceEnrolmentLoading: false,
  getByClassesEnrolmentLoading: false,
  classesByLocationAndOrgId: [],
  classesByLocationLoading: false,
  AllClassesByActiveEnrolmentsLoading: false,
  AllClassesByActiveEnrolments: [],
  loadingForSelectedClass: false,
};

const classesReducer = (state = initialLessonState, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const {
        list,
        classesByLocation,
        meta,
      //  selected,
        // classesByService,
        count,
      } = action.payload;
      return {
        ...state,
        list,
       // selected,
        classesByLocation,
        // classesByService,
        meta: { ...meta },
        count,
      };
    }


    case actionTypes.SetClassesByOrgIdAndLocation: {
      const { classesByLocationAndOrgId } = action.payload;
      return {
        ...state,
        classesByLocationAndOrgId,
      };
    }

    case actionTypes.SetLoading: {
      const { loading } = action.payload;
      return {
        ...state,
        loadingExport: loading,
      };
    }

    case actionTypes.SetCsvData: {
      const { csvData } = action.payload;
      return {
        ...state,
        csvData: csvData,
      };
    }

    case actionTypes.SetLessonsByClass: {
      const { lessonsByClass } = action.payload;
      return {
        ...state,
        lessonsByClass,
      };
    }

    case actionTypes.setClassesPrivateState: {
      const { classesByLocation } = action.payload;
      return {
        ...state,
        classesByLocation,
      };
    }

    case actionTypes.SetSessionRevenueClass: {
      const { session } = action.payload;
      return {
        ...state,
        session,
      };
    }

    case actionTypes.Loading: {
      const { loading } = action.payload;
      return {
        ...state,
        loading,
      };
    }

    case actionTypes.SetClassesWithEnrolPercentage: {
      const { classesWithEnrolPercentage } = action.payload;
      return {
        ...state,
        classesWithEnrolPercentage,
      };
    }

    case actionTypes.SetClassWithEnrolLoading: {
      const { classWithEnrolLoading } = action.payload;
      return {
        ...state,
        classWithEnrolLoading,
      };
    }

    case actionTypes.GetByServiceLoading: {
      const { loading } = action.payload;
      return {
        ...state,
        getByServiceLoading: loading,
      };
    }

    case actionTypes.SetClassesByService: {
      const { classesByService } = action.payload;
      return {
        ...state,
        classesByService: classesByService,
      };
    }

    case actionTypes.GetByServiceEnrolmentLoading: {
      const { loading } = action.payload;
      return {
        ...state,
        getByServiceEnrolmentLoading: loading,
      };
    }

    case actionTypes.SetClassesByServiceEnrolment: {
      const { classesByServiceEnrolment } = action.payload;
      return {
        ...state,
        classesByServiceEnrolment: classesByServiceEnrolment,
      };
    }

    case actionTypes.GetByLessonsEnrolmentLoading: {
      const { loading } = action.payload;
      return {
        ...state,
        getByClassesEnrolmentLoading: loading,
      };
    }

    case actionTypes.SetLessonsByClassesEnrolment: {
      const { lessonByClassesEnrolment } = action.payload;
      return {
        ...state,
        lessonByClassesEnrolment: lessonByClassesEnrolment,
      };
    }

    case actionTypes.SetClassesByServiceForCommunications: {
      const { classesByServiceForCommunications } = action.payload;
      return {
        ...state,
        classesByServiceForCommunications: classesByServiceForCommunications,
      };
    }

    case actionTypes.GetByServiceLoadingForCommunications: {
      const { loading } = action.payload;
      return {
        ...state,
        getByServiceLoadingForCommunications: loading,
      };
    }

    case actionTypes.GetLoadingSelected: {
      const { loading } = action.payload;
      return {
        ...state,
        loadingForSelectedClass: loading,
      };
    }
    
    case actionTypes.GetClassesByLocationLoading: {
      const { loading } = action.payload;
      return {
        ...state,
        classesByLocationLoading: loading,
      };
    }

    case actionTypes.SetAllClassesByActiveEnrolments: {
      const { AllClassesByActiveEnrolments } = action.payload;
      return {
        ...state,
        AllClassesByActiveEnrolments,
      };
    }

    case actionTypes.SetAllClassesByActiveEnrolmentsLoading: {
      const { AllClassesByActiveEnrolmentsLoading } = action.payload;
      return {
        ...state,
        AllClassesByActiveEnrolmentsLoading,
      };
    }

    case actionTypes.SetSelected: {
      const { selected } = action.payload;
      return {
        ...state,
        selected: selected,
        classesByLocation: selected,
      };
    }

    default:
      return state;
  }
};

export default classesReducer;
