export const actionTypes = {
  GetList: "contact/GET_LIST",
  SetState: "contact/SET_STATE",
  CreateContact: "contact/CREATE_LIST",
  UpdateContact: "contact/UPDATE_LIST",
  DeleteContact: "contact/DELETE_LIST",
  GetSelectedContact: "contact/GET_SELECTED_LIST",
  SetLoading: "contact/SET_LOADING",
  ExportCsvFile: "contact/EXPORT_CSV_FILE",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  SetLoading: (payload) => ({ type: actionTypes.SetLoading, payload }),
  createContact: (payload) => ({ type: actionTypes.CreateContact, payload }),
  updateContact: (payload) => ({ type: actionTypes.UpdateContact, payload }),
  deleteContact: (payload) => ({ type: actionTypes.DeleteContact, payload }),
  getSelectedContact: (payload) => ({
    type: actionTypes.GetSelectedList,
    payload,
  }),
  exportCsvFile: (payload) => ({
    type: actionTypes.ExportCsvFile,
    payload,
  }),
};
