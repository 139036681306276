import {
  getAll,
  deleteStudent,
  create,
  getSelected,
  updateSelected,
  getStudentsByParent,
  exportCSVFile,
  addMessage,
  editMessage,
  deleteMessage,
  searchStudent,
  getBirthdayList,
} from "../../services/student";
import { actionTypes, actions } from "../actions/students";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";
import { conforms } from "lodash";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList(payload) {
    try {
      const result = yield call(getAll, payload);
      return yield put(
        actions.setState({
          list: result.data.results,
          count: result.data.count,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.GetBirthdaysList, function* getBirthdays(
    payload
  ) {
    yield put(
      actions.SetBirthdayLoader({
        loading: true,
      })
    );
    try {
      const result = yield call(getBirthdayList, payload);
      yield put(
        actions.SetBirthdayLoader({
          loading: false,
        })
      );
      return yield put(
        actions.SetBirthdayPartyList({
          birthdayList: result?.data,
        })
      );
    } catch (error) {
      yield put(
        actions.SetBirthdayLoader({
          loading: false,
        })
      );
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.SearchStudent, function* searchAllStudents(
    payload
  ) {
    try {
      const result = yield call(searchStudent, payload);
      return yield put(
        actions.setState({
          list: result.data.students,
          count: result.data.count,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.GetStudentsByParent, function* getStudentsParent(
    payload
  ) {
    try {
      const result = yield call(getStudentsByParent, payload);

      return yield put(
        actions.setState({
          studentsByParents: result.data.results,
        })
      );
    } catch (error) {}
  });

  yield takeLatest(actionTypes.ExportCsvFile, function* exportCsvFileItem(
    payload
  ) {
    try {
      toast.success("Your download will start shortly!");
      const result = yield call(exportCSVFile, payload);
      return window.open(result.data.results, "_blank");
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.AddMessage, function* addNewMessage(payload) {
    try {
      const result = yield call(addMessage, payload.payload);

      toast.success(result.data.message);

      return yield put(
        actions.getSelectedStudent({
          selected: result.data.results,
        })
      );
    } catch (error) {
      return toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.EditMessage, function* editNewMessage(payload) {
    try {
      const result = yield call(editMessage, payload.payload);

      toast.success(result.data.message);

      return yield put(
        actions.getSelectedStudent({
          selected: result.data.results,
        })
      );
    } catch (error) {
      return toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.DeleteMessage, function* deleteNewMessage(
    payload
  ) {
    try {
      const result = yield call(deleteMessage, payload.payload);

      toast.success(result.data.message);

      return yield put(
        actions.getSelectedStudent({
          selected: result.data.results,
        })
      );
    } catch (error) {
      return toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.UpdateStudent, function* updateSelectedStudent(
    payload
  ) {
    try {
      const result = yield call(updateSelected, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.GetSelectedStudent, function* getSelectedStudent(
    payload
  ) {
    try {
      const result = yield call(getSelected, payload.payload);
      return yield put(
        actions.getSelectedStudent({
          selected: result.data,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  });

  yield takeLatest(actionTypes.CreateStudent, function* createStudent(payload) {
    try {
      const result = yield call(create, payload.payload.values);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.DeleteStudent, function* deleteSelectedVenue(
    payload
  ) {
    try {
      const result = yield call(deleteStudent, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
      return yield put(
        actions.setState({
          meta: null,
        })
      );
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });
}
