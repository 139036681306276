import {
  getAll,
  create,
  updateSelected,
  deleteService,
  getSelected,
  getAllCoachesReports,
  getReport,
  getEnrolmentsByService,
} from "../../services/service";
import { actionTypes, actions } from "../actions/service";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList({ payload }) {
    try {
      const result = yield call(getAll, payload);
      return yield put(
        actions.setState({
          list: result.data,
          // meta: null,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(
    actionTypes.GetEnrolmentsByService,
    function* getAllEnrolmentsReportsByServices() {
      try {
        const result = yield call(getEnrolmentsByService);
        return yield put(
          actions.setState({
            enrolmentsByService: result.data,
          })
        );
      } catch (error) {
        console.log(error);
        console.log(error.response);
        if (error?.response?.data?.error) {
          return toast.error(error.response.data.error);
        }
      }
    }
  );

  yield takeLatest(actionTypes.GetCoachReport, function* GetAllCoachReport(
    payload
  ) {
    try {
      const result = yield call(getAllCoachesReports, payload.payload);
      if (result)
        return yield put(
          actions.SetCoachReport({
            coaches: result.data,
          })
        );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.GetReport, function* getServiceReport(payload) {
    yield put(
      actions.loading({
        loading: true,
      })
    );
    try {
      const result = yield call(getReport, payload.payload);
      yield put(
        actions.loading({
          loading: false,
        })
      );

      return yield put(
        actions.SetReport({
          report: result.data.result,
        })
      );
    } catch (error) {
      yield put(
        actions.loading({
          loading: false,
        })
      );
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.GetSelectedService, function* getSelectedService(
    payload
  ) {
    try {
      const result = yield call(getSelected, payload.payload);
      if (result)
        return yield put(
          actions.SetSelectedService({
            selected: result.data,
          })
        );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.CreateService, function* createService(payload) {
    try {
      const result = yield call(create, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.DeleteService, function* deleteSelectedService(
    payload
  ) {
    try {
      const result = yield call(deleteService, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
      return yield put(
        actions.setState({
          meta: null,
        })
      );
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.UpdateService, function* updateSelectedService(
    payload
  ) {
    try {
      const result = yield call(updateSelected, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });
}
