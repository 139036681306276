export const actionTypes = {
  GetAssignFortutor: "birthdayparty/GetAssignFortutor",
  GetList: "birthdayparty/GET_LIST",
  GetGroupList: "birthdayparty/GET_GROUP_LIST",

  GetPastBirthdayParty: "birthdayparty/GET_PAST_LIST",
  SetPastBirthdayParty: "birthdayparty/SET_PAST_LIST",

  GetFutureBirthdayParty: "birthdayparty/GET_UPCOMING_LIST",
  SetFutureBirthdayParty: "birthdayparty/SET_UPCOMING_LIST",

  SetAssignFortutor:"birthdayparty/SET_ASSIGN_FOR_TUTER",
  SetState: "birthdayparty/SET_STATE",
  CreateBirthdayParty: "birthdayparty/CREATE_BIRTHDAY_PARTY",
  DeleteBirthdayParty: "birthdayparty/DELETE_BIRTHDAY_PARTY",

  GetSelectedBirthdayParty: "birthdayparty/GET_SELECTED_BIRTHDAY_PARTY",
  SetSelectedBirthdayParty: "birthdayparty/SET_SELECTED_BIRTHDAY_PARTY",

  GetLocationSurcharge: "birthdayparty/GET_LOCATION_SURCHARGE",
  SetLocationSurcharge: "birthdayparty/SET_LOCATION_SURCHARGE",

  GetCancelledList: "birthdayparty/GET_CANCELLED_LIST",
  SetCancelledList: "birthdayparty/SET_CANCELLED_LIST",

  UpdateBirthdayParty: "birthdayparty/UPDATE_BIRTHDAY_PARTY",

  AddMessage: "birthdayparty/ADD_MESSAGE",
  EditMessage: "birthdayparty/EDIT_MESSAGE",
  DeleteMessage: "birthdayparty/DELETE_MESSAGE",
  SetLoading: "bithdayparty/SET_LOADING",
};

export const actions = {
  setAssignFortutor: (payload) => ({ type: actionTypes.SetAssignFortutor , payload}),
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  SetLoading: (payload) => ({ type: actionTypes.SetLoading, payload }),
  CreateBirthdayParty: (payload) => ({
    type: actionTypes.CreateBirthdayParty,
    payload,
  }),
  UpdateBirthdayParty: (payload) => ({
    type: actionTypes.UpdateBirthdayParty,
    payload,
  }),
  GetSelectedBirthdayParty: (payload) => ({
    type: actionTypes.GetSelectedBirthdayParty,
    payload,
  }),
  SetSelectedBirthdayParty: (payload) => ({
    type: actionTypes.SetSelectedBirthdayParty,
    payload,
  }),
  GetAssignFortutor: (payload) => ({
    type: actionTypes.GetAssignFortutor,
    payload,
  }),
  GetList: (payload) => ({
    type: actionTypes.GetList,
    payload,
  }),

  GetPastBirthdayParty: (payload) => ({
    type: actionTypes.GetPastBirthdayParty,
    payload,
  }),
  SetPastBirthdayParty: (payload) => ({
    type: actionTypes.SetPastBirthdayParty,
    payload,
  }),
  GetFutureBirthdayParty: (payload) => ({
    type: actionTypes.GetFutureBirthdayParty,
    payload,
  }),
  SetFutureBirthdayParty: (payload) => ({
    type: actionTypes.SetFutureBirthdayParty,
    payload,
  }),
  GetLocationSurcharge: (payload) => ({
    type: actionTypes.GetLocationSurcharge,
    payload,
  }),
  SetLocationSurcharge: (payload) => ({
    type: actionTypes.SetLocationSurcharge,
    payload,
  }),

  GetCancelledList: (payload) => ({
    type: actionTypes.GetCancelledList,
    payload,
  }),
  SetCancelledList: (payload) => ({
    type: actionTypes.SetCancelledList,
    payload,
  }),
  AddMessage: (payload) => ({
    type: actionTypes.AddMessage,
    payload,
  }),
  EditMessage: (payload) => ({
    type: actionTypes.EditMessage,
    payload,
  }),
  DeleteMessage: (payload) => ({
    type: actionTypes.DeleteMessage,
    payload,
  }),
};
