import { actionTypes } from "../actions/service";

const initialServiceReducer = {
  list: [],
  selected: {},
  coaches: [],
  report: [],
  enrolmentsByService: [],
  loading: false,
};

const serviceReducer = (state = initialServiceReducer, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list, enrolmentsByService } = action.payload;
      return {
        ...state,
        list,
        enrolmentsByService,
      };
    }

    case actionTypes.SetSelectedService: {
      return {
        ...state,
        selected: { ...action.payload.selected },
      };
    }

    case actionTypes.SetCoachReport: {
      const { coaches } = action.payload;
      return {
        ...state,
        coaches,
      };
    }

    case actionTypes.SetReport: {
      const { report } = action.payload;

      return {
        ...state,
        report,
      };
    }

    case actionTypes.SET_LOADING: {
      const { loading } = action.payload;

      return {
        ...state,
        loading,
      };
    }

    default:
      return state;
  }
};

export default serviceReducer;
