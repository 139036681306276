export const actionTypes = {
  GetList: "bookingSettings/GET_LIST",
  GetListAvailabilities: "bookingSettings/GET_LIST_AVAILABILITIES",
  SetState: "bookingSettings/SET_STATE",
  SetListLoading: "bookingSettings/SET_LIST_LOADING",
  SetAvailabilitiesLoading: "bookingSettings/SET_AVAILABILITIES_LOADING",
  SetAvailabilities: "bookingSettings/SET_AVAILABILITIES",
  GetEventExtra : "bookingSettings/GET_EVENT_EXTRA",
  SetEventExtra: "bookingSettings/SET_EVENT_EXTRA",
  SetEventExtraLoading: "bookingSettings/GET_EVENT_EXTRA_LOADING",
  GetInflatableItems: "bookingSettings/GET_INFLATABLE_ITEMS",
  SetInflatableItems: "bookingSettings/SET_INFLATABLE_ITEMS",
  SetInflatableItemsLoading: "bookingSettings/GET_INFLATABLE_ITEMS_LOADING",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  setAvailabilities: (payload) => ({
    type: actionTypes.SetAvailabilities,
    payload,
  }),
  setEventExtra: (payload) => ({
    type: actionTypes.SetEventExtra,
    payload,
  }),
  setListLoading: (payload) => ({ type: actionTypes.SetListLoading, payload }),
  setEventExtraLoading: (payload) => ({ type: actionTypes.SetEventExtraLoading, payload }),
  setAvailabilitiesLoading: (payload) => ({
    type: actionTypes.SetAvailabilitiesLoading,
    payload,
  }),
  setInflatableItems: (payload) => ({
    type: actionTypes.SetInflatableItems,
    payload,
  }),
  setInflatableItemsLoading: (payload) => ({ type: actionTypes.SetInflatableItemsLoading, payload }),
};
