import {
  getAll,
  deleteParent,
  create,
  getSelected,
  updateSelected,
  exportCSVFile,
  addMessage,
  editMessage,
  deleteMessage,
  getParentsToChargeThisMonth,
  searchParent,
  getNextMonthCalculations,
  getNextTermCalculations,
  getParentsToChargeThisTerm,
  getAllPaymentMethodsOfParent,
  getCredistReports,
  getAllGmailMessages,
} from "../../services/parent";
import { actionTypes, actions } from "../actions/parents";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";
import { PAGINATION_PAGE_LIMIT } from "../../app/Common/commonMethods";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList(payload) {
    yield put(
      actions.loading({
        loading: true,
      })
    );
    try {
      const result = yield call(getAll, payload);
      yield put(
        actions.loading({
          loading: false,
        })
      );
      return yield put(
        actions.setState({
          list: result.data.parents,
          parentListCount: result.data.count,
          // meta: null,
        })
      );
    } catch (error) {
      yield put(
        actions.loading({
          loading: false,
        })
      );
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.GetCredistReport, function* getCreditsReport(
    payload
  ) {
    yield put(
      actions.SetCreditsLoading({
        loading: true,
      })
    );
    try {
      const result = yield call(getCredistReports, payload);
      yield put(
        actions.SetCreditsLoading({
          loading: false,
        })
      );
      return yield put(
        actions.SetCredistReport({
          creditsReport: result?.data,
        })
      );
    } catch (error) {
      yield put(
        actions.SetCreditsLoading({
          loading: false,
        })
      );
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.GetPaymentMethods, function* getList(payload) {
    try {
      const result = yield call(getAllPaymentMethodsOfParent, payload);
      return yield put(
        actions.setPaymentsMethods({
          paymentMethods: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.GetGmailMessages, function* getMessagesList(
    payload
  ) {
    try {
      const result = yield call(getAllGmailMessages, payload);
      return yield put(
        actions.SetGmailMessages({
          gmailMessage: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.SearchParent, function* searchAllParents(
    payload
  ) {
    try {
      const result = yield call(searchParent, payload);
      return yield put(
        actions.setState({
          list: result.data.parents,
          count: result.data.count,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(
    actionTypes.ParentToChargeThisMonth,
    function* getParentToCharge({ payload }) {
      try {
        const result = yield call(getParentsToChargeThisMonth, payload);
        return yield put(
          actions.setState({
            parentsToChargeThisMonth: result.data.results,
          })
        );
      } catch (error) {}
    }
  );

  yield takeLatest(
    actionTypes.GetParentsPaymentDetails,
    function* getParentsPaymentsDetails({ payload }) {
      try {
        yield put(
          actions.setCalculationLoader({
            loading: true,
          })
        );

        const result = yield call(getNextMonthCalculations, payload);
        yield put(
          actions.SetParentsPaymentDetails({
            count: result.data.count,
            totalRevenueOfMonth: result.data.totalRevenueOfMonth,
            totalEnrolmentsThisMonth: result.data.totalEnrolments,
            parentsToChargeThisMonth: result.data.enrolments,
          })
        );

        yield put(
          actions.setCalculationLoader({
            loading: false,
          })
        );
      } catch (error) {
        console.log(error);
        yield put(
          actions.setCalculationLoader({
            loading: false,
          })
        );
      }
    }
  );

  yield takeLatest(
    actionTypes.ParentToChargeThisTerm,
    function* getParentToChargeTermList({ payload }) {
      try {
        const result = yield call(getParentsToChargeThisTerm, payload);
        return yield put(
          actions.setState({
            parentsToChargeThisTerm: result.data.results,
          })
        );
      } catch (error) {}
    }
  );

  yield takeLatest(
    actionTypes.GetParentsTermPaymentsDetails,
    function* getParentsPaymentsDetailsTerm({ payload }) {
      try {
        yield put(
          actions.setCalculationLoader({
            loading: true,
          })
        );

        const result = yield call(getNextTermCalculations, payload);
        yield put(
          actions.SetParentsTermPaymentsDetails({
            countTerm: result.data.count,
            totalRevenueOfTerm: result.data.totalRevenueOfMonth,
            totalEnrolmentsThisTerm: result.data.totalEnrolments,
          })
        );

        yield put(
          actions.setCalculationLoader({
            loading: false,
          })
        );
      } catch (error) {
        console.log(error);
        yield put(
          actions.setCalculationLoader({
            loading: false,
          })
        );
      }
    }
  );

  yield takeLatest(actionTypes.ExportCsvFile, function* exportCsvFileItem(
    payload
  ) {
    try {
      toast.success("Your download will start shortly!");
      const result = yield call(exportCSVFile, payload);
      return window.open(result.data.results, "_blank");
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.AddMessage, function* addNewMessage(payload) {
    try {
      const result = yield call(addMessage, payload.payload);

      toast.success(result.data.message);

      return yield put(
        actions.getSelectedParent({
          selected: result.data.results,
        })
      );
    } catch (error) {
      return toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.EditMessage, function* editNewMessage(payload) {
    try {
      const result = yield call(editMessage, payload.payload);

      toast.success(result.data.message);

      return yield put(
        actions.getSelectedParent({
          selected: result.data.results,
        })
      );
    } catch (error) {
      return toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.DeleteMessage, function* deleteNewMessage(
    payload
  ) {
    try {
      const result = yield call(deleteMessage, payload.payload);

      toast.success(result.data.message);

      return yield put(
        actions.getSelectedParent({
          selected: result.data.results,
        })
      );
    } catch (error) {
      return toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.UpdateParent, function* updateSelectedParent(
    payload
  ) {
    try {
      const result = yield call(updateSelected, payload.payload);
      // yield put({
      //   type: actionTypes.GetList,
      // });
      toast.success(result.data.message);
      return yield put(
        actions.getSelectedParent({
          selected: result.data.results,
        })
      );
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.GetSelectedParent, function* getSelectedParent(
    payload
  ) {
    try {
      const result = yield call(getSelected, payload.payload);
      return yield put(
        actions.getSelectedParent({
          selected: result.data,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  });

  yield takeLatest(actionTypes.CreateParent, function* createParent({
    payload,
  }) {
    try {
      const result = yield call(create, payload.values);
      if (payload.pagination) {
        yield put({
          type: actionTypes.GetList,
          payload: {
            business: payload?.values?.business,
            pagination: {
              page: payload?.pagination?.page,
              limit: payload?.pagination?.limit,
            },
          },
        });
      }
      toast.success("Parent successfully created!");
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.DeleteParent, function* deleteSelectedVenue({
    payload,
  }) {
    try {
      const result = yield call(deleteParent, payload);
      yield put({
        type: actionTypes.GetList,
        payload: {
          business: payload.activeKey,
          pagination: {
            page: payload.activePage,
            limit: PAGINATION_PAGE_LIMIT,
          },
        },
      });

      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });
}
