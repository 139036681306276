import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Field } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import MuiAlert from "@material-ui/lab/Alert";

import { FormattedMessage, injectIntl } from "react-intl";
import { Input } from "@material-ui/core";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SigninSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),
  password: Yup.string()
    .required("Required")
    .min(6, "Too Short!"),
});

const Login = (props) => {
  const {
    dispatch,
    auth: { meta },
    reRenderHandler,
    match,
    history,
    user,
    showCancel,
    onCancel,
  } = props;
  const [loading, setLoading] = useState(false);

  const getInputClasses = (meta, fieldname) => {
    let result = "form-control ";
    if (meta.touched && meta.error) {
      result += " is-invalid";
    }

    if (meta.touched && !meta.error) {
      result += " is-valid";
    }

    return result;
  };

  return (
    <div className="login-form login-signin" style={{ width: "100%" }}>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={SigninSchema}
        onSubmit={(values) => {
          dispatch({
            type: "[Login] Action",
            payload: { ...values, business: match.params.business },
          });

          setTimeout(() => {
            if (
              user &&
              user.roleId.name === "parent" &&
              user.roleId.business === match.params.business
            ) {
              dispatch({
                type: "students/GET_STUDENTS_BY_PARENT",
                parentId: user._id,
              });

              history.push(match.url);
              reRenderHandler();
            }
          }, 500);
        }}
      >
        {({ status, handleSubmit, setFieldValue }) => (
          <form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
            <div className="form-group">
              <Field
                name="email"
                component={Input}
                placeholder="Email"
                label="Email"
              >
                {({ field, form, meta }) => (
                  <div>
                    <input
                      type="email"
                      {...field}
                      className={`${getInputClasses(meta)}`}
                      placeholder="Enter Email"
                      onChange={(e) => {
                        setFieldValue("email", e.target.value.toLowerCase());
                      }}
                    />
                    {meta.touched && meta.error && (
                      <div className="error invalid-feedback">{meta.error}</div>
                    )}
                  </div>
                )}
              </Field>
            </div>

            <div className="form-group">
              <Field
                name="password"
                component={Input}
                placeholder="Password"
                label="Password"
              >
                {({ field, form, meta }) => (
                  <div>
                    <input
                      type="password"
                      {...field}
                      className={`${getInputClasses(meta)}`}
                      placeholder="Enter Password"
                    />
                    {meta.touched && meta.error && (
                      <div className="error invalid-feedback">{meta.error}</div>
                    )}
                  </div>
                )}
              </Field>
            </div>

            {/* begin::Actions */}
            <button
              type="submit"
              className={`btn btn-primary  ${
                meta && meta.login && meta.login.loading
                  ? "spinner spinner-white spinner-left"
                  : ""
              }`}
              disabled={meta && meta.login && meta.login.loading ? true : false}
            >
              <span>Sign In</span>
            </button>
            {showCancel && (
              <button onClick={onCancel} className="btn btn-secondary ml-2">
                Back
              </button>
            )}
            {/* end::Actions */}
          </form>
        )}
      </Formik>
      {/*end::Form*/}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default injectIntl(connect(mapStateToProps, null)(Login));
