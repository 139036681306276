import { getAll } from "../../services/prices";
import { actionTypes, actions } from "../actions/price";
import { put, takeLatest, call } from "redux-saga/effects";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    yield put(
      actions.SetLoading({
        loading: true,
      })
    );
    try {
      const result = yield call(getAll);

      yield put(
        actions.SetLoading({
          loading: false,
        })
      );
      return yield put(
        actions.setState({
          list: result.data,
        })
      );
    } catch (error) {
      console.log(error);
      yield put(
        actions.SetLoading({
          loading: false,
        })
      );
      return yield put(
        actions.setState({
          list: [],
        })
      );
    }
  });
}
