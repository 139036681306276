import { actionTypes } from "../actions/enrolment";

const initialEnrolmentState = {
  list: [],
  count: "",
  show: false,
  prevEnrolmentsByParent: [],
  futureEnrolmentsByParent: [],
  enrolmentsByParent: [],
  enrolmentsByClass: [],
  graphs: [],
  selected: {},
  cancelled: [],
  pageEnrolments: [],
  totalAmountToPay: "",
  enrolmentInLesson: [],
  childcareMessage: "",
  parents: [],
  progress: {},
  graphData: {},
  compareData: {},
  Progressloading: false,
  enrolmentsByLocation: [],
  enrolmentByVenueLoader: false,
  reportsByAge: [],
  reportsByCancellations: {},
  unpaidPayments: [],
  futureLessonEnrolments: [],
  loadingFutureEnrolments: false,
  loading: false,
  enrolmentTableLoading: false,
  activeEnrolmentsReports: {},
  fullPaymentsEnrolmentsReports: [],
  allActiveEnrolments : [],
  pendingPaymentsEnrolmentsReports: [],
  nextMonthPaymentsEnrolmentsReports: [],
  hearAboutUsReport: [],
  hearAboutUsReportLoading: false,
};

const enrolmentTypesReducer = (state = initialEnrolmentState, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const {
        list,
        count,
        meta,
        show,
        totalAmountToPay,
        enrolmentInLesson,
        childcareMessage,
        parents,
        futureLessonEnrolments,
        allActiveEnrolments,
        fullPaymentsEnrolmentsReports,
        pendingPaymentsEnrolmentsReports,
        nextMonthPaymentsEnrolmentsReports,
      } = action.payload;
      return {
        ...state,
        list,
        count,
        show,
        totalAmountToPay,
        enrolmentInLesson,
        meta: { ...meta },
        childcareMessage,
        parents,
        futureLessonEnrolments,
        allActiveEnrolments,
        fullPaymentsEnrolmentsReports,
        pendingPaymentsEnrolmentsReports,
        nextMonthPaymentsEnrolmentsReports,
      };
    }

    case actionTypes.SetActiveEnrolmentReport: {
      const { activeEnrolmentsReports } = action.payload;
      return {
        ...state,
        activeEnrolmentsReports,
      };
    }


    case actionTypes.SetFullPaymentsEnrolmentReport: {
      console.log("chekckdkckc",action.payload)
      const { fullPaymentsEnrolmentsReports } = action.payload;
      return {
        ...state,
        fullPaymentsEnrolmentsReports,
      };
    }
    // case actionTypes.SetAllEnrolments: {
    //   const { allActiveEnrolments } = action.payload;
    //   return {
    //     ...state,
    //     allActiveEnrolments,
    //   };
    // }

    case actionTypes.SetLoadingFutureEnrolments: {
      const { loading } = action.payload;
      return {
        ...state,
        loadingFutureEnrolments: loading,
      };
    }

    case actionTypes.SetCancelledList: {
      const { cancelledList } = action.payload;
      return {
        ...state,
        cancelledList,
      };
    }

    case actionTypes.SetReportsByCancellations: {
      const { reportsByCancellations } = action.payload;
      return {
        ...state,
        reportsByCancellations,
      };
    }

    case actionTypes.SetFilteredList: {
      const { progress, loading } = action.payload;
      return {
        ...state,
        progress,
        Progressloading: loading,
      };
    }

    case actionTypes.SetFilteredGraphData: {
      const { graphData, loading } = action.payload;
      return {
        ...state,
        graphData,
        Progressloading: loading,
      };
    }

    case actionTypes.SetFilteredCompareData: {
      const { compareData, loading } = action.payload;
      return {
        ...state,
        compareData,
        Progressloading: loading,
      };
    }

    case actionTypes.SetPrevEnrolmentByParent: {
      const { prevEnrolmentsByParent } = action.payload;
      return {
        ...state,
        prevEnrolmentsByParent,
      };
    }

    case actionTypes.SetFutureEnrolmentByParent: {
      const { futureEnrolmentsByParent } = action.payload;
      return {
        ...state,
        futureEnrolmentsByParent,
      };
    }

    case actionTypes.SetEnrolmentByParent: {
      const { enrolmentsByParent } = action.payload;
      return {
        ...state,
        enrolmentsByParent,
      };
    }

    case actionTypes.SetEnrolmentByClass: {
      const { enrolmentsByClass } = action.payload;
      return {
        ...state,
        enrolmentsByClass,
      };
    }
    case actionTypes.SetGraph: {
      const { graphs } = action.payload;
      return {
        ...state,
        graphs,
      };
    }
    case actionTypes.SetSelectedPage: {
      const { pageEnrolments, count } = action.payload;
      return {
        ...state,
        pageEnrolments,
        count,
      };
    }
    case actionTypes.SetSelected: {
      const { selected } = action.payload;
      return {
        ...state,
        selected,
      };
    }

    case actionTypes.SetEnrolmentsByLocationOrVenue: {
      const { enrolmentsByLocation } = action.payload;
      return {
        ...state,
        enrolmentsByLocation,
      };
    }

    case actionTypes.SetEnrolmentByLocationLoader: {
      const { loading } = action.payload;
      return {
        ...state,
        enrolmentByVenueLoader: loading,
      };
    }

    case actionTypes.SetReportsByAge: {
      const { reportsByAge } = action.payload;
      return {
        ...state,
        reportsByAge,
      };
    }

    case actionTypes.SetReportsByCancellations: {
      const { reportsByCancellations } = action.payload;
      return {
        ...state,
        reportsByCancellations,
      };
    }

    case actionTypes.SetLoading: {
      const { loading } = action.payload;
      return {
        ...state,
        loading,
      };
    }

    case actionTypes.SetLoadingEnrolments: {
      const { loading } = action.payload;
      return {
        ...state,
        enrolmentTableLoading: loading,
      };
    }

    case actionTypes.SetHearAboutUsReportLoading: {
      const { loading } = action.payload;
      return {
        ...state,
        hearAboutUsReportLoading: loading,
      };
    }

    case actionTypes.SetHearAboutUsReport: {
      const { hearAboutUsReport } = action.payload;
      return {
        ...state,
        hearAboutUsReport,
      };
    }

    default:
      return state;
  }
};

export default enrolmentTypesReducer;
