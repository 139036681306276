export const actionTypes = {
  GetList: "programs/GET_LIST",
  SetState: "programs/SET_STATE",
  CreateProgram: "programs/CREATE_PROGRAM",
  UpdateProgram: "programs/UPDATE_PROGRAM",
  DeleteProgram: "programs/DELETE_PROGRAM",
  GetSelectedProgram: "programs/GET_SELECTED_PROGRAM",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  createProgram: (payload) => ({ type: actionTypes.CreateProgram, payload }),
  updateProgram: (payload) => ({ type: actionTypes.UpdateProgram, payload }),
  deleteProgram: (payload) => ({ type: actionTypes.DeleteProgram, payload }),
  getSelectedProgram: (payload) => ({ type: actionTypes.GetSelectedProgram, payload }),
};
