import React from "react";

function StepOne({
  formWizard,
  address,
  setAddress,
  user,
  setFirstName,
  setLastName,
  setEmail,
  setPhone,
  match,
  onEmailChangeHandler,
  message,
  exist,
  error,
}) {
  return (
    <form
      className="form fv-plugins-bootstrap fv-plugins-framework"
      id="kt_form"
      onSubmit={(e) => {
        e.preventDefault();
        formWizard.goNext();
      }}
    >
      <h4 className="mb-10 font-weight-bold text-dark">Enter Your Details</h4>
      {match?.params?.uuid ? (
        <div className="row">
          <div className="col-xl-6">
            <div className="form-group fv-plugins-icon-container">
              <label>First Name</label>
              <input
                type="text"
                className="form-control form-control-solid form-control-lg"
                name="firstName"
                placeholder="First Name"
                defaultValue={user?.firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required={true}
              />
              <span className="form-text text-muted">
                Please enter your first name.
              </span>
              <div className="fv-plugins-message-container"></div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="form-group fv-plugins-icon-container">
              <label>Last Name</label>
              <input
                type="text"
                className="form-control form-control-solid form-control-lg"
                name="lastName"
                placeholder="Last Name"
                defaultValue={user?.lastName}
                onChange={(e) => setLastName(e.target.value)}
                required={true}
              />
              <span className="form-text text-muted">
                Please enter your last name.
              </span>
              <div className="fv-plugins-message-container"></div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="form-group fv-plugins-icon-container">
              <label>Phone Number</label>
              <input
                type="number"
                className="form-control form-control-solid form-control-lg"
                name="phone"
                placeholder="Phone"
                defaultValue={user?.phone}
                onChange={(e) => setPhone(e.target.value)}
                required={true}
              />
              <span className="form-text text-muted">
                Please enter your phone.
              </span>
              <div className="fv-plugins-message-container"></div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="form-group fv-plugins-icon-container">
              <label>Email</label>
              <input
                type="email"
                className="form-control form-control-solid form-control-lg"
                name="email"
                placeholder="Email"
                defaultValue={user?.email}
                onChange={(e) => {
                  onEmailChangeHandler(e);
                }}
                required={true}
              />
              {message && (
                <div style={{ color: error ? "red" : "green" }}>
                  {exist ? (
                    <p>
                      An account already exist with this email address. Please
                      login to checkout.
                    </p>
                  ) : null}
                </div>
              )}
              <span className="form-text text-muted">
                Please enter your email.
              </span>
              <div className="fv-plugins-message-container"></div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="form-group fv-plugins-icon-container">
        <label>Address</label>
        <input
          type="text"
          className="form-control form-control-solid form-control-lg"
          name="address1"
          placeholder="Address"
          defaultValue={address.address}
          onChange={(e) =>
            setAddress({
              ...address,
              address: e.target.value,
            })
          }
          required={true}
        />
        <span className="form-text text-muted">Please enter your Address.</span>
        <div className="fv-plugins-message-container"></div>
      </div>
      <div className="row">
        <div className="col-xl-6">
          <div className="form-group fv-plugins-icon-container">
            <label>Postcode</label>
            <input
              type="text"
              className="form-control form-control-solid form-control-lg"
              name="postcode"
              placeholder="Postcode"
              defaultValue={address.postcode}
              onChange={(e) =>
                setAddress({
                  ...address,
                  postcode: e.target.value,
                })
              }
              required={true}
            />
            <span className="form-text text-muted">
              Please enter your Postcode.
            </span>
            <div className="fv-plugins-message-container"></div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="form-group fv-plugins-icon-container">
            <label>Suburb</label>
            <input
              type="text"
              className="form-control form-control-solid form-control-lg"
              name="state"
              placeholder="Suburb"
              defaultValue={address.suburb}
              onChange={(e) =>
                setAddress({
                  ...address,
                  suburb: e.target.value,
                })
              }
              required={true}
            />
            <span className="form-text text-muted">
              Please enter your Suburb.
            </span>
            <div className="fv-plugins-message-container"></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6">
          <div className="form-group fv-plugins-icon-container">
            <label>State</label>
            <input
              type="text"
              className="form-control form-control-solid form-control-lg"
              name="state"
              placeholder="State"
              defaultValue={address.state}
              onChange={(e) =>
                setAddress({
                  ...address,
                  state: e.target.value,
                })
              }
              required={true}
            />
            <span className="form-text text-muted">
              Please enter your State.
            </span>
            <div className="fv-plugins-message-container"></div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between border-top mt-5 pt-10">
        <div className="mr-2"></div>
        <div>
          <button
            type="submit"
            className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
          >
            Next
          </button>
        </div>
      </div>
    </form>
  );
}

export default StepOne;
