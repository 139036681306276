import { actionTypes } from "../actions/tutors";

const initialTutorsState = {
  list: [],
  selected: {},
  scheduleList: [],
  coachesByUuid: [],
  // meta: {},
  tutorLessons: [],
  loading: false,
};

const tutorReducer = (state = initialTutorsState, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list, coachesByUuid } = action.payload;
      return {
        ...state,
        coachesByUuid,
        list,
      };
    }

    case actionTypes.GetSelectedTutor: {
      return {
        ...state,
        selected: { ...action.payload.selected },
      };
    }

    case actionTypes.SetSchedule: {
      const { scheduleList } = action.payload;
      return {
        ...state,
        scheduleList,
      };
    }

    case actionTypes.SetTutorLessons: {
      const { tutorLessons } = action.payload;
      return {
        ...state,
        tutorLessons,
      };
    }

    case actionTypes.SetTutorLessonUpdate: {
      const { lesson } = action.payload;
      return {
        ...state,
        tutorLessons: state.tutorLessons?.map((selected) => {
          if (selected?._id === lesson?._id) {
            return {
              ...selected,
              date: lesson?.date,
              startTime: lesson?.startTime,
              endTime: lesson?.endTime,
            };
          } else {
            return selected;
          }
        }),
      };
    }

    case actionTypes.SetLoading: {
      const { loading } = action.payload;
      return {
        ...state,
        loading,
      };
    }

    default:
      return state;
  }
};

export default tutorReducer;
