import { actionTypes } from "../actions/waitingList";

const initialWaitingListState = {
  list: [],
  listBySelectedClass: [],
  selected: {},
  count: null,
  loading: false,
};

const waitingListReducer = (state = initialWaitingListState, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list, listBySelectedClass, count } = action.payload;
      return {
        ...state,
        count,
        list,
        listBySelectedClass,
      };
    }

    case actionTypes.SetLoading: {
      const { loading } = action.payload;

      return {
        ...state,
        loading,
      };
    }

    case actionTypes.SetSelected: {
      const { selected } = action.payload;

      return {
        ...state,
        selected,
      };
    }

    default:
      return state;
  }
};

export default waitingListReducer;
