import { actionTypes } from "../actions/categories";

const initialCategorysState = {
  list: [],
  meta: {},
  selected: {},
};

const categoryReducer = (state = initialCategorysState, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list, meta } = action.payload;
      return {
        ...state,
        list,
        meta: { ...meta },
      };
    }
    case actionTypes.GetSelectedCategory: {
      return {
        ...state,
        selected: { ...action.payload.selected },
      };
    }

    default:
      return state;
  }
};

export default categoryReducer;
