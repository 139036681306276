import React from "react";
import { withRouter } from "react-router-dom";

function ParentShopRight({
  cartItems,
  dispatch,
  history,
  toast,
  removeFromCart,
  match,
}) {
  console.log({ match });
  return (
    <div className="card card-custom card-stretch gutter-b">
      <div
        className="card-header p-0"
        style={{
          minHeight: "50px",
        }}
      >
        <button
          className="btn btn-block btn-success btn-md"
          onClick={() => {
            if (cartItems && cartItems.length > 0) {
              dispatch({
                type: "products/SET_CART",
                payload: {
                  cart: cartItems,
                },
              });
              if (match?.params?.uuid) {
                history.push(
                  `/${match?.params?.uuid}/public-shop/checkout/${match.params.business}`
                );
              } else {
                history.push("/parent/shop/checkout");
              }
            } else {
              toast.error(
                "Please add an <strong>item to cart</strong> to proceed to checkout"
              );
            }
          }}
        >
          <i class="fa fas fa-shopping-bag"></i>
          {"  "}Continue to Checkout
        </button>
      </div>
      <div className="card-body">
        <div className="row">
          {cartItems && cartItems.length > 0 ? (
            cartItems.map((item, index) => (
              <div>
                <div className="d-flex align-items-center mb-8">
                  <div className="symbol mr-5 pt-1">
                    <div
                      className="symbol-label min-w-65px min-h-100px"
                      style={{
                        backgroundImage: `url('${item.product.featureImage}')`,
                      }}
                    ></div>
                  </div>
                  <div className="d-flex flex-column">
                    <a
                      href="#"
                      className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                    >
                      {item.product.name}
                    </a>
                    <span className="text-muted font-weight-bold font-size-sm pb-4">
                      Qty: {item.qty}
                    </span>
                    <span className="text-muted font-weight-bold font-size-sm pb-4">
                      Price: ${item.product.price}
                    </span>
                    <span className="text-muted font-weight-bold font-size-sm pb-4">
                      Total: $
                      {parseInt(item.qty) * parseInt(item.product.price)}
                    </span>
                    <div>
                      <button
                        type="button"
                        className="btn btn-light font-weight-bolder font-size-sm py-2"
                        onClick={() => removeFromCart({ ...item, index })}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="col-12 d-flex justify-content-center">
              <h6 className="font-weight-bolder">
                <i className="flaticon2-shopping-cart-1"></i> Cart is empty
              </h6>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default withRouter(ParentShopRight);
