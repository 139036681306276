import { getAll, deleteFailedPayment } from "../../services/payments";
import { actionTypes, actions } from "../actions/payments";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetListFailedPayments, function* getList() {
    try {
      const result = yield call(getAll);
      return yield put(
        actions.setState({
          failedPaymentsList: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.DeletePayment, function* deleteFailedPaymentSaga(
    payload
  ) {
    try {
      const result = yield call(deleteFailedPayment, payload.payload);
      yield put({
        type: actionTypes.GetListFailedPayments,
      });
      toast.success("Payment successfully deleted!");
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });
}
