export const ROLES_ACCESS_KEYS = {
  classes: "classes",
  lessons: "lessons",
  enrolments: "enrolments",
  waitingList: "waitingList",
  trials: "trials",
  makeupLessons: "makeupLessons",
  feedback: "feedback",
  coaches: "coaches",
  parents: "parents",
  players: "players",
  reports: "reports",
  orders: "orders",
  messages: "messages",
  tasks: "tasks",
  locations: "locations",
  products: "products",
  payments: "payments",
  refunds: "refunds",
  organization: "organization",
  teams: "teams",
  publicHolidays: "publicHolidays",
  coupons: "coupons",
  embedCodes: "embedCodes",
  emailTemplates: "emailTemplates",
  dashboard: "dashboard",
  roles: "roles",
  todaySessions: "todaySessions",
  calendar: "calendar",
  birthdayPartyBooking: "birthdayPartyBooking",
  oneToOneBooking: "oneToOneBooking",
  inflatableBooking: "inflatableBooking",
  inflatableItemsBooking: "inflatableItemsBooking",
  coachBooking: "coachBooking",
  playBooking: "playBooking",
  bookingAvailabilitySetting: "bookingAvailabilitySetting",
  eventExtras: "eventExtras",
};

export const PERMISSIONS = {
  view: "access",
  create: "create",
  edit: "edit",
  delete: "remove",
  importExport: "importExport",
};

export const ROLES_FORM_VALUES = [
  {
    section: "Classes",
    key: "classes",
    permissions: ["access", "create", "edit", "remove", "importExport"],
  },
  {
    section: "Lessons",
    key: "lessons",
    permissions: ["access", "create", "edit", "remove"],
  },
  {
    section: "Enrolments",
    key: "enrolments",
    permissions: ["access", "create", "edit", "remove", "importExport"],
  },
  {
    section: "Waiting List",
    key: "waitingList",
    permissions: ["access", "create", "edit", "remove"],
  },
  {
    section: "Trials",
    key: "trials",
    permissions: ["access", "create", "edit", "remove"],
  },
  {
    section: "Makeup Lessons",
    key: "makeupLessons",
    permissions: ["access", "create", "edit", "remove"],
  },
  {
    section: "Feedback",
    key: "feedback",
    permissions: ["access", null, "edit"],
  },
  {
    section: "Coaches",
    key: "coaches",
    permissions: ["access", "create", "edit", "remove", "importExport"],
  },
  {
    section: "Parents",
    key: "parents",
    permissions: ["access", "create", "edit", "remove", "importExport"],
  },
  {
    section: "Players",
    key: "players",
    permissions: ["access", "create", "edit", "remove", "importExport"],
  },
  {
    section: "Reports",
    key: "reports",
    permissions: ["access"],
  },
  {
    section: "Orders",
    key: "orders",
    permissions: ["access", "create", "edit", "remove", "importExport"],
  },
  {
    section: "Messages",
    key: "messages",
    permissions: ["access", "create", "edit", "remove"],
  },
  {
    section: "Tasks",
    key: "tasks",
    permissions: ["access", "create", "edit", "remove"],
  },
  {
    section: "Locations",
    key: "locations",
    permissions: ["access", "create", "edit", "remove"],
  },
  {
    section: "Products",
    key: "products",
    permissions: ["access", "create", "edit", "remove", "importExport"],
  },
  {
    section: "Payments",
    key: "payments",
    permissions: ["access", "create"],
  },
  {
    section: "Refunds",
    key: "refunds",
    permissions: ["access", "create"],
  },
  {
    section: "Organisation Details",
    key: "organization",
    permissions: ["access", "create", "edit", "remove"],
  },
  {
    section: "Teams",
    key: "teams",
    permissions: ["access", "create", "edit", "remove"],
  },
  {
    section: "Public Holidays",
    key: "publicHolidays",
    permissions: ["access", "create", "edit", "remove"],
  },
  {
    section: "Coupons",
    key: "coupons",
    permissions: ["access", "create", "edit", "remove"],
  },
  {
    section: "Embed Codes",
    key: "embedCodes",
    permissions: ["access", "create", "edit", "remove"],
  },
  {
    section: "Email Templates",
    key: "emailTemplates",
    permissions: ["access", "create", "edit", "remove"],
  },
  {
    section: "Roles",
    key: "roles",
    permissions: ["access", "create", "edit", "remove"],
  },
  {
    section: "Calendar",
    key: "calendar",
    permissions: ["access"],
  },
  {
    section: "Today's Sessions",
    key: "todaySessions",
    permissions: ["access"],
  },
  {
    section: "Birthday Party Booking",
    key: "birthdayPartyBooking",
    permissions: ["access"],
  },
  {
    section: "1-2-1 Booking",
    key: "oneToOneBooking",
    permissions: ["access"],
  },
  {
    section: "Inflatables Booking",
    key: "inflatableBooking",
    permissions: ["access"],
  },
  {
    section: "Inflatables Items Booking",
    key: "inflatableItemsBooking",
    permissions: ["access"],
  },
  {
    section: "Book a Coach",
    key: "coachBooking",
    permissions: ["access"],
  },
  {
    section: "Play Booking",
    key: "playBooking",
    permissions: ["access"],
  },
  {
    section: "Booking availability setting",
    key: "bookingAvailabilitySetting",
    permissions: ["access"],
  },
  {
    section: "Event Extras",
    key: "eventExtras",
    permissions: ["access"],
  },
];
