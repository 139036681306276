export const actionTypes = {
  GetList: "emailtemplate/GET_LIST",
  GetGroupList: "emailtemplate/GET_GROUP_LIST",
  SetState: "emailtemplate/SET_STATE",
  CreateEmailTemplate: "emailtemplate/CREATE_EMAIL_TEMPLATE",
  EditEmailTemplate: "emailtemplate/EDIT_EMAIL_TEMPLATE",
  DeleteEmailTemplate: "emailtemplate/DELETE_EMAIL_TEMPLATE",
  GetSelectedEmailTemplate: "emailtemplate/GET_SELECTED_EMAIL_TEMPLATE",
  SetSelectedEmailTemplate: "emailtemplate/SET_SELECTED_EMAIL_TEMPLATE",
  UpdateEmailTemplate: "emailtemplate/UPDATE_EMAIL_TEMPLATE",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  createEmailTemplate: (payload) => ({
    type: actionTypes.CreateEmailTemplate,
    payload,
  }),
  editEmailTemplate: (payload) => ({
    type: actionTypes.EditEmailTemplate,
    payload,
  }),
  getSelectedEmailTemplate: (payload) => ({
    type: actionTypes.GetSelectedEmailTemplate,
    payload,
  }),
  setSelectedEmailTemplate: (payload) => ({
    type: actionTypes.SetSelectedEmailTemplate,
    payload,
  }),
  deleteEmailTemplate: (payload) => ({
    type: actionTypes.DeleteEmailTemplate,
    payload,
  }),
};
