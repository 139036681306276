import {
  getOne,
  getAll,
  createClass,
  getAllLessonsByClass,
  getSelected,
  // updateLesson,
  getSearchedClasses,
  getAllClassesByLocation,
  sendCSVFile,
  exportCSVFile,
  duplicateRecord,
  getClassesByLocationPrivate,
  getClassesByService,
  getClassesByServiceEnrolment,
  getLessonsByClassesEnrolment,
  getClassesByServiceForCommunications,
  getSessionAll,
  getClassesWithEnrolPercentage,
  getByOrgIdAndLocation,
  getAllClassesByActiveEnrolments
} from "../../services/classes";
import { actionTypes, actions } from "../actions/classes";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetSessionRevenueClass, function* getSessionList(
    payload
  ) {
    yield put(
      actions.loading({
        loading: true,
      })
    );
    try {
      const result = yield call(getSessionAll, payload.payload);
      yield put(
        actions.loading({
          loading: false,
        })
      );

      return yield put(
        actions.SetSessionRevenueClass({
          session: result.data,
        })
      );
    } catch (error) {
      yield put(
        actions.loading({
          loading: true,
        })
      );
    }
  });

  yield takeLatest(actionTypes.GetList, function* getList(payload) {
    yield put(
      actions.loading({
        loading: true,
      })
    );
    try {
      const result = yield call(getAll, payload);
      yield put(
        actions.loading({
          loading: false,
        })
      );
      return yield put(
        actions.setState({
          list: result.data.results,
          count: result.data.count,
        })
      );
    } catch (error) {
      yield put(
        actions.loading({
          loading: false,
        })
      );
    }
  });

  yield takeLatest(
    actionTypes.GetClassesWithEnrolPercentage,
    function* getListClassesBasedOnEnrol({ payload }) {
      yield put(
        actions.SetClassWithEnrolLoading({
          classWithEnrolLoading: true,
        })
      );
      yield put(
        actions.setClassesWithEnrolPercentage({
          classesWithEnrolPercentage: [],
        })
      );
      try {
        const result = yield call(getClassesWithEnrolPercentage, payload);
        if (Array.isArray(result?.data) && result?.data?.length >= 0) {
          yield put(
            actions.SetClassWithEnrolLoading({
              classWithEnrolLoading: false,
            })
          );
          return yield put(
            actions.setClassesWithEnrolPercentage({
              classesWithEnrolPercentage: result.data,
            })
          );
        }
      } catch (error) {
        yield put(
          actions.SetClassWithEnrolLoading({
            classWithEnrolLoading: false,
          })
        );
      }
    }
  );

  yield takeLatest(
    actionTypes.GetSearchedResults,
    function* getListOfSearchedResults(payload) {
      yield put(
        actions.loading({
          loading: true,
        })
      );
      try {
        const result = yield call(getSearchedClasses, payload);
        yield put(
          actions.loading({
            loading: false,
          })
        );

        return yield put(
          actions.setState({
            list: result.data.results,
            count: result.data.count,
          })
        );
      } catch (error) {
        yield put(
          actions.loading({
            loading: false,
          })
        );
      }
    }
  );

  yield takeLatest(actionTypes.GetClassesByService, function* getListByService(
    payload
  ) {
    yield put(
      actions.GetByServiceLoading({
        loading: true,
      })
    );
    try {
      const result = yield call(getClassesByService, payload);
      yield put(
        actions.GetByServiceLoading({
          loading: false,
        })
      );
      return yield put(
        actions.SetClassesByService({
          classesByService: result.data,
        })
      );
    } catch (error) {
      yield put(
        actions.GetByServiceLoading({
          loading: false,
        })
      );
    }
  });

  yield takeLatest(actionTypes.GetClassesByServiceEnrolment, function* getListByServiceEnrolment(
    payload
  ) {
    yield put(
      actions.GetByServiceEnrolmentLoading({
        loading: true,
      })
    );
    try {
      const result = yield call(getClassesByServiceEnrolment, payload);
      yield put(
        actions.GetByServiceEnrolmentLoading({
          loading: false,
        })
      );
      return yield put(
        actions.SetClassesByServiceEnrolment({
          classesByServiceEnrolment: result.data,
        })
      );
    } catch (error) {
      yield put(
        actions.GetByServiceEnrolmentLoading({
          loading: false,
        })
      );
    }
  });

  yield takeLatest(actionTypes.GetLessonsByClassesEnrolment, function* getLessonsClassesEnrolment(
    payload
  ) {
    yield put(
      actions.GetByLessonsEnrolmentLoading({
        loading: true,
      })
    );
    try {
      const result = yield call(getLessonsByClassesEnrolment, payload);
      yield put(
        actions.GetByLessonsEnrolmentLoading({
          loading: false,
        })
      );
      return yield put(
        actions.SetLessonsByClassesEnrolment({
          lessonByClassesEnrolment: result.data,
        })
      );
    } catch (error) {
      yield put(
        actions.GetByLessonsEnrolmentLoading({
          loading: false,
        })
      );
    }
  });

  yield takeLatest(actionTypes.GetClassesByServiceForCommunications, function* getListByServiceForCommunications(
    payload
  ) {
    yield put(
      actions.GetByServiceLoadingForCommunications({
        loading: true,
      })
    );
    try {
      const result = yield call(getClassesByServiceForCommunications, payload);
      yield put(
        actions.GetByServiceLoadingForCommunications({
          loading: false,
        })
      );
      return yield put(
        actions.SetClassesByServiceForCommunications({
          classesByServiceForCommunications: result.data,
        })
      );
    } catch (error) {
      yield put(
        actions.GetByServiceLoadingForCommunications({
          loading: false,
        })
      );
    }
  });

  yield takeLatest(actionTypes.GetSelected, function* getSelected(payload) {
    try {
      yield put(
        actions.GetLoadingSelected({
          loading: true,
        })
      );
      const result = yield call(getOne, payload);
 
       yield put(
        actions.SetSelected({
          classesByLocation: [result?.data],
          selected: result?.data,
        })
      );
      yield put(
        actions.GetLoadingSelected({
          loading: false,
        })
      );
    } catch (error) {
            yield put(
        actions.GetLoadingSelected({
          loading: false,
        })
      );
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.resetState, function* resetState() {
    try {
      yield put(
        actions.SetSelected({
          selected: {},
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.DuplicateRecord, function* duplicateSingleRecord(
    payload
  ) {
    try {
      const temp = yield call(duplicateRecord, payload);

      const result = yield call(getAll, payload);

      toast.success("Duplicated successfully!");

      return yield put(
        actions.setState({
          list: result.data,
        })
      );
    } catch (error) { }
  });

  yield takeLatest(actionTypes.ExportCsvFile, function* exportCsvFileItem(
    payload
  ) {
    yield put(
      actions.SetLoading({
        loading: true,
      })
    );
    try {
      const result = yield call(exportCSVFile, payload);

      const renderDays = (days) => {
        let daysString = "";
        if (Array.isArray(days)) {
          for (let day of days) {
            if (daysString.length === 0) {
              daysString += day;
            } else {
              daysString += ", " + day;
            }
          }
        }
        return daysString;
      };

      const renderInstructors = (instrs) => {
        let additionalInstructor = "";
        if (Array.isArray(instrs)) {
          for (let instr of instrs) {
            if (additionalInstructor.length === 0) {
              additionalInstructor += instr?.firstName + " " + instr?.lastName;
            } else {
              additionalInstructor +=
                ", " + instr?.firstName + " " + instr?.lastName;
            }
          }
        }

        return additionalInstructor;
      };

      const modifiedResult = Array.isArray(result?.data)
        ? result?.data?.map((cls) => ({
          ...cls,
          location: cls?.venue ? cls?.venue?.name : cls?.location?.name,
          days: renderDays(cls?.days),
          primaryCoach: cls?.primaryInstructor
            ? cls?.primaryInstructor?.firstName +
            " " +
            cls?.primaryInstructor?.lastName
            : "",
          additionalCoaches: renderInstructors(cls?.additionalInstructors),
        }))
        : [];

      console.log({ modifiedResult });
      yield put(
        actions.SetCsvData({
          csvData: modifiedResult,
        })
      );

      yield put(
        actions.SetLoading({
          loading: false,
        })
      );

      // return window.open(result.data.results);
    } catch (error) {
      console.log(error);
      yield put(
        actions.SetLoading({
          loading: false,
        })
      );
    }
  });

  yield takeLatest(
    actionTypes.GetClassesByLocation,
    function* getAllClassesByLocations(payload) {
      yield put(
        actions.GetClassesByLocationLoading({
          loading: true,
        })
      );
      try {
        const result = yield call(getAllClassesByLocation, payload.payload);
        yield put(
          actions.GetClassesByLocationLoading({
            loading: false,
          })
        );
        return yield put(
          actions.setState({
            classesByLocation: result.data,
          })
        );
      } catch (error) {
        yield put(
          actions.GetClassesByLocationLoading({
            loading: false,
          })
        );
        console.log(error.response);
      }
    }
  );

  yield takeLatest(
    actionTypes.GetLessonsByClass,
    function* GetTheLessonsByClass(payload) {
      try {
        const result = yield call(getAllLessonsByClass, payload.payload);

        return yield put(
          actions.SetLessonsByClass({
            lessonsByClass: result.data.results,
          })
        );
      } catch (error) {
        console.log(error.response);
      }
    }
  );

  yield takeLatest(
    actionTypes.GetClassesPrivate,
    function* getClassesByLocation(payload) {
      try {
        const result = yield call(getClassesByLocationPrivate, payload.payload);

        return yield put(
          actions.setState({
            classesByLocation: result.data,
          })
        );
      } catch (error) {
        console.log(error.response);
      }
    }
  );

  yield takeLatest(
    actionTypes.GetClassesByOrgIdAndLocation,
    function* fetchClassesByOrgIdAndLocation({ payload }) {
      try {
        const result = yield call(getByOrgIdAndLocation, payload);

        return yield put(
          actions.SetClassesByOrgIdAndLocation({
            classesByLocationAndOrgId: result.data,
          })
        );
      } catch (error) {
        return yield put(
          actions.SetClassesByOrgIdAndLocation({
            classesByLocationAndOrgId: [],
          })
        );
        console.log(error.response);
      }
    }
  );

  yield takeLatest(actionTypes.SendCSVFile, function* sendCSVFileInput(
    payload
  ) {
    try {
      const result = yield call(sendCSVFile, payload.payload);
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.CreateClasses, function* createClasses(payload) {
    try {
      const result = yield call(createClass, payload.payload);
      toast.success(result.data.message);
      return yield put(
        actions.setState({
          groups: result.data,
          meta: null,
        })
      );
    } catch (error) {
      toast.error(error.response.data.error);
      return yield put(
        actions.setState({
          meta: {
            list: {
              loading: false,
            },
          },
        })
      );
    }
  });

  yield takeLatest(
    actionTypes.GetAllClassesByActiveEnrolments,
    function* getAllClassesByActiveEnrolment({ payload }) {
      //getAllClassesByActiveEnrolments
      yield put(
        actions.SetAllClassesByActiveEnrolmentsLoading({
          AllClassesByActiveEnrolmentsLoading: true,
        })
      );
      yield put(
        actions.setAllClassesByActiveEnrolments({
          AllClassesByActiveEnrolments: [],
        })
      );
      try {
        const result = yield call(getAllClassesByActiveEnrolments, payload);
        if (Array.isArray(result?.data) && result?.data?.length >= 0) {
          yield put(
            actions.SetAllClassesByActiveEnrolmentsLoading({
              AllClassesByActiveEnrolmentsLoading: false,
            })
          );
          return yield put(
            actions.setAllClassesByActiveEnrolments({
              AllClassesByActiveEnrolments: result.data,
            })
          );
        }
      } catch (error) {
        yield put(
          actions.SetAllClassesByActiveEnrolmentsLoading({
            AllClassesByActiveEnrolmentsLoading: false,
          })
        );
      }
    }
  );
}
