export const actionTypes = {
  GetList: "attendance/GET_LIST",
  GetListByLesson: "attendance/GET_LIST_BY_LESSON",
  SetState: "attendance/SET_STATE",
  SetListByLessons: "attendance/SET_LIST_BY_LESSONS",
  CreateAttendance: "attendance/CREATE_ATTENDANCE",
  UpdateAttendance: "attendance/UPDATE_ATTENDANCE",
  SubmitAttendance: "attendance/SUBMIT_ATTENDANCE",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  SetListByLessons: (payload) => ({
    type: actionTypes.SetListByLessons,
    payload,
  }),

  GetListByLesson: (payload) => ({ type: actionTypes.SetState, payload }),
  CreateAttendance: (payload) => ({
    type: actionTypes.CreateAttendance,
    payload,
  }),
  UpdateAttendance: (payload) => ({
    type: actionTypes.UpdateAttendance,
    payload,
  }),
  SubmitAttendance: (payload) => ({
    type: actionTypes.SubmitAttendance,
    payload,
  }),
};
