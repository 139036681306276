import baseApi from "../api/baseApi";

// const authToken = () =>
//   JSON.parse(localStorage.getItem("persist:auth"))
//     ? JSON.parse(localStorage.getItem("persist:auth")).authToken
//     : "";

// baseApi.defaults.headers.common["Authorization"] = authToken()
//   ? JSON.parse(authToken()).token
//   : "";

export function getAll({ payload }) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  console.log(payload);
  if (payload) {
    if (payload?.business && !payload?.pagination?.page) {
      return baseApi.get("/parents/all", {
        params: {
          business: payload?.business,
        },
      });
    } else {
      return baseApi.get("/parents/all", {
        params: {
          business: payload?.business,
          page: payload?.pagination?.page,
          limit: payload?.pagination?.limit,
        },
      });
    }
  } else {
    if (
      !JSON.parse(authToken()).token ||
      JSON.parse(authToken()).token === undefined
    ) {
      baseApi.defaults.headers.common["Authorization"] = payload.auth;
    }
    return baseApi.get("/parents/all");
  }
}

export const searchParent = ({ payload }) => {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get("/parents/parent-search", {
    params: {
      business: payload.business,
      search: payload.search.toString(),
    },
  });
};

export const getCredistReports = () => {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get("/parents/creditReport");
};

export function getParentsToChargeThisMonth(payload) {
  const { page, limit, month, business } = payload;

  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get(`/parents/parentsToCharge`, {
    params: { page, limit, month, business },
  });
}

export function getNextMonthCalculations(payload) {
  const { page, limit, month, business, type } = payload;

  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get(`/parents/get-monthly-calculations`, {
    params: { page, limit, month, business, type },
  });
}

export const getParentsToChargeThisTerm = (payload) => {
  const { page, limit, term, business } = payload;

  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get(`/parents/get-term-payments`, {
    params: { page, limit, term, business },
  });
};

export function getNextTermCalculations(payload) {
  const { term, business } = payload;

  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get(`/parents/get-term-calculations`, {
    params: { term, business },
  });
}

export function addMessage(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.post("/parents/addmessage", payload);
}

export function editMessage(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.post("/parents/editmessage", payload);
}

export function deleteMessage(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.post("/parents/deletemessage", payload);
}

export function exportCSVFile(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.put(`/parents/exportcsvfile/${payload.payload.business}`, payload.payload);
}

export function getSelected(payload) {
  if (payload.id) {
    const authToken = () =>
      JSON.parse(localStorage.getItem("persist:auth"))
        ? JSON.parse(localStorage.getItem("persist:auth")).authToken
        : "";

    baseApi.defaults.headers.common["Authorization"] = authToken()
      ? JSON.parse(authToken()).token
      : "";
    return baseApi.get(`/parents/get/${payload.id}`);
  }
}

export function create(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.post(`/parents/create`, payload);
}

export function deleteParent(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.delete(`/parents/delete/${payload.id}`);
}

export function updateSelected(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.put(`/parents/update/${payload.id}`, payload);
}

export function getAllPaymentMethodsOfParent({ payload }) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.get(`/parents/getAllPaymentMethods`, {
    params: {
      id: payload.parentId,
    },
  });
}

export function getAllGmailMessages({ payload }) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get(`/parents/allGmailMessages/${payload.parentId}`);
}

export function unsubscribeParent({ id }) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get(`/parents/unsubscribe/${id}`);
}
