import { actionTypes } from "../actions/lessons";

const initialLessonState = {
  list: [],
  lessonsByEnrolment: [],
  lessonsByLocation: [],
  lessonsByClass: [],
  selected: {},
  groups: [],
  meta: {},
  upcomingList: [],
  tutorLessons: [],
  todaysLesson: [],
  lessonsByTutorReport: {
    count: 0,
    records: [],
  },
  reportsByLesson: [],
  reportsLoading: false,
  loadingLessons: false,
  tutorCount: 0,
  tutorPreviousCount: 0,
  previousLessons: [],
};

const lessonReducer = (state = initialLessonState, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list, lessonsByLocation, meta } = action.payload;
      return {
        ...state,
        list,
        lessonsByLocation,
        meta: { ...meta },
      };
    }

    case actionTypes.SetAllLessonsByEnrolment : {
      const { lessonsByEnrolment } = action.payload;
      return {
        ...state,
        lessonsByEnrolment
      };
    }

    case actionTypes.SetTutorLessons: {
      const { tutorLessons, count } = action.payload;
      return {
        ...state,
        tutorLessons,
        tutorCount: count,
      };
    }

    case actionTypes.SetPreviousLessons: {
      const { previousLessons, count } = action.payload;
      return {
        ...state,
        previousLessons,
        tutorPreviousCount: count,
      };
    }

    case actionTypes.SetLessonsReportByDate: {
      const { reportsByLesson } = action.payload;
      return {
        ...state,
        reportsByLesson,
      };
    }

    case actionTypes.SetReportsLoading: {
      const { reportsLoading } = action.payload;
      return {
        ...state,
        reportsLoading,
      };
    }

    case actionTypes.SetLoaderLessons: {
      const { loadingLessons } = action.payload;
      return {
        ...state,
        loadingLessons,
      };
    }

    case actionTypes.SetToday: {
      const { todaysLesson } = action.payload;
      return {
        ...state,
        todaysLesson,
      };
    }
    case actionTypes.SetUpcoming: {
      const { upcomingList } = action.payload;
      return {
        ...state,
        upcomingList,
      };
    }
    case actionTypes.SetLessonsByClass: {
      const { lessonsByClass } = action.payload;
      return {
        ...state,
        lessonsByClass,
      };
    }

    case actionTypes.SetLessonsbyTutor: {
      const { lessonsByTutorReport } = action.payload;
      return {
        ...state,
        lessonsByTutorReport,
      };
    }

    case actionTypes.SetSelectedLesson: {
      const { selected } = action.payload;
      return {
        ...state,
        selected,
      };
    }
    default:
      return state;
  }
};

export default lessonReducer;
