import React from "react";
import ParentShop from "./ParentShop";
import ParentShopCheckout from "./ParentShopCheckout";
import { ContentRoute } from "../../../_metronic/layout";
import { Redirect, Switch } from "react-router-dom";
import ThankyouPage from "./ThankyouPage";

const ShopPage = () => {
  return (
    <Switch>
      <ContentRoute
        exact={true}
        path="/:uuid/public-shop/thankyou/:business"
        component={ThankyouPage}
      />
      <ContentRoute
        exact={true}
        path="/:uuid/public-shop/:business"
        component={ParentShop}
      />
      <ContentRoute
        path="/:uuid/public-shop/checkout/:business"
        component={ParentShopCheckout}
      />
      <Redirect to="error/error-v1" />
    </Switch>
  );
};

export default ShopPage;
