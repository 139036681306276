import baseApi from "../api/baseApi";

export function getAll(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.get("/feedback/all", {
    params: {
      limit: payload?.limit,
      page: payload?.page
    }
  });
}

export function getTutorList(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get(`/feedback/tutor/${payload.id}`);
}

export function getParentList(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get(`/feedback/parent/${payload.id}`);
}

export function getSelected(payload) {
  if (payload.id) {
    const authToken = () =>
      JSON.parse(localStorage.getItem("persist:auth"))
        ? JSON.parse(localStorage.getItem("persist:auth")).authToken
        : "";

    baseApi.defaults.headers.common["Authorization"] = authToken()
      ? JSON.parse(authToken()).token
      : "";
    return baseApi.get(`/feedback/get/${payload.id}`);
  }
}

export function create(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.post(`/feedback/create`, payload);
}

export function deleteFeedback(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.delete(`/feedback/delete/${payload.id}`);
}

export function updateSelected(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.put(`/feedback/update/${payload.id}`, payload);
}

export function changeStatus(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.put(`/feedback/changestatus/${payload.id}`, payload);
}
