import _ from "lodash";
import moment from "moment";
import React from "react";

export const getInputClasses = (meta) => {
  let result = "form-control";
  if (meta.touched && meta.error) {
    result += " is-invalid";
  }

  if (meta.touched && !meta.error) {
    result += " is-valid";
  }

  return result;
};

export const GOOGLE_SEARCHABLE_COUNTRY = "au";

export const PAGINATION_PAGE_LIMIT = 15;

export const DATE_FORMAT = "DD/MM/YYYY";

export const FREE_KIT_DATE = "31/03/2022";

export const ENROLMENT_TYPE_OPTIONS = [
  {
    text: "Childcare",
    value: "childcare",
  },
  {
    text: "Weekly",
    value: "weekly",
  },
];

export const CANCELATION_REASON_OPTIONS = [
  { text: "Coach delivery", value: "coach_delivery" },
  { text: "Coach changes", value: "coach_changes" },
  { text: "Customer service", value: "customer_service" },
  { text: "Child No longer intersted", value: "child_no_longer_interested" },
  { text: "Price - too expensive", value: "price_too_expensive" },
  { text: "Cost of living pressures in general", value: "cost_of_living_pressures_in_general" },
  { text: "Not enough other children in the session", value: "not_enough_other_children_in_the_session" },
  { text: "Too many other children in the session", value: "too_many_other_children_in_the_session" },
  { text: "Parking issues", value: "parking_issues" },
  { text: "Location inconvenient for us", value: "location_inconvenient_for_us" },
  { text: "Time of day inconvenient for us", value: "time_of_day_inconvenient_for_us" },
  { text: "Day of week inconvenient for us", value: "day_of_week_inconvenient_for_us" },
];

export const attendanceOptions = [
  { text: "Y", value: "Y" },
  { text: "X", value: "X" },
  // { text: "YM", value: "YM" },
  { text: "NP ", value: "NP" },
  // { text: "XM", value: "XM" },
];

export const businessOptions = [
  {
    text: "Ginger Sport",
    value: "ginger",
  },
  {
    text: "QuestFA",
    value: "quest",
  },
];

export const payInFullText =
  "When you pay in full, you are choosing to pay for the rest of the calendar year. If you pay in full before 30 June, you receive a 10% discount off a Birthday Party & 25% discount off a 1-2-1 session. If you pay in full before 31 March, you also receive free kit (shirt, short, socks).";

export const payInFullWeeklyQuest =
  "When you pay in full, you are choosing to pay for the rest of the calendar year. If you pay in full before 30 June, you receive a 10% discount off a Birthday Party & 25% discount off a 1-2-1 session. ";

export const payMonthlyText =
  "When you choose the PAY MONTHLY option, you are choosing to pay every month. When you register for the first time, we bill you for the remaining sessions in the month. After that, we bill you on the first Sunday of every month. You can cancel at any time.";

export const payTermText = `When you choose the "term" payment option, this means you are choosing to pay for the rest of the sessions in the current term. The number of sessions you are paying for depends on whether you are booking at the start of the term or part way through the term. Ongoing, you will be billed automatically at the beginning of every term, for the next term's fees. If you choose to stop playing, you can cancel at any time, and we will stop billing you from the next term.`;

export const enrolmentBeforeRegisterText =
  "If you are registering part way through the month, you will only be charged for the number of sessions left in the month. After that, you'll be charged monthly on the first Sunday of every month for the number of sessions in that month.  You can cancel at any time.";

export const ENROLMENT_DISCOUNT = process.env.REACT_APP_ENROLMENT_DISCOUNT;

export const GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY;

export const EMBED_CODE = process.env.EMBED_CODE;

export const paymentMethods = [
  { text: "Credit Card", value: "credit" },
  { text: "Direct Debit", value: "direct" },
  // { text: "Bank Transfer", value: "transfer" },
];

export const paymentClassOptions = [
  { text: "Fixed", value: "fixed" },
  { text: "Monthly", value: "monthly" },
  { text: "Term", value: "term" },
];

export const paymentOptions = [
  { text: "Pay in Full", value: "full" },
  { text: "Pay monthly", value: "monthly" },
  // { text: "Pay per term", value: "term" },
];

export const paymentType = [
  { text: "Price per lesson", value: "lesson" },
  { text: "Price per class ", value: "class" },
];

export const merchandiseOptions = [
  { text: "Yes", value: "yes" },
  { text: "No", value: "no" },
];

export const photoVideoPermissionOptions = [
  { text: "Yes", value: "yes" },
  { text: "No", value: "no" },
];

export const sizeOptions = [
  { text: "2", value: "2" },
  { text: "4", value: "4" },
  { text: "6", value: "6" },
  { text: "8", value: "8" },
];

export const genderOptions = [
  { text: "Male", value: "Male" },
  { text: "Female", value: "Female" },
  { text: "Other", value: "Other" },
];

export const enrolmentInitialValues = {
  studentId: "",
  service: "",
  location: "",
  venue: "",
  classId: "",
  paymentOption: "",
  paymentMethod: "credit",
  saveAndProcess: false,
  accountHolderName: "",
  isFree: false,
  sendPaymentLink: false,
  enrolmentDate: moment(new Date()).format("YYYY-MM-DD"),
  size: "",
  quantity: "",
  productId: "",
  coupon: "",
};

export const trialInitialValues = {
  studentId: "",
  service: "",
  lesson: "",
  classId: "",
};

export const parseClassNameByDays = (cl, lessons) => {
  let name = "";
  if ((cl && cl?.repeat === "no") || !cl?.repeat) {
    //name = cl?.lessons && cl?.lessons?.reduce((obj, item) => Object.assign(obj, { ...item }), {}).day;
    name =
      lessons &&
      lessons?.reduce((obj, item) => Object.assign(obj, { ...item }), {}).day;
    if (name === undefined) {
      name = "--";
    }
  } else {
    if (Array.isArray(cl?.days)) {
      for (let day of cl?.days) {
        if (name.length > 0) {
          name += ", " + day;
        } else {
          name += day;
        }
      }
    }
  }

  return `${name}`;
};

export const showOptionsForRecurring = (classes, selectedClasses) => {
  let show = false;
  let options = [];
  if (classes && selectedClasses) {
    for (let cl of classes) {
      for (let selected of selectedClasses) {
        if (cl._id === selected.value) {
          if (cl.payment === "fixed") {
            show = true;
          }
          options = Array.isArray(cl?.payment)
            ? [...cl?.payment]
            : [...options];
        }
      }
    }
  }

  options = options
    .map((option) => {
      if (option === "fixed") {
        return {
          text: "Pay in Full",
          value: "full",
        };
      } else if (option === "monthly") {
        return {
          text: "Pay Monthly",
          value: "monthly",
        };
      } else if (option === "term") {
        return {
          text: "Pay per Term",
          value: "term",
        };
      }
    })
    .filter((data) => data !== undefined);

  if (options.length === 0) {
    options = [
      {
        text: "Pay in Full",
        value: "full",
      },
    ];
  }

  return { show, options };
};

export const parseDiscount = (selectedClasses, classes) => {
  let show = false;
  let discount = 0;
  if (classes && selectedClasses) {
    for (let cl of classes) {
      for (let selected of selectedClasses) {
        if (cl._id === selected.value) {
          if (parseFloat(cl.additional_child_discount) > 0) {
            show = true;
            discount = cl.additional_child_discount;
          }
        }
      }
    }
  }

  return { show, discount };
};

export const showPaymentMethod = (classes, selectedClasses) => {
  let show = false;
  for (let cl of classes) {
    for (let selected of selectedClasses) {
      if (cl._id === selected.value) {
        if (cl.payment === "fixed") {
          show = true;
        }
      }
    }
  }

  return show;
};

export const getTermsOfClasses = (classes, selectedClasses) => {
  let terms = "";
  if (Array.isArray(classes)) {
    for (let cl of classes) {
      for (let selected of selectedClasses) {
        if (selected?.value === cl._id) {
          if (cl?.terms) {
            if (terms.length > 0) {
              terms += " " + cl.terms;
            } else {
              terms += cl.terms;
            }
          }
        }
      }
    }
  }

  return terms;
};

export const getTermsOfSingleClass = (classes, selected) => {
  let terms = "";
  for (let cl of classes) {
    if (selected?.value === cl._id) {
      if (cl?.terms) {
        if (terms.length > 0) {
          terms += " " + cl.terms;
        } else {
          terms += cl.terms;
        }
      }
    }
  }

  return terms;
};

export const getLessonsOfAClass = ({ cls, classes }) => {
  if (classes && cls && Array.isArray(classes)) {
    const today = moment(Date.now()).format(DATE_FORMAT);
    const index = classes?.findIndex((cl) => cl._id === cls);

    const lessons = classes[index]?.lessons?.filter((lesson) =>
      moment(lesson.date, DATE_FORMAT).isAfter(moment(today, DATE_FORMAT))
    );

    return lessons;
  }
};

export const parseEnrolmentFormClassName = (cl) => {
  let name = "";
  if (cl?.repeat === "no" || !cl?.repeat) {
    name = cl?.lessons?.reduce(
      (obj, item) => Object.assign(obj, { ...item }),
      {}
    ).day;
  } else {
    for (let day of cl.days) {
      if (name.length > 0) {
        name += ", " + day;
      } else {
        name += day;
      }
    }
  }

  return cl.name;
};

export const parseClassDays = (cl) => {
  let name = "";
  if ((cl && cl.repeat === "no") || !cl.repeat) {
    name = cl?.lessons?.reduce(
      (obj, item) => Object.assign(obj, { ...item }),
      {}
    ).day;
  } else {
    if (Array.isArray(cl.days)) {
      for (let day of cl.days) {
        if (name.length > 0) {
          name += ", " + day;
        } else {
          name += day;
        }
      }
    }
  }

  return `${cl.name} - ${name}`;
};

export const isFirstSessonIsToday = ({ startTime, enrolmentDate }) => {
  const now = moment(Date.now());

  let concatenatedTime = moment(
    enrolmentDate + " " + startTime,
    DATE_FORMAT + " " + "HH:mm"
  );

  const isAfter = concatenatedTime.isAfter(now);

  return isAfter;
};

export const renderAmount = ({
  classes,
  values,
  coupon,
  products,
  term,
  isQuestWeekly,
  kitMandatory,
}) => {
  let amount = 0;
  let paid = false;
  let totalOrderAmount = 0;
  let fullCapactityClasses = [];
  let showMerchandise = false;
  let isFreeClass = false;

  let studentData = [];

  const enrolmentDate = moment(new Date()).format(DATE_FORMAT);

  if (values?.classId) {
    for (let cl of values?.classId) {
      for (let selected of classes) {
        if (selected.paymentType === "free") {
          isFreeClass = true;
        }

        if (
          (selected._id === cl.value &&
            selected.include_kit &&
            selected.paymentType === "paid" &&
            !selected.capacity) ||
          (selected?._id === cl.value &&
            selected?.include_kit &&
            selected?.paymentType === "paid" &&
            selected?.capacity &&
            parseInt(selected?.capacity) > selected?.students?.length)
        ) {
          showMerchandise = true;
        }

        const lessonsToPay =
          values.paymentOption === "full"
            ? selected?.lessons
              ?.filter((lesson) => !lesson.makeUpLesson)
              ?.filter((lesson) =>
                isFirstSessonIsToday({
                  startTime: lesson.startTime,
                  enrolmentDate,
                })
                  ? moment(lesson.date, DATE_FORMAT).isSameOrAfter(
                    moment(enrolmentDate, DATE_FORMAT)
                  )
                  : moment(lesson.date, DATE_FORMAT).isAfter(
                    moment(enrolmentDate, DATE_FORMAT)
                  )
              )
            : values.paymentOption === "term"
              ? selected?.lessons
                ?.filter((lesson) => !lesson.makeUpLesson)
                ?.filter((lesson) =>
                  isFirstSessonIsToday({
                    startTime: lesson.startTime,
                    enrolmentDate,
                  })
                    ? moment(lesson.date, DATE_FORMAT).isSameOrAfter(
                      moment(enrolmentDate, DATE_FORMAT)
                    )
                    : moment(lesson.date, DATE_FORMAT).isAfter(
                      moment(enrolmentDate, DATE_FORMAT)
                    )
                )
                .filter((lesson) =>
                  moment(lesson?.date, DATE_FORMAT).isSameOrBefore(
                    moment(term?.endDate, DATE_FORMAT)
                  )
                )
              : [];

        if (values.studentIds && values.studentIds.length > 0) {
          for (let child of values.studentIds) {
            const discount = studentData
              .map((data) => data.cls._id)
              .includes(selected._id)
              ? selected.additional_child_discount
              : 0;

            const enrolmentsOfThisClass = selected.students.length;

            const enrolledStudentsThisEnrolment = studentData.filter(
              (student) => student.cls._id === selected._id
            ).length;

            const isClassFull =
              selected.capacity &&
                parseInt(selected.capacity) <=
                enrolmentsOfThisClass + enrolledStudentsThisEnrolment
                ? true
                : false;

            const selectedProduct = selected.product;

            if (cl.value === selected._id) {
              studentData.push({
                selectedProduct,
                child: child.value,
                cls: selected,
                totalAmount: selected.unit_price * lessonsToPay.length,
                additionalChildDiscount: discount,
                isClassFull,
                existingStudent: true,
                orderAmount:
                  parseInt(child.quantity) && parseInt(child.quantity) > 0
                    ? parseFloat(selected?.product?.price) *
                    parseInt(child.quantity)
                    : null,
              });
            }
          }
        }

        for (let child of values.childs) {
          if (
            (Array.isArray(child?.childClassIds) &&
              child?.childClassIds?.length == 0) ||
            !child?.childClassIds
          ) {
            const discount = studentData
              .map((data) => data.cls._id)
              .includes(selected._id)
              ? selected.additional_child_discount
              : 0;

            const enrolmentsOfThisClass = selected?.students?.length;

            const enrolledStudentsThisEnrolment = studentData.filter(
              (student) => student.cls._id === selected._id
            )?.length;

            const isClassFull =
              selected.capacity &&
                parseInt(selected.capacity) <=
                enrolmentsOfThisClass + enrolledStudentsThisEnrolment
                ? true
                : false;

            const selectedProduct = selected.product;

            if (cl.value === selected._id) {
              studentData.push({
                selectedProduct,
                child: child,
                cls: selected,
                totalAmount: selected.unit_price * lessonsToPay.length,
                additionalChildDiscount: discount,
                isClassFull,
                existingStudent: false,
                orderAmount:
                  parseInt(child.quantity) && parseInt(child.quantity) > 0
                    ? parseFloat(products?.price) * parseInt(child.quantity)
                    : null,
              });
            }
          } else if (
            child?.childClassIds &&
            Array.isArray(child?.childClassIds) &&
            child?.childClassIds?.length > 0
          ) {
            const discount = studentData
              .map((data) => data.cls._id)
              .includes(selected._id)
              ? selected.additional_child_discount
              : 0;

            const enrolmentsOfThisClass = selected.students.length;

            const enrolledStudentsThisEnrolment = studentData.filter(
              (student) => student.cls._id === selected._id
            ).length;

            const isClassFull =
              selected.capacity &&
                parseInt(selected.capacity) <=
                enrolmentsOfThisClass + enrolledStudentsThisEnrolment
                ? true
                : false;
            const selectedProduct = selected.product;

            for (let key in child.childClassIds) {
              if (child.childClassIds[key].value === selected._id) {
                studentData.push({
                  selectedProduct,
                  child: child,
                  cls: selected,
                  totalAmount: selected.unit_price * lessonsToPay.length,
                  additionalChildDiscount: discount,
                  isClassFull,
                  existingStudent: false,
                  orderAmount:
                    parseInt(child.quantity) && parseInt(child.quantity) > 0
                      ? parseFloat(products?.price) * parseInt(child.quantity)
                      : null,
                });
              }
            }
          }
        }
      }
    }
  }

  // studentData = [...new Set(studentData)];

  //Finding full Classes
  fullCapactityClasses = studentData
    .filter((data) => data.isClassFull)
    .map((student) => student.cls);

  fullCapactityClasses = [...new Set(fullCapactityClasses)];

  //Applying additional User discount
  studentData = studentData.map((data) => {
    return {
      ...data,
      amountAfterAdditionalDiscount:
        parseFloat(data.additionalChildDiscount) > 0
          ? data.totalAmount *
          (1 - parseFloat(data.additionalChildDiscount).toFixed(2) / 100)
          : data.totalAmount,
    };
  });

  let freeProduct;
  //Show merchandise or not
  for (let student of studentData) {
    //Calculating Amount
    if (student?.cls?.paymentType === "paid" && !student?.isClassFull) {
      paid = true;
      amount += parseFloat(student.amountAfterAdditionalDiscount);
    }

    freeProduct = student.selectedProduct;

    if (
      (values.isQuestWeekly &&
        student.child.attendedQuest === "no" &&
        parseInt(student.child.quantity) > 0) ||
      (!kitMandatory & !values.isQuestWeekly &&
        student.child.merchandise === "yes" &&
        parseInt(student.child.quantity) > 0) ||
      (kitMandatory &&
        !values.isQuestWeekly &&
        student.child.attendedQuest === "no" &&
        parseInt(student.child.quantity) > 0)
    ) {
      totalOrderAmount +=
        parseFloat(student?.selectedProduct?.price) *
        parseInt(student.child.quantity);
    }
  }

  //Applying free kit till 31st of March
  if (
    moment(FREE_KIT_DATE, DATE_FORMAT).isAfter(
      moment(enrolmentDate, DATE_FORMAT)
    ) &&
    values?.paymentOption === "full" &&
    !values.isQuestWeekly &&
    !kitMandatory
  ) {
    totalOrderAmount =
      totalOrderAmount > 0
        ? totalOrderAmount - parseFloat(freeProduct?.price)
        : totalOrderAmount;
  }

  amount = totalOrderAmount > 0 ? amount + totalOrderAmount : amount;

  if (coupon) {
    if (coupon.type === "flat") {
      amount = amount - parseFloat(coupon.amount);
    } else if (coupon.type === "percentage") {
      amount = amount * (1 - parseFloat(coupon.amount) / 100);
    }
  }

  return {
    paid,
    amount: amount,
    fullCapactityClasses,
    showMerchandise,
    isFreeClass,
  };
};

export const totalOrderPriceCalculator = (products, values) => {
  let calculatedAmount = 0;
  const today = moment(new Date());
  if (products?.length > 0) {
    for (let child of values?.childs) {
      if (parseInt(child?.quantity) > 0) {
        calculatedAmount +=
          parseInt(child?.quantity) * parseFloat(products[0]?.price);
      }
    }

    if (
      values?.childs?.some((child) => child.quantity > 0) &&
      moment(FREE_KIT_DATE, DATE_FORMAT).isAfter(today)
    ) {
      calculatedAmount = calculatedAmount - parseFloat(products[0]?.price);
    }
  }

  return isFinite(calculatedAmount) ? calculatedAmount : 0;
};

export const timeFormatToSort = (date) => {
  if (!date) {
    return;
  }
  const time = date.split("/");

  return moment(`${time[1] + "/" + time[0] + "/" + time[2]}`).unix();
};

export const renderPrimaryName = (primaryInstructor, instructors) => {
  if (primaryInstructor) {
    let name = `${primaryInstructor.firstName +
      " " +
      primaryInstructor.lastName}*`;

    return name;
  } else {
    return "";
  }
};

export const renderInstructorsName = (additionalInstructors) => {
  let name = "";
  for (let key in additionalInstructors) {
    if (name.length > 0) {
      name +=
        ", " +
        additionalInstructors[key].firstName +
        " " +
        additionalInstructors[key].lastName;
    } else {
      name +=
        ", " +
        additionalInstructors[key].firstName +
        " " +
        additionalInstructors[key].lastName;
    }
  }

  return name;
};

export const sortLessonsAccToDate = (classes, instructors) => {
  return (
    classes &&
    classes
      .sort((b, a) => {
        let firstTime = timeFormatToSort(a.to);
        let secondTime = timeFormatToSort(b.to);
        firstTime = !isNaN(firstTime) ? firstTime : "";
        secondTime = !isNaN(secondTime) ? secondTime : "";

        return firstTime - secondTime;
      })
      .map((cl) => {
        return {
          includeKit: cl.include_kit,
          additionalChildDiscount: cl.additional_child_discount,
          commonId: cl._id,
          service: cl.service,
          business: cl.business,
          capacity: cl.capacity,
          venueName: cl.venue && cl.venue.name,
          locationName: cl.location && cl.location.name,
          venue: cl.venue,
          filterLocationOption:
            cl.type === "childcare" ? cl?.venue?.name : cl?.location?.name,
          _id: cl._id,
          name: cl.name,
          startTime: cl.startTime,
          endTime: cl.endTime,
          location: cl.location,
          program: cl.program,
          typeClass: true,
          repeat: cl.repeat,
          payment: cl.payment,
          level1: cl.level,
          type: cl.type,
          primaryInstructor: cl.primaryInstructor,
          additionalInstructors: cl.additionalInstructors,
          days: cl.days,
          paymentType: cl.paymentType,
          terms: cl.terms,
          tag:
            typeof cl?.tag === "object"
              ? JSON.stringify(
                cl?.tag?.map((tag) => ({
                  value: tag,
                }))
              )
              : [],
          instructors:
            renderPrimaryName(cl.primaryInstructor, instructors) +
            renderInstructorsName(cl.additionalInstructors),
          frequency: cl.frequency,
          paymentDuration: cl.paymentDuration,
          unit_price: cl.unit_price,
          from: cl.from,
          to: cl.to,
          totalEnrolments: cl.totalEnrolments,
          totalCancelPendingEnrolments: cl.totalCancelPendingEnrolments,
          kitMandatory: cl.kit_mandatory,
          product: cl.product,
          ignorePublicHoliday: cl.ignorePublicHoliday,
          allIndividualDateBookings: cl.allow_individual_date_bookings,
          makeUpLessonCount: cl.makeUpLessonCount,
          waitingCount: cl.waitingCount,
          trials: cl.trials,
          totalTrials: cl.totalTrials,
          ageGroup: cl?.ageGroup?.map((age) => ({
            value: age?._id,
            label: age?.name,
          })),
          status: cl?.status,
          freeTrialsDisabled: cl?.freeTrialsDisabled,
          admin_fee: cl?.admin_fee,
        };
      })
  );
};

export const parseLessons = (classes) => {
  return (
    classes &&
    classes
      .map((cls) =>
        cls.lessons.map((lesson) => {
          let files = { exists: false };
          if (
            cls.venue &&
            cls.venue.attachments &&
            cls.venue.attachments.length > 0
          ) {
            files = {
              exists: true,
              attachments: cls.venue.attachments,
            };
          } else if (
            cls.location &&
            cls.location.attachments &&
            cls.location.attachments.length > 0
          ) {
            files = {
              exists: true,
              attachments: cls.location.attachments,
            };
          }

          return {
            ...lesson,
            name: cls.name,
            classId: cls._id,
            locationName: cls.location && cls.location.name,
            start: cls.startTime,
            end: cls.endTime,
            startTime: moment(
              lesson.date + " " + lesson.startTime,
              "DD-MM-YYYY HH:mm"
            ).format("LLLL"),
            endTime: moment(
              lesson.date + " " + lesson.endTime,
              "DD-MM-YYYY HH:mm"
            ).format("LLLL"),
            venue: cls.venue ? cls.venue : null,
            location: cls.location ? cls.location : null,
            locationName: cls.venue
              ? cls.venue.name
              : cls.location
                ? cls.location.name
                : "",
            file: files,
          };
        })
      )
      .flat()
  );
};

export const getClassProduct = ({ classes, classId }) => {
  if (Array.isArray(classId) && classId && Array.isArray(classes)) {
    let product = null;
    let kitMandatory = false;
    for (let cls of classes) {
      if (classId.includes(cls._id)) {
        if (cls?.product?._id) {
          product = cls?.product;
          kitMandatory = cls?.kit_mandatory;
        }
      }
    }

    return { ...product, kitMandatory };
  }
};

export const getChildProduct = ({ classes, childs }) => {
  let kitMandatory = false;
  let product = null;
  let childIndex = null;
  for (let index in childs) {
    if (
      Array.isArray(childs[index].childClassIds) &&
      childs[index].childClassIds.length > 0
    ) {
      for (let clsObject of childs[index].childClassIds) {
        for (let cls of classes) {
          if (clsObject.value === cls._id) {
            product = cls.product;
            kitMandatory = cls.kit_mandatory;
            childIndex = parseInt(index);
          }
        }
      }
    }
  }

  return { ...product, kitMandatory, childIndex };
};

export const parseClassName = (cl, margin) => {
  let name = "";
  if (cl?.repeat === "no" || !cl?.repeat) {
    name = cl?.lessons?.reduce(
      (obj, item) => Object.assign(obj, { ...item }),
      {}
    ).day;
  } else {
    for (let day of cl.days) {
      if (name?.length > 0) {
        name += ", " + day;
      } else {
        name += day;
      }
    }
  }

  return (
    <React.Fragment>
      <p>{name}</p>
      {/* {margin && <br />} */}
      <p className={margin && "mt-4"}>
        {cl?.startTime} - {cl?.endTime}
      </p>
    </React.Fragment>
  );
};

//for Enrolment Page 
export const parseClassNameForEnrolment = (cl, margin) => {
  let name = "";
    for (let day of cl.days) {
      if (name?.length > 0) {
        name += ", " + day;
      } else {
        name += day;
      }
    }

  return (
    <React.Fragment>
      <p>{name}</p>
      {/* {margin && <br />} */}
      <p className={margin && "mt-4"}>
        {cl?.startTime} - {cl?.endTime}
      </p>
    </React.Fragment>
  );
};


export const checkCookie = () => {
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

export const parseSessionsDates = (classId, classes) => {
  let name = "";
  // if (cl.repeat === "no" || !cl.repeat) {
  //   name = cl.lessons.reduce((obj, item) => Object.assign(obj, { ...item }), {})
  //     .day;
  // } else {
  //   for (let day of cl.days) {
  //     if (name.length > 0) {
  //       name += ", " + day;
  //     } else {
  //       name += day;
  //     }
  //   }
  // }
};

export const getSuburbs = (locations) => {
  const options = locations
    ?.map((location) =>
      location.suburb
        ? {
          suburb: location.suburb,
          name: location.suburb,
          _id: location.suburb,
          address: location.address,
          postcode: location.postcode,
        }
        : null
    )
    ?.filter((location) => location !== null);

  const uniques = _.uniqBy(options, "suburb");

  const sorted = _.sortBy(uniques, ({ suburb }) => suburb.toLowerCase());

  return sorted;
};

export const getLocationValue = (location, locations) => {
  if (Array.isArray(locations) && locations.length > 0) {
    const index = locations.findIndex((row) => row._id === location);

    const selectedLocation = locations[index];
    const selectedOption = {
      suburb: selectedLocation.suburb,
      name: selectedLocation.name,
      address: selectedLocation.address,
      postcode: selectedLocation.postcode,
      _id: selectedLocation._id,
    };

    return selectedLocation;
  }
};

export const hearAboutUsOptions = [
  {
    text: "Facebook/Instagram",
    value: "facebook/instagram",
  },
  {
    text: "Newsletter",
    value: "newsletter",
  },
  {
    text: "Emails",
    value: "emails",
  },
  {
    text: "Word of Mouth",
    value: "word of mouth",
  },
  {
    text: "Web Search (Google)",
    value: "web search",
  },
  {
    text: "Referral",
    value: "referral",
  },
  {
    text: "Other",
    value: "other",
  },
];
