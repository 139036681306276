import {
  addAge,
  getAll,
  deleteAge,
  getSelected,
  updateSelected,
} from '../../services/age'
import { actionTypes, actions } from '../actions/age'
import { put, takeLatest, call } from 'redux-saga/effects'
import { toast } from '../../app/components/Toastr'

export default function* saga() {
  yield takeLatest(actionTypes.GetAgeList, function* getList() {
    try {
      const result = yield call(getAll)
      return yield put({
        type: actionTypes.SetAgeList,
        payload: {
          age: result.data,
        },
      })
    } catch (error) {
      console.log(error)
    }
  })

  yield takeLatest(actionTypes.CreateAge, function* CreateAge(payload) {
    try {
      const result = yield call(addAge, payload.payload.values)
      yield put({
        type: actionTypes.GetAgeList,
      })

      return toast.success(result.data.message)
    } catch (error) {
      toast.error(error.response.data.error)
    }
  })

  yield takeLatest(actionTypes.DeleteAge, function* DeleteAge(payload) {
    try {
      const result = yield call(deleteAge, payload.payload)
      yield put({
        type: actionTypes.GetAgeList,
      })
      toast.success('Age deleted successfully!')
    } catch (error) {
      toast.error(error.response.data.error)
    }
  })

  yield takeLatest(actionTypes.GetSelectedAge, function* GetSelected(payload) {
    try {
      const result = yield call(getSelected, payload.payload)
      return yield put({
        type: actionTypes.SetSelectedAge,
        payload: {
          selected: result.data,
        },
      })
    } catch (error) {
      toast.error(error.response.data.error)
    }
  })

  yield takeLatest(actionTypes.UpdateAge, function* updateSelectedage(payload) {
    try {
      const result = yield call(updateSelected, payload.payload)
      yield put({
        type: actionTypes.GetAgeList,
      })
      toast.success(result.data.message)
    } catch (error) {
      toast.error(error.response.data.error)
    }
  })
}
