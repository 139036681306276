import { getAll, getAllAvailabilities, getAllEventExtra, getAllInflatableItems } from "../../services/bookingSettings";
import { actionTypes, actions } from "../actions/bookingSettings";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {

  yield takeLatest(actionTypes.GetInflatableItems, function* getInflatableItems() {
    yield put(
      actions.setInflatableItemsLoading({
        inflatableItemsLoading: true,
      })
    );
    try {
      const result = yield call(getAllInflatableItems);
      yield put(
        actions.setInflatableItemsLoading({
          inflatableItemsLoading: false,
        })
      );
      return yield put(
        actions.setInflatableItems({
          inflatableItems: result.data,
        })
      );
    } catch (error) {
      yield put(
        actions.setInflatableItemsLoading({
          inflatableItemsLoading: false,
        })
      );
      actions.setInflatableItems({
        inflatableItems: [],
      });
      console.log(error);
      toast.error("Something went wrong. Please try after some time.");
    }
  });


  yield takeLatest(actionTypes.GetEventExtra, function* getEventExtra() {
    yield put(
      actions.setEventExtraLoading({
        EventExtraLoading: true,
      })
    );
    try {
      const result = yield call(getAllEventExtra);
      yield put(
        actions.setEventExtraLoading({
          EventExtraLoading: false,
        })
      );
      return yield put(
        actions.setEventExtra({
          eventExtra: result.data,
        })
      );
    } catch (error) {
      yield put(
        actions.setEventExtraLoading({
          EventExtraLoading: false,
        })
      );
      actions.setEventExtra({
        eventExtra: [],
      });
      console.log(error);
      toast.error("Something went wrong. Please try after some time.");
    }
  });

  yield takeLatest(actionTypes.GetList, function* getList() {
    yield put(
      actions.setListLoading({
        listLoading: true,
      })
    );
    try {
      const result = yield call(getAll);
      yield put(
        actions.setListLoading({
          listLoading: false,
        })
      );
      return yield put(
        actions.setState({
          list: result.data,
        })
      );
    } catch (error) {
      yield put(
        actions.setListLoading({
          listLoading: false,
        })
      );
      actions.setState({
        list: [],
      });
      console.log(error);
      toast.error("Something went wrong. Please try after some time.");
    }
  });

  yield takeLatest(
    actionTypes.GetListAvailabilities,
    function* fetchAllAvailabilities() {
      yield put(
        actions.setAvailabilitiesLoading({
          availabilitiesLoading: true,
        })
      );
      try {
        const result = yield call(getAllAvailabilities);
        yield put(
          actions.setAvailabilitiesLoading({
            availabilitiesLoading: false,
          })
        );
        return yield put(
          actions.setAvailabilities({
            availabilities: result.data,
          })
        );
      } catch (error) {
        yield put(
          actions.setAvailabilitiesLoading({
            availabilitiesLoading: false,
          })
        );
        actions.setAvailabilities({
          availabilities: [],
        });
        console.log(error);
        toast.error("Something went wrong. Please try after some time.");
      }
    }
  );
}
