export const actionTypes = {
  GetAssignFortutor: "booking/GET_ASSIGN_ONE_TO_ONE_FOR_TUTER",
  SetAssignFortutor: "booking/SET_ASSIGN_ONE_TO_ONE_FOR_TUTER",
  GetList: "booking/GET_LIST",
  GetGroupList: "booking/GET_GROUP_LIST",
  SetState: "booking/SET_STATE",
  CreateBooking: "booking/CREATE_BOOKING",
  DeleteBooking: "booking/DELETE_BOOKING",
  GetSelectedBooking: "booking/GET_SELECTED_BOOKING",
  SetSelectedBooking: "booking/SET_SELECTED_BOOKING",
  GetCancelledList: "booking/GET_CANCELLED_LIST",
  SetCancelledList: "booking/SET_CANCELLED_LIST",
  GetPastBooking: "booking/GET_PAST_LIST",
  SetPastBooking: "booking/SET_PAST_LIST",
  GetScheduleBooking: "booking/GET_SCHEDULE_LIST",
  SetScheduleBooking: "booking/SET_SCHEDULE_LIST",
  GetFutureBooking: "booking/GET_UPCOMING_LIST",
  SetFutureBooking: "booking/SET_UPCOMING_LIST",
  UpdateBooking: "booking/UPDATE_BOOKING",
  AddMessage: "booking/ADD_MESSAGE",
  EditMessage: "booking/EDIT_MESSAGE",
  DeleteMessage: "booking/DELETE_MESSAGE",
  GetCountsOfAllBookings: "booking/GET_COUNTS_OF_ALL_BOOKINGS",
  SetCounts: "booking/SET_COUNTS",
  LoadingCreateBooking: "booking/LOADING_CREATE_BOOKING",
};

export const actions = {
  setAssignFortutor: (payload) => ({ type: actionTypes.SetAssignFortutor, payload }),
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  LoadingCreateBooking: (payload) => ({
    type: actionTypes.LoadingCreateBooking,
    payload,
  }),
  SetCounts: (payload) => ({ type: actionTypes.SetCounts, payload }),
  CreateBooking: (payload) => ({
    type: actionTypes.CreateBooking,
    payload,
  }),
  UpdateBooking: (payload) => ({
    type: actionTypes.UpdateBooking,
    payload,
  }),
  GetSelectedBooking: (payload) => ({
    type: actionTypes.GetSelectedBooking,
    payload,
  }),
  SetSelectedBooking: (payload) => ({
    type: actionTypes.SetSelectedBooking,
    payload,
  }),
  GetAssignFortutor: (payload) => ({
    type: actionTypes.GetAssignFortutor,
    payload,
  }),
  GetList: (payload) => ({
    type: actionTypes.GetList,
    payload,
  }),

  GetCancelledList: (payload) => ({
    type: actionTypes.GetCancelledList,
    payload,
  }),
  SetCancelledList: (payload) => ({
    type: actionTypes.SetCancelledList,
    payload,
  }),
  GetPastBooking: (payload) => ({
    type: actionTypes.GetPastBooking,
    payload,
  }),
  SetPastBooking: (payload) => ({
    type: actionTypes.SetPastBooking,
    payload,
  }),
  GetFutureBooking: (payload) => ({
    type: actionTypes.GetFutureBooking,
    payload,
  }),
  SetFutureBooking: (payload) => ({
    type: actionTypes.SetFutureBooking,
    payload,
  }),

  GetScheduleBooking: (payload) => ({
    type: actionTypes.GetScheduleBooking,
    payload,
  }),
  SetScheduleBooking: (payload) => ({
    type: actionTypes.SetScheduleBooking,
    payload,
  }),
  AddMessage: (payload) => ({
    type: actionTypes.AddMessage,
    payload,
  }),
  EditMessage: (payload) => ({
    type: actionTypes.EditMessage,
    payload,
  }),
  DeleteMessage: (payload) => ({
    type: actionTypes.DeleteMessage,
    payload,
  }),
};
