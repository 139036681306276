import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CreateCardShop from "../CardForm/CardFormShop";

const StepTwo = ({
  formWizard,
  setting,
  dispatch,
  parent,
  setStripeToken,
  publicSetting,
  match,
}) => {
  useEffect(() => {
    if (parent?._id) {
      dispatch({
        type: "settings/GET_SIMPLE_PUBLIC_SETTING",
        payload: {
          organization: parent?.organization,
          business: parent?.business,
        },
      });
    }
  }, [parent]);

  const submitHandler = (values) => {
    // console.log(values);
    setStripeToken(values.source);
    formWizard.goNext();
  };

  return (
    <form
      className="form fv-plugins-bootstrap fv-plugins-framework"
      id="kt_form"
      onSubmit={(e) => {
        e.preventDefault();
        formWizard.goNext();
      }}
    >
      <h4 className="mb-10 font-weight-bold text-dark">
        Enter your Payment Details
      </h4>
      <div className="row">
        {match?.params?.uuid
          ? publicSetting &&
            publicSetting.stripe_publishable_key && (
              <Elements
                stripe={loadStripe(
                  publicSetting.stripe_publishable_key &&
                    publicSetting.stripe_publishable_key
                )}
              >
                <CreateCardShop
                  getCardSource={submitHandler}
                  // loading={spinner}
                  // onCancel={() => setCreateShow(false)}
                  stripePublishableKey={publicSetting?.stripe_publishable_key}
                  parent={parent}
                  dispatch={dispatch}
                  formWizard={formWizard}
                />
              </Elements>
            )
          : setting &&
            setting.stripe_publishable_key && (
              <Elements
                stripe={loadStripe(
                  setting.stripe_publishable_key &&
                    setting.stripe_publishable_key
                )}
              >
                <CreateCardShop
                  getCardSource={submitHandler}
                  // loading={spinner}
                  // onCancel={() => setCreateShow(false)}
                  stripePublishableKey={setting?.stripe_publishable_key}
                  parent={parent}
                  dispatch={dispatch}
                  formWizard={formWizard}
                />
              </Elements>
            )}
      </div>
    </form>
  );
};

const matchStateToProps = (state) => ({
  parent: state.auth.user,
  paymentMethods: state.parents.paymentMethods,
  setting: state.settings.gingerSetting,
  publicSetting: state.settings.publicSetting,
});

export default connect(matchStateToProps)(StepTwo);
