import { getCurrent, getAll, getOne } from "../../services/terms";
import { actionTypes, actions } from "../actions/term";
import { put, takeLatest, call } from "redux-saga/effects";

export default function* saga() {
  yield takeLatest(actionTypes.GetCurrent, function* getCurrentTerm() {
    try {
      const result = yield call(getCurrent);
      console.log(result?.data);
      return yield put(
        actions.setCurrent({
          current: result?.data,
        })
      );
    } catch (error) {
      console.log("error is ", error);
    }
  });

  yield takeLatest(actionTypes.GetList, function* getAllTerms() {
    try {
      const result = yield call(getAll);
      return yield put(
        actions.setState({
          list: result.data,
        })
      );
    } catch (error) {
      console.log("error is ", error);
    }
  });

  yield takeLatest(actionTypes.GetSelected, function* getSElectedTerm({
    payload,
  }) {
    try {
      const result = yield call(getOne, payload);
      return yield put(
        actions.setState({
          selected: result.data,
        })
      );
    } catch (error) {
      console.log("error is ", error);
    }
  });
}
