export const actionTypes = {
  GetList: "list/GET_LIST",
  SetState: "list/SET_STATE",
  CreateList: "list/CREATE_LIST",
  UpdateList: "list/UPDATE_LIST",
  DeleteList: "list/DELETE_LIST",
  GetSelectedList: "list/GET_SELECTED_LIST",
  SetLoading: "list/SET_LOADING",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  SetLoading: (payload) => ({ type: actionTypes.SetLoading, payload }),
  createLevel: (payload) => ({ type: actionTypes.CreateList, payload }),
  updateLevel: (payload) => ({ type: actionTypes.UpdateList, payload }),
  deleteLevel: (payload) => ({ type: actionTypes.DeleteList, payload }),
  getSelectedLevel: (payload) => ({
    type: actionTypes.GetSelectedList,
    payload,
  }),
};
