export const actionTypes = {
  GetList: "classes/GET_LIST",
  GetClassesByServiceForCommunications: "classes/GET_CLASSES_BY_SERVICE_FOR_COMMUNICATIONS",
  SetClassesByServiceForCommunications: "classes/SET_CLASSES_BY_SERVICE_FOR_COMMUNICATIONS",
  GetClassesByService: "classes/GET_CLASSES_BY_SERVICE",
  SetClassesByService: "classes/SET_CLASSES_BY_SERVICE",
  GetClassesByServiceEnrolment: "classes/GET_CLASSES_BY_SERVICE_ENROLMENT",
  SetClassesByServiceEnrolment: "classes/SET_CLASSES_BY_SERVICE_ENROLMENT",
  GetLessonsByClassesEnrolment: "classes/GET_LESSONS_BY_CLASS_ENROLMENT",
  SetLessonsByClassesEnrolment: "classes/SET_LESSONS_BY_CLASS_ENROLMENT",
  GetGroupList: "classes/GET_GROUP_LIST",
  SetState: "classes/SET_STATE",
  SetLoading: "classes/SET_LOADING",
  setClassesPrivateState: "classes/SET_CLASSES_PRIVATE_STATE",
  GetClassesPrivate: "classes/GET_CLASSES_PRIVATE",
  CreateClasses: "classes/CREATE_CLASS",
  UpdateClasses: "classes/UPDATE_CLASS",
  GetClassesByLocation: "classes/GET_CLASSES_BY_LOCATION",
  GetClassesByLocationLoading: "classes/GET_CLASSES_BY_LOCATION_LOADING",
  GetLessonsByClass: "classes/GET_LESSONS_BY_CLASS",
  SetLessonsByClass: "classes/SET_LESSONS_BY_CLASS",
  GetSelected: "classes/GET_SELECTED",
  SendCSVFile: "classes/SEND_CSV_FILE",
  ExportCsvFile: "classes/EXPORT_CSV_FILE",
  DuplicateRecord: "classes/DUPLICATE_RECORD",
  GetSessionRevenueClass: "classes/GET_SESSION_REVENUE_BY_CLASS",
  SetSessionRevenueClass: "classes/SET_SESSION_REVENUE_BY_CLASS",
  GetSearchedResults: "classes/GET_SEARCHED_RESULTS",
  Loading: "classes/LOADING",
  GetClassesWithEnrolPercentage: "classes/GET_CLASSES_WITH_ENROL_PERCENTAGE",
  SetClassesWithEnrolPercentage: "classes/SET_CLASSES_WITH_ENROL_PERCENTAGE",
  SetClassWithEnrolLoading: "classes/SET_CLASS_WITH_ENROL_LOADING",
  SetCsvData: "classes/SET_CSV_DATA",
  GetByServiceLoading: "classes/GET_BY_SERVICE_LOADING",
  GetByServiceEnrolmentLoading: "classes/GET_BY_SERVICE_ENROLMENT_LOADING",
  GetByLessonsEnrolmentLoading: "classes/GET_BY_LESSONS_ENROLMENT_LOADING",
  GetByServiceLoadingForCommunications: "classes/GET_BY_SERVICE_LOADING_FOR_COMMUNICATIONS",
  GetClassesByOrgIdAndLocation: "classes/CLASSES_BY_LOCATION_AND_ORG_ID",
  SetClassesByOrgIdAndLocation: "classes/SET_CLASSES_BY_LOCATION_AND_ORG_ID",
  GetAllClassesByActiveEnrolments: "classes/GET_ALL_CLASSES_BY_ACTIVE_ENROLMENT",
  SetAllClassesByActiveEnrolments: "classes/SET_ALL_CLASSES_BY_ACTIVE_ENROLMENT",
  SetAllClassesByActiveEnrolmentsLoading: "classes/SET_ALL_CLASSES_BY_ACTIVE_ENROLMENT_LOADING",

  SetSelected: "classes/SET__SELECTED",
  resetState: "classes/RESET_STATE",
  GetLoadingSelected: "classes/GET_LOADING_FOR_SELECTED_CLASS"
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  GetClassesByLocationLoading: (payload) => ({
    type: actionTypes.GetClassesByLocationLoading,
    payload,
  }),
  SetClassesByOrgIdAndLocation: (payload) => ({
    type: actionTypes.SetClassesByOrgIdAndLocation,
    payload,
  }),
  GetByServiceLoading: (payload) => ({
    type: actionTypes.GetByServiceLoading,
    payload,
  }),

  GetByServiceEnrolmentLoading: (payload) => ({
    type: actionTypes.GetByServiceEnrolmentLoading,
    payload,
  }),

  GetByLessonsEnrolmentLoading: (payload) => ({
    type: actionTypes.GetByLessonsEnrolmentLoading,
    payload,
  }),

  GetByServiceLoadingForCommunications: (payload) => ({
    type: actionTypes.GetByServiceLoadingForCommunications,
    payload,
  }),
  GetLoadingSelected: (payload) => ({
    type: actionTypes.GetLoadingSelected,
    payload,
  }),
  SetCsvData: (payload) => ({ type: actionTypes.SetCsvData, payload }),
  SetLoading: (payload) => ({ type: actionTypes.SetLoading, payload }),
  loading: (payload) => ({ type: actionTypes.Loading, payload }),
  GetClassesByService: (payload) => ({
    type: actionTypes.GetClassesByService,
    payload,
  }),

  GetClassesByServiceEnrolment: (payload) => ({
    type: actionTypes.GetClassesByServiceEnrolment,
    payload,
  }),

  GetLessonsByClassesEnrolment: (payload) => ({
    type: actionTypes.GetLessonsByClassesEnrolment,
    payload,
  }),

  GetClassesByServiceForCommunications: (payload) => ({
    type: actionTypes.GetClassesByServiceForCommunications,
    payload,
  }),
  setClassesPrivateState: (payload) => ({
    type: actionTypes.setClassesPrivateState,
    payload,
  }),
  setClassesWithEnrolPercentage: (payload) => ({
    type: actionTypes.SetClassesWithEnrolPercentage,
    payload,
  }),
  SetClassWithEnrolLoading: (payload) => ({
    type: actionTypes.SetClassWithEnrolLoading,
    payload,
  }),
  setAllClassesByActiveEnrolments: (payload) => ({
    type: actionTypes.SetAllClassesByActiveEnrolments,
    payload,
  }),
  SetAllClassesByActiveEnrolmentsLoading: (payload) => ({
    type: actionTypes.SetAllClassesByActiveEnrolmentsLoading,
    payload,
  }),
  GetList: (payload) => ({
    type: actionTypes.GetList,
    payload,
  }),
  CreateLessons: (payload) => ({ type: actionTypes.CreateLessons, payload }),
  GetSelected: (payload) => ({ type: actionTypes.GetSelected, payload }),
  SetSelected: (payload) => ({ type: actionTypes.SetSelected, payload }),
  resetState: (payload) => ({ type: actionTypes.resetState, payload }),
  UpdateLessons: (payload) => ({ type: actionTypes.UpdateLessons, payload }),
  GetLessonsByLocation: (payload) => ({
    type: actionTypes.GetClassesByLocation,
    payload,
  }),
  SetClassesByService: (payload) => ({
    type: actionTypes.SetClassesByService,
    payload,
  }),

  SetClassesByServiceEnrolment: (payload) => ({
    type: actionTypes.SetClassesByServiceEnrolment,
    payload,
  }),

  SetLessonsByClassesEnrolment: (payload) => ({
    type: actionTypes.SetLessonsByClassesEnrolment,
    payload,
  }),

  SetClassesByServiceForCommunications: (payload) => ({
    type: actionTypes.SetClassesByServiceForCommunications,
    payload,
  }),
  GetLessonsByClass: (payload) => ({
    type: actionTypes.GetLessonsByClass,
    payload,
  }),
  SetLessonsByClass: (payload) => ({
    type: actionTypes.SetLessonsByClass,
    payload,
  }),
  SendCSVFile: (payload) => ({
    type: actionTypes.SendCSVFile,
    payload,
  }),
  exportCsvFile: (payload) => ({
    type: actionTypes.ExportCsvFile,
    payload,
  }),
  DuplicateRecord: (payload) => ({
    type: actionTypes.DuplicateRecord,
    payload,
  }),

  GetSessionRevenueClass: (payload) => ({
    type: actionTypes.GetSessionRevenueClass,
    payload,
  }),
  SetSessionRevenueClass: (payload) => ({
    type: actionTypes.SetSessionRevenueClass,
    payload,
  }),
};
