import {
  getTutorAssign,
  getAll,
  create,
  deleteBooking,
  updateSelected,
  getSelected,
  getCancelledList,
  getPast,
  getUpcoming,
  getScheduleBooking,
  addMessage,
  editMessage,
  getNewBookingCount,
  deleteMessage,
} from "../../services/booking";
import { actionTypes, actions } from "../actions/booking";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetAssignFortutor, function* getList() {
    try {
      const result = yield call(getTutorAssign);
      return yield put(
        actions.setAssignFortutor({
          AssignFortutor: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.GetList, function* getList() {
    try {
      const result = yield call(getAll);
      return yield put(
        actions.setState({
          list: result.data,
          // meta: null,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(
    actionTypes.GetCountsOfAllBookings,
    function* getBookingsCount() {
      try {
        const result = yield call(getNewBookingCount);
        return yield put(
          actions.SetCounts({
            counts: result.data,
            // meta: null,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  );

  yield takeLatest(
    actionTypes.GetSelectedBooking,
    function* getSelectedBookingById(payload) {
      try {
        const result = yield call(getSelected, payload.payload);
        return yield put(
          actions.SetSelectedBooking({
            selected: result.data,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  );

  yield takeLatest(actionTypes.AddMessage, function* addNewMessage(payload) {
    try {
      const result = yield call(addMessage, payload.payload);

      toast.success(result.data.message);

      return yield put(
        actions.SetSelectedBooking({
          selected: result.data.results,
        })
      );
    } catch (error) {
      return toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.EditMessage, function* editNewMessage(payload) {
    try {
      const result = yield call(editMessage, payload.payload);

      toast.success(result.data.message);

      return yield put(
        actions.SetSelectedBooking({
          selected: result.data.results,
        })
      );
    } catch (error) {
      return toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.DeleteMessage, function* deleteNewMessage(
    payload
  ) {
    try {
      const result = yield call(deleteMessage, payload.payload);

      toast.success(result.data.message);

      return yield put(
        actions.SetSelectedBooking({
          selected: result.data.results,
        })
      );
    } catch (error) {
      return toast.error(error.response.data.error);
    }
  });

  yield takeLatest(
    actionTypes.GetScheduleBooking,
    function* getScheduleBookingList() {
      try {
        const result = yield call(getScheduleBooking);

        return yield put(
          actions.SetScheduleBooking({
            scheduleList: result.data,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  );

  yield takeLatest(
    actionTypes.GetCancelledList,
    function* getCancelledBirthdayList() {
      try {
        const result = yield call(getCancelledList);

        return yield put(
          actions.SetCancelledList({
            cancelledList: result.data,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  );

  yield takeLatest(actionTypes.GetPastBooking, function* getPastList() {
    try {
      const result = yield call(getPast);

      return yield put(
        actions.SetPastBooking({
          pastList: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.GetFutureBooking, function* getFutureList() {
    try {
      const result = yield call(getUpcoming);
      return yield put(
        actions.SetFutureBooking({
          upcomingList: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.CreateBooking, function* createBooking(payload) {
    yield put(
      actions.LoadingCreateBooking({
        loading: true,
      })
    );
    try {
      const result = yield call(create, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });

      yield put(
        actions.LoadingCreateBooking({
          loading: false,
        })
      );
      payload.history.push(`/thankyou/booking/${payload.payload.business}`);

      toast.success(result.data.message);
    } catch (error) {
      console.log(error);
      yield put(
        actions.LoadingCreateBooking({
          loading: false,
        })
      );
      if (error.message) {
        toast.error(error.message);
        toast.error("Please check the form for any error!");
      } else {
        toast.error(error.response.data.error);
      }
    }
  });

  yield takeLatest(actionTypes.DeleteBooking, function* deleteSelectedBooking(
    payload
  ) {
    try {
      const result = yield call(deleteBooking, payload.payload);
      toast.success(result.data.message);
      return yield put(
        actions.setState({
          meta: null,
        })
      );
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.UpdateBooking, function* updateSelectedBooking(
    payload
  ) {
    try {
      const result = yield call(updateSelected, payload.payload);

      yield put({
        type: actionTypes.GetSelectedBooking,
        payload: { id: payload.payload._id },
      });
      yield put({
        type: actionTypes.GetFutureBooking,
      });
      yield put({
        type: actionTypes.GetPastBooking,
      });
      yield put({
        type: actionTypes.GetCancelledList,
      });
      yield put({
        type: "booking/GET_COUNTS_OF_ALL_BOOKINGS",
      });
      return toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });
}
