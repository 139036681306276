import moment from "moment";
import baseApi from "../api/baseApi";
import { DATE_FORMAT } from "../app/Common/commonMethods";
// import axios from "axios";
// import store from "../redux/store";

// axios.interceptors.request.use(
//   (config) => {
//     const {
//       auth: { authToken },
//     } = store.getState();

//     if (authToken) {
//       config.headers.Authorization = `Bearer ${authToken}`;
//     }

//     return config;
//   },
//   (err) => Promise.reject(err)
// );

// const authToken = () =>
//   JSON.parse(localStorage.getItem("persist:auth"))
//     ? JSON.parse(localStorage.getItem("persist:auth")).authToken
//     : "";

// baseApi.defaults.headers.common["Authorization"] = authToken()
//   ? JSON.parse(authToken()).token
//   : "";

export function getAll() {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.get("/products/all");
}

export function getByUuid(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.get(`/products/get/public/${payload.uuid}`);
}

export function getSelected(payload) {
  if (payload.id) {
    const authToken = () =>
      JSON.parse(localStorage.getItem("persist:auth"))
        ? JSON.parse(localStorage.getItem("persist:auth")).authToken
        : "";

    baseApi.defaults.headers.common["Authorization"] = authToken()
      ? JSON.parse(authToken()).token
      : "";
    return baseApi.get(`/products/get/${payload.id}`);
  }
}

export function create(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";
  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  //Creating a form data object here because of image file
  const fileImage = new FormData();
  fileImage.append("attributes", JSON.stringify(payload.attributes));
  fileImage.append("category", payload.category);
  fileImage.append("description", payload.description);
  fileImage.append("name", payload.name);
  fileImage.append("price", payload.price);
  fileImage.append("shortDescription", payload.shortDescription);
  fileImage.append("sku", payload.sku);
  fileImage.append("featuredImage", payload.featuredImage);
  fileImage.append("business", payload.business);
  fileImage.append("personalisationAvailable", payload.personalisationAvailable);

  if (payload?.additionalImages && Array.isArray(payload?.additionalImages)) {
    for (let value of payload?.additionalImages) {
      fileImage.append("additionalImages", value);
    }
  }

  return baseApi.post(`products/create`, fileImage, {
    headers: {
      "Content-Type": "multipart/form-data, charset=utf-8",
    },
  });
}

export function getProductsByCategory(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.get(`/products/parent/${payload.parentId}`);
}

export function deleteProduct(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.delete(`/products/delete/${payload.id}`);
}

export function updateSelected(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  //Creating a form data object here because of image file
  const fileImage = new FormData();
  fileImage.append("attributes", JSON.stringify(payload.attributes));
  fileImage.append("category", payload.category);
  fileImage.append("description", payload.description);
  fileImage.append("name", payload.name);
  fileImage.append("price", payload.price);
  fileImage.append("shortDescription", payload.shortDescription);
  fileImage.append("sku", payload.sku);
  fileImage.append("featuredImage", payload.featuredImage);
  fileImage.append("business", payload.business);
  fileImage.append("personalisationAvailable", payload.personalisationAvailable);
  
  if (payload?.additionalImages && Array.isArray(payload?.additionalImages)) {
    for (let value of payload?.additionalImages) {
      fileImage.append("additionalImages", value);
    }
  }
  return baseApi.put(`/products/update/${payload.id}`, fileImage);
}

export const getProductReportsData = (payload) => {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get(`/products/getMerchandiseReports`, {
    params: {
      startDate: moment(payload.startDate, "YYYY-MM-DD").format(DATE_FORMAT),
      endDate: moment(payload.endDate, "YYYY-MM-DD").format(DATE_FORMAT),
    },
  });
};
