import {
  getListByClass,
  getAll,
  deleteWaiting,
  update,
  getOne,
  sendEmail,
  waitingtoEnrolment,
} from "../../services/waitingList";

import { actionTypes, actions } from "../actions/waitingList";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetAll, function* getAllWaiting(payload) {
    try {
      actions.setLoading({
        loading: true,
      });
      const result = yield call(getAll, payload);

      if (payload.payload.download) {
        window.open(result.data.results, "_blank");
        actions.setLoading({
          loading: false,
        });
      } else {
        actions.setLoading({
          loading: false,
        });
        yield put(
          actions.setState({
            list: result.data.results,
            count: result.data.count,
          })
        );
      }
    } catch (error) {
      actions.setLoadingExport({
        loading: false,
      });
    }
  });

  yield takeLatest(actionTypes.GetSelected, function* getSelected(payload) {
    try {
      const result = yield call(getOne, payload);

      yield put(
        actions.SetSelected({
          selected: result.data,
        })
      );
    } catch (error) {}
  });

  yield takeLatest(actionTypes.SendEmail, function* getEmailSelected(payload) {
    try {
      const result = yield call(sendEmail, payload.payload);
      return toast.success("Message sent successfully!");
    } catch (error) {
      return toast.error("Error: " + error);
    }
  });

  yield takeLatest(actionTypes.GetListByClass, function* getListByClasses(
    payload
  ) {
    try {
      const result = yield call(getListByClass, payload);

      yield put(
        actions.setState({
          listBySelectedClass: result.data.results,
        })
      );
    } catch (error) {}
  });

  yield takeLatest(actionTypes.DeleteWaiting, function* deleteWaitingEntry(
    payload
  ) {
    try {
      const result = yield call(deleteWaiting, payload);
      yield put({
        type: actionTypes.GetAll,
        payload: { filter: payload.payload.type },
      });

      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.UpdateWaiting, function* updateWaitingRecord(
    payload
  ) {
    try {
      const result = yield call(update, payload);
      toast.success(result.data.message);

      yield put({
        type: actionTypes.GetAll,
        payload: null,
      });
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(
    actionTypes.WaitingtoEnrolment,
    function* WaitingtoEnrolmentRecord(payload) {
      try {
        const result = yield call(waitingtoEnrolment, payload);

        yield put({
          type: actionTypes.GetListByClass,
          payload: { classId: payload.payload.classId },
        });

        return toast.success(result.data.message);
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    }
  );
}
