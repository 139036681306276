import { actionTypes } from "../actions/inflatable";

const initialInflatableReducer = {
  list: [],
  pastList: [],
  upcomingList: [],
  selected: {},
  locationSurcharge: [],
  cancelledList: [],
  loading: false,
};

const inflatableReducer = (state = initialInflatableReducer, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list } = action.payload;
      return {
        ...state,
        list,
      };
    }
    case actionTypes.SetPastInflatable: {
      const { pastList } = action.payload;

      return {
        ...state,
        pastList,
      };
    }
    case actionTypes.SetFutureInflatable: {
      const { upcomingList } = action.payload;
      return {
        ...state,
        upcomingList,
      };
    }

    case actionTypes.SetSelectedInflatable: {
      const { selected } = action.payload;
      return {
        ...state,
        selected,
      };
    }

    case actionTypes.SetLocationSurcharge: {
      const { locationSurcharge } = action.payload;
      return {
        ...state,
        locationSurcharge,
      };
    }

    case actionTypes.SetCancelledList: {
      const { cancelledList } = action.payload;
      return {
        ...state,
        cancelledList,
      };
    }

    case actionTypes.SetLoader: {
      const { loading } = action.payload;
      return {
        ...state,
        loading,
      };
    }

    default:
      return state;
  }
};

export default inflatableReducer;
