import React from "react";
import { withRouter } from "react-router-dom";
import Birthday from "../../components/ThankYou/Birthday";

const ThankyouPage = ({ match }) => {
  return (
    <>
      <Birthday business={match.params.business} match={match} publicShop />
    </>
  );
};

export default withRouter(ThankyouPage);
