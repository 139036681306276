import React, { useState } from "react";
import { Formik, Field } from "formik";
import { CardElement, ElementsConsumer } from "@stripe/react-stripe-js";

const CreateCardShop = ({
  stripe,
  elements,
  stripePublishableKey,
  formWizard,
  onCancel,
  getCardSource,
}) => {
  const [spin, setSpin] = useState(false);

  const submitHandler = async (values) => {
    if (stripePublishableKey) {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      const cardElement = elements.getElement(CardElement);
      const { token } = await stripe.createToken(cardElement);

      if (token) {
        setSpin(true);

        values.source = token.id;
      } else {
        return;
      }

      getCardSource(values);
    }
  };

  return (
    <div className="col-md-12">
      <Formik
        enableReinitialize={true}
        initialValues={{
          source: "",
          accountHolderName: "",
        }}
        //   validationSchema={schema}
        onSubmit={(values, { resetForm }) => {
          submitHandler(values, resetForm);
        }}
      >
        {({ handleSubmit, values }) => (
          <form
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
            className="form"
          >
            <div>
              <div className="row">
                <>
                  <div className="col-sm-12 col-md-12">
                    <div className="form-group">
                      <label id="my-radio-group">Enter Card Details</label>
                      <Field name="stripeId">
                        {() => <CardElement hidePostalCode />}
                      </Field>
                    </div>
                  </div>
                </>
              </div>
            </div>

            <div className="d-flex justify-content-between border-top mt-5 pt-10">
              <div className="mr-2">
                <button
                  type="button"
                  className="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4"
                  data-wizard-type="action-prev"
                  onClick={() => {
                    formWizard.goPrev();
                  }}
                >
                  Previous
                </button>
              </div>
              <div>
                <button
                  type="submit"
                  className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
                >
                  Next
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default function InjectedPaymentSetupForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CreateCardShop stripe={stripe} elements={elements} {...props} />
      )}
    </ElementsConsumer>
  );
}
