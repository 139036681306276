import { actionTypes } from "../actions/roles";

const initialServiceReducer = {
  list: [],
};

const rolesReducer = (state = initialServiceReducer, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list, selected } = action.payload;
      return {
        ...state,
        list,
        selected,
      };
    }

    default:
      return state;
  }
};

export default rolesReducer;
