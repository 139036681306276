import {
  getAll,
  deleteNote,
  create,
  getSelected,
  updateSelected,
  getParentList,
} from "../../services/notes";
import { actionTypes, actions } from "../actions/notes";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    try {
      const result = yield call(getAll);
      return yield put(
        actions.SetState({
          list: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.GetParentList, function* getParentListbyId(
    payload
  ) {
    try {
      const result = yield call(getParentList, payload.payload);
      return yield put(
        actions.SetParentList({
          parentList: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.UpdateNote, function* updateSelectedNote(
    payload
  ) {
    try {
      const result = yield call(updateSelected, payload.payload);
      yield put({
        type: actionTypes.GetParentList,
        payload: {
          id: payload.payload.from,
        },
      });
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.GetSelectedNote, function* getSelectedNote(
    payload
  ) {
    try {
      const result = yield call(getSelected, payload.payload);
      return yield put(
        actions.SetSelectedNote({
          selected: result.data,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  });

  yield takeLatest(actionTypes.CreateNote, function* createNote(payload) {
    try {
      const result = yield call(create, payload.payload.values);
      yield put({
        type: actionTypes.GetParentList,
        payload: {
          id: payload.payload.values.from,
        },
      });
      yield put({
        type: actionTypes.GetList,
      });
      return toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.DeleteNote, function* deleteSelectedNote(
    payload
  ) {
    try {
      const result = yield call(deleteNote, payload.payload);

      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
      yield put({
        type: actionTypes.GetParentList,
        payload: {
          id: payload.payload.parentId,
        },
      });
      return yield put({
        type: actionTypes.GetList,
      });
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });
}
