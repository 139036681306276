import React from "react";
import { Link, withRouter } from "react-router-dom";

function Birthday({ business, publicShop, match, history }) {
  console.log(business, publicShop, match, history);
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="example example-compact">
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    minHeight: "100vh",
                    textAlign: "center",
                  }}
                >
                  <h2>Hip hip hooray!</h2>
                  <hr />
                  <h6
                    style={{
                      fontSize: "14px",
                      fontWeight: "normal",
                      lineHeight: "1.6",
                    }}
                  >
                    You've submitted your party booking. We'll go through your
                    form and make sure the date & time you want is free.
                    <br /> When we confirm that it's all systems go, we'll bill you 50%, then the remaining 50% 7 days before the party. If your party is less than 7 days away, we'll bill you for the full amount when we confirm. We'll be in touch soon!
                    {/* <br />
                    <strong>
                      (If you book less than 7 days before the party then we'll
                      take the full balance when we confirm the party.)
                    </strong> */}
                  </h6>
                  <hr />

                  <h4
                    style={{
                      fontSize: "15px",
                      fontWeight: "normal",
                      lineHeight: "1.4",
                    }}
                  >
                    Kind regards
                    <br />
                    from the{" "}
                    <strong>
                      {business.toLowerCase() === "ginger"
                        ? "Ginger Sport"
                        : business.toLowerCase() === "quest"
                          ? "Quest FA"
                          : ""}{" "}
                      team
                    </strong>
                  </h4>
                  {/* {!publicShop && (
                    <span
                      onClick={() =>
                        history.push(
                          `/parent/dashboard`
                        )
                      }
                      style={{
                        cursor: "pointer",
                        color: "blue",
                      }}
                    >
                      Back
                    </span>
                  )} */}

                  {publicShop && (
                    <span
                      onClick={() =>
                        history.push(
                          `/${match.params.uuid}/public-shop/${match.params.business}`
                        )
                      }
                      style={{
                        cursor: "pointer",
                        color: "blue",
                      }}
                    >
                      Back to Shop
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(Birthday);
