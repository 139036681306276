import { actionTypes } from "../actions/price";

const initialPriceReducer = {
  list: [],
  loading: false,
};

const priceReducer = (state = initialPriceReducer, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list } = action.payload;
      return {
        ...state,
        list,
      };
    }

    case actionTypes.SetLoading: {
      const { loading } = action.payload;
      return {
        ...state,
        loading,
      };
    }

    default:
      return state;
  }
};

export default priceReducer;
