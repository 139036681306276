import {
  getAll,
  createLesson,
  updateLesson,
  getAllLessonsByLocation,
  getLessonsByClass,
  getTutorLessons,
  getSelectedLesson,
  deleteLesson,
  addMessage,
  editMessage,
  deleteMessage,
  getUpcoming,
  getToday,
  getAllLessonsByTutor,
  getLessonReportsByDate,
  getLessonsByEnrolment,
} from "../../services/lessons";
import { actionTypes, actions } from "../actions/lessons";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    yield put(
      actions.setState({
        list: [],
        meta: {
          list: {
            loading: true,
          },
        },
      })
    );
    try {
      const result = yield call(getAll);
      return yield put(
        actions.setState({
          list: result.data,
          meta: null,
        })
      );
    } catch (error) {
      return yield put(
        actions.setState({
          list: [],
          meta: {
            list: {
              loading: false,
            },
          },
        })
      );
    }
  });

  yield takeLatest(actionTypes.GetUpcoming, function* getUpcomingList() {
    try {
      const result = yield call(getUpcoming);

      return yield put(
        actions.SetUpcoming({
          upcomingList: result.data,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  });

  yield takeLatest(
    actionTypes.GetLessonsReportByDate,
    function* getUpcomingList({ payload }) {
      yield put(
        actions.SetReportsLoading({
          reportsLoading: true,
        })
      );
      try {
        const result = yield call(getLessonReportsByDate, payload);
        yield put(
          actions.SetReportsLoading({
            reportsLoading: false,
          })
        );
        return yield put(
          actions.SetLessonsReportByDate({
            reportsByLesson: result.data,
          })
        );
      } catch (error) {
        console.log(error.response);
        yield put(
          actions.SetReportsLoading({
            reportsLoading: false,
          })
        );
        return yield put(
          actions.SetLessonsReportByDate({
            reportsByLesson: [],
          })
        );
      }
    }
  );

  yield takeLatest(
    actionTypes.GetLessonsByLocation,
    function* getLessonsByLocationId(payload) {
      try {
        const result = yield call(getAllLessonsByLocation, payload.payload);

        return yield put(
          actions.setState({
            lessonsByLocation: result.data,
          })
        );
      } catch (error) {
        console.log(error.response);
      }
    }
  );

  
  yield takeLatest(
    actionTypes.GetLessonsByEnrolments,
    function* getLessonsByLocationId(payload) {
      try {
        const result = yield call(getLessonsByEnrolment, payload.payload);
        return yield put(
          actions.SetAllLessonsByEnrolment({
            lessonsByEnrolment: result.data.results,
          })
        );
      } catch (error) {
        console.log(error.response);
      }
    }
  );


  yield takeLatest(actionTypes.GetSelectedLesson, function* getSelectedId(
    payload
  ) {
    try {
      const result = yield call(getSelectedLesson, payload.payload);

      return yield put(
        actions.SetSelectedLesson({
          selected: result.data,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  });

  yield takeLatest(actionTypes.GetToday, function* getTodayLessons(payload) {
    try {
      const result = yield call(getToday, payload.payload);

      return yield put(
        actions.SetToday({
          todaysLesson: result.data,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  });

  yield takeLatest(actionTypes.GetLessonsByClass, function* getLessonsByClassId(
    payload
  ) {
    yield put(
      actions.SetLoaderLessons({
        loadingLessons: true,
      })
    );
    try {
      const result = yield call(getLessonsByClass, payload.payload);
      yield put(
        actions.SetLoaderLessons({
          loadingLessons: false,
        })
      );

      return yield put(
        actions.SetLessonsByClass({
          lessonsByClass: result.data,
        })
      );
    } catch (error) {
      yield put(
        actions.SetLoaderLessons({
          loadingLessons: false,
        })
      );
      console.log(error.response);
    }
  });

  yield takeLatest(
    actionTypes.GetLessonsbyTutor,
    function* getLessonsByTutorId({ payload }) {
      yield put(
        actions.SetLoaderLessons({
          loadingLessons: true,
        })
      );
      try {
        const result = yield call(getAllLessonsByTutor, payload);
        yield put(
          actions.SetLoaderLessons({
            loadingLessons: false,
          })
        );
        return yield put(
          actions.SetLessonsbyTutor({
            lessonsByTutorReport: {
              count: result?.data?.count,
              records: result?.data?.records,
            },
          })
        );
      } catch (error) {
        toast.error("Something went wrong. Please try after some time.");
        yield put(
          actions.SetLoaderLessons({
            loadingLessons: false,
          })
        );
        return yield put(
          actions.SetLessonsbyTutor({
            lessonsByTutorReport: { count: 0, records: [] },
          })
        );
        console.log(error.response);
      }
    }
  );

  yield takeLatest(
    actionTypes.GetTutorLessons,
    function* getLessonsOfCurrentTutor({ payload }) {
      yield put(
        actions.SetLoaderLessons({
          loadingLessons: true,
        })
      );
      try {
        const result = yield call(getTutorLessons, payload);
        yield put(
          actions.SetLoaderLessons({
            loadingLessons: false,
          })
        );
        if (payload.type === "active") {
          return yield put(
            actions.SetTutorLessons({
              tutorLessons: result.data.results,
              count: result?.data?.count,
            })
          );
        } else {
          return yield put(
            actions.SetPreviousLessons({
              previousLessons: result.data.results,
              count: result?.data?.count,
            })
          );
        }
      } catch (error) {
        yield put(
          actions.SetLoaderLessons({
            loadingLessons: false,
          })
        );
        console.log(error.response);
      }
    }
  );

  yield takeLatest(actionTypes.UpdateLessons, function* updateLessons(payload) {
    try {
      const result = yield call(updateLesson, payload.payload);

      yield put({
        type: actionTypes.GetLessonsByClass,
        payload: {
          classId: payload.payload.class,
        },
      });
      return toast.success(result.data.message);
    } catch (error) {
      if (error?.response?.data?.message) {
        return toast.error(error?.response?.data?.message);
      } else {
        toast.error(
          "Lesson cannot be created right now. Please try again later."
        );
      }
      return yield put(
        actions.setState({
          meta: {
            list: {
              loading: false,
            },
          },
        })
      );
    }
  });

  yield takeLatest(actionTypes.AddMessage, function* addNewMessage(payload) {
    try {
      const result = yield call(addMessage, payload.payload);

      toast.success(result.data.message);

      return yield put(
        actions.SetSelectedLesson({
          selected: result.data.results,
        })
      );
    } catch (error) {
      return toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.EditMessage, function* editNewMessage(payload) {
    try {
      const result = yield call(editMessage, payload.payload);

      toast.success(result.data.message);

      return yield put(
        actions.SetSelectedLesson({
          selected: result.data.results,
        })
      );
    } catch (error) {
      return toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.DeleteMessage, function* deleteNewMessage(
    payload
  ) {
    try {
      const result = yield call(deleteMessage, payload.payload);

      toast.success(result.data.message);

      return yield put(
        actions.SetSelectedLesson({
          selected: result.data.results,
        })
      );
    } catch (error) {
      return toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.CreateLessons, function* createLessons(payload) {
    try {
      const result = yield call(createLesson, payload.payload);
      toast.success(result.data.message);
      yield put(
        actions.setState({
          groups: result.data,
          meta: null,
        })
      );

      return yield put({
        type: actionTypes.GetLessonsByClass,
        payload: {
          classId: payload.payload.classId,
        },
      });
    } catch (error) {
      if (error?.response?.data?.message) {
        return toast.error(error?.response?.data?.message);
      } else {
        toast.error(
          "Lesson cannot be created right now. Please try again later."
        );
      }
      return yield put(
        actions.setState({
          meta: {
            list: {
              loading: false,
            },
          },
        })
      );
    }
  });

  yield takeLatest(actionTypes.DeleteLesson, function* deleteSelectedlesson({
    payload,
  }) {
    try {
      const result = yield call(deleteLesson, payload);
      toast.success(result.data.message);
      return yield put({
        type: actionTypes.GetLessonsByClass,
        payload: {
          classId: payload?.selectedClass,
        },
      });
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });
}
