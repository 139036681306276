export const actionTypes = {
  GetList: "levels/GET_LIST",
  SetState: "levels/SET_STATE",
  CreateLevel: "levels/CREATE_LEVEL",
  UpdateLevel: "levels/UPDATE_LEVEL",
  DeleteLevel: "levels/DELETE_LEVEL",
  GetSelectedLevel: "levels/GET_SELECTED_LEVEL",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  createLevel: (payload) => ({ type: actionTypes.CreateLevel, payload }),
  updateLevel: (payload) => ({ type: actionTypes.UpdateLevel, payload }),
  deleteLevel: (payload) => ({ type: actionTypes.DeleteLevel, payload }),
  getSelectedLevel: (payload) => ({
    type: actionTypes.GetSelectedLevel,
    payload,
  }),
};
