export const actionTypes = {
  GetList: "alert/GET_LIST",
  SetState: "alert/SET_STATE",
  DeleteAlert: "alert/DELETE_ALERT",
  GetSelectedAlert: "alert/GET_SELECTED_ALERT",
  SetSelectedAlert: "alert/SET_SELECTED_ALERT",
  ChangeStatus: "alert/CHANGE_STATUS",
};

export const actions = {
  GetList: (payload) => ({
    type: actionTypes.GetList,
    payload,
  }),
  SetState: (payload) => ({ type: actionTypes.SetState, payload }),
  DeleteAlert: (payload) => ({
    type: actionTypes.DeleteAlert,
    payload,
  }),
  GetSelectedAlert: (payload) => ({
    type: actionTypes.GetSelectedAlert,
    payload,
  }),
  SetSelectedAlert: (payload) => ({
    type: actionTypes.SetSelectedAlert,
    payload,
  }),
  ChangeStatus: (payload) => ({
    type: actionTypes.ChangeStatus,
    payload,
  }),
};
