import {
  getAll,
  deleteProduct,
  create,
  getSelected,
  updateSelected,
  getProductsByCategory,
  getByUuid,
  getProductReportsData,
} from "../../services/products";
import { actionTypes, actions } from "../actions/products";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    try {
      const result = yield call(getAll);
      return yield put(
        actions.setState({
          list: result.data,
        })
      );
    } catch (error) { }
  });

  yield takeLatest(actionTypes.GetProductsByUuid, function* getPublicProducts(
    payload
  ) {
    try {
      const result = yield call(getByUuid, payload);
      return yield put(
        actions.setState({
          publicProducts: result.data,
        })
      );
    } catch (error) { }
  });

  yield takeLatest(
    actionTypes.GetProductsByCategory,
    function* getProductsCategory(payload) {
      try {
        const result = yield call(getProductsByCategory, payload);
        return yield put(
          actions.SetProductsByCategory({
            productsByCategorys: result.data.results,
          })
        );
      } catch (error) { }
    }
  );

  yield takeLatest(actionTypes.UpdateProduct, function* updateSelectedProduct(
    payload
  ) {
    try {
      const result = yield call(updateSelected, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.GetSelectedProduct, function* getSelectedProduct(
    payload
  ) {
    try {
      const result = yield call(getSelected, payload.payload);
      return yield put(
        actions.getSelectedProduct({
          selected: result.data,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  });

  yield takeLatest(actionTypes.CreateProduct, function* createProduct({
    payload,
  }) {
    try {
      const result = yield call(create, payload.values);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      console.log(error);
      // toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.DeleteProduct, function* deleteSelectedVenue(
    payload
  ) {
    try {
      const result = yield call(deleteProduct, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
      return yield put(
        actions.setState({
          meta: null,
        })
      );
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(
    actionTypes.GetProductsReportsData,
    function* getReportsData({ payload }) {
      yield put(
        actions.SetReportsLoad({
          loading: true,
        })
      );
      try {
        const result = yield call(getProductReportsData, payload);
        yield put(
          actions.SetReportsLoad({
            loading: false,
          })
        );
        return yield put(
          actions.SetProductsReportsData({
            data: result.data,
          })
        );
      } catch (error) {
        yield put(
          actions.SetReportsLoad({
            loading: false,
          })
        );
        toast.error(error.response.data.error);
      }
    }
  );
}
