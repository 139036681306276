import { actionTypes } from "../actions/publicholidays";

const initialPublicHolidayReducer = {
  list: [],
  selected: {},
  // meta: {},
  enrolmentOnHoliday: [],
};

const publicHolidayReducer = (state = initialPublicHolidayReducer, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list } = action.payload;
      return {
        ...state,
        list,
      };
    }

    case actionTypes.SetEnrolmentsByHoliday: {
      return {
        ...state,
        enrolmentOnHoliday: action.payload.enrolmentOnHoliday,
      };
    }

    case actionTypes.GetSelectedPublicHoliday: {
      return {
        ...state,
        selected: { ...action.payload.selected },
      };
    }

    default:
      return state;
  }
};

export default publicHolidayReducer;
