import { actionTypes } from "../actions/regions";

const initialServiceReducer = {
  list: [],
  selected: {},
  enrolmentsByRegions: [],
  report: [],
  loading: false,
};

const serviceReducer = (state = initialServiceReducer, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list } = action.payload;
      return {
        ...state,
        list,
      };
    }
    case actionTypes.SetEnrolmentsByRegion: {
      const { enrolmentsByRegions } = action.payload;
      return {
        ...state,
        enrolmentsByRegions,
      };
    }

    case actionTypes.SetReport: {
      const { report } = action.payload;

      return {
        ...state,
        report,
      };
    }

    case actionTypes.SET_LOADING: {
      const { loading } = action.payload;

      return {
        ...state,
        loading,
      };
    }

    default:
      return state;
  }
};

export default serviceReducer;
