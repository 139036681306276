import { getAllRoles, getSelected, deleteRole } from "../../services/roles";
import { actionTypes, actions } from "../actions/roles";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    try {
      const result = yield call(getAllRoles);
      return yield put(
        actions.setState({
          list: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.GetSelected, function* getSelectedRole({
    payload,
  }) {
    try {
      const result = yield call(getSelected, payload);
      return yield put(
        actions.setState({
          selected: result.data,
        })
      );
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error("Server Error");
      }
    }
  });

  yield takeLatest(actionTypes.DeleteRole, function* deleteSelectedRole({
    payload,
  }) {
    try {
      const result = yield call(deleteRole, payload);

      toast.success("Role successfully deleted");
      yield put({
        type: actionTypes.GetList,
      });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error("Server Error");
      }
    }
  });
}
