import React from "react";
import { useHistory } from "react-router-dom";
import { isPublicPage } from "../Common/_routehepers";
const AppStyles = React.lazy(() => import("../styleLoader/AppStyle"));
const PublicPageStyles = React.lazy(() =>
  import("../styleLoader/PublicPageStyles")
);

const AppStylesSelector = ({ children }) => {
  let history = useHistory();

  return (
    <>
      <React.Suspense fallback={<></>}>
        {isPublicPage(history) ? <PublicPageStyles /> : <AppStyles />}
      </React.Suspense>
      {children}
    </>
  );
};

export default AppStylesSelector;
