import React from "react";
import { useSelector } from "react-redux";

const totalCalculateHelper = ({ total, coupon }) => {
  if (coupon?.amount) {
    if (coupon?.type === "flat") {
      const calculatedAmount = total - parseFloat(coupon?.amount);
      return isFinite(calculatedAmount) && calculatedAmount > 0
        ? calculatedAmount
        : "0";
    } else {
      const calculatedAmount = total * (1 - parseFloat(coupon?.amount) / 100);
      return isFinite(calculatedAmount) && calculatedAmount > 0
        ? calculatedAmount
        : "0";
    }
  } else {
    return isFinite(total) && total > 0 ? total : "0";
  }
};

const StepThree = ({
  shopSubmit,
  address,
  cart,
  total,
  formWizard,
  onCouponChangeHandler,
  coupon,
  couponValidateMessage,
}) => {
  const orders = useSelector((state) => state.orders);
  return (
    <form
      className="form fv-plugins-bootstrap fv-plugins-framework"
      id="kt_form"
      onSubmit={shopSubmit}
    >
      <h4 className="mb-10 font-weight-bold text-dark">
        Review your Order and Submit
      </h4>
      <div className="form-group fv-plugins-icon-container">
        <label>Enter Coupon Code (if you have one)</label>
        <input
          type="text"
          className="form-control form-control-solid form-control-lg"
          name="coupon"
          placeholder="Enter Coupon"
          // value={address.coupon}
          onChange={onCouponChangeHandler}
        />
        {console.log({ couponValidateMessage })}
        {couponValidateMessage && (
          <div
            style={{
              color:
                couponValidateMessage === "Coupon Applied" ? "green" : "red",
            }}
          >
            <p>{couponValidateMessage}</p>
          </div>
        )}
      </div>
      <h4 className="mb-10 font-weight-bold text-dark">Enter Your Address</h4>
      <h6 className="font-weight-bolder mb-3">Delivery Address:</h6>
      <div className="text-dark-50 line-height-lg">
        <div>{address.address}</div>
        <div>
          {address.suburb} {address.postcode}, {address.state}
        </div>
      </div>
      <div className="separator separator-dashed my-5"></div>
      <h6 className="font-weight-bolder mb-3">Order Details:</h6>
      <div className="text-dark-50 line-height-lg">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th className="pl-0 font-weight-bold text-muted text-uppercase">
                  Ordered Items
                </th>
                <th className="text-right font-weight-bold text-muted text-uppercase">
                  Qty
                </th>
                <th className="text-right font-weight-bold text-muted text-uppercase">
                  Unit Price
                </th>
                <th className="text-right pr-0 font-weight-bold text-muted text-uppercase">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {cart &&
                cart.length > 0 &&
                cart.map((item) => (
                  <tr className="font-weight-boldest">
                    <td className="border-0 pl-0 pt-7 d-flex align-items-center">
                      <div className="symbol symbol-40 flex-shrink-0 mr-4 bg-light">
                        <div
                          className="symbol-label"
                          style={{
                            backgroundImage: `url('${item.product.featureImage}')`,
                          }}
                        ></div>
                      </div>
                      {item.product.name}
                    </td>
                    <td className="text-right pt-7 align-middle">{item.qty}</td>
                    <td className="text-right pt-7 align-middle">
                      ${item.product.price}
                    </td>
                    <td className="pr-0 pt-7 text-right align-middle">
                      ${parseInt(item.product.price) * parseInt(item.qty)}
                    </td>
                  </tr>
                ))}

              <tr>
                <td colspan="2"></td>
                <td className="font-weight-bolder text-right"></td>
                <td className="font-weight-bolder text-right pr-0"></td>
              </tr>
              {/* <tr>
                <td colspan="2"></td>
                <td className="font-weight-bolder text-right">Subtotal</td>
                <td className="font-weight-bolder text-right pr-0">
                  ${cart && cart.length > 0 ? total : "0.00"}
                </td>
              </tr>
              <tr>
                <td colspan="2" className="border-0 pt-0"></td>
                <td className="border-0 pt-0 font-weight-bolder text-right">
                  Delivery Fees
                </td>
                <td className="border-0 pt-0 font-weight-bolder text-right pr-0">
                  $0.00
                </td>
              </tr> */}
              {coupon?.amount && (
                <tr>
                  <td colspan="2" className="border-0 pt-0"></td>
                  <td className="border-0 pt-0 font-weight-bolder font-size-h5 text-right">
                    Coupon Discount
                  </td>

                  <td className="border-0 pt-0 font-weight-bolder font-size-h5 text-right pr-0">
                    {coupon && coupon.type === "flat"
                      ? `$${coupon?.amount}`
                      : `${coupon?.amount}%`}
                  </td>
                </tr>
              )}
              <tr>
                <td colspan="2" className="border-0 pt-0"></td>
                <td className="border-0 pt-0 font-weight-bolder font-size-h5 text-right">
                  Grand Total
                </td>

                <td className="border-0 pt-0 font-weight-bolder font-size-h5 text-right pr-0">
                  $
                  {cart && cart.length > 0
                    ? totalCalculateHelper({ total, coupon })
                    : "0.00"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="d-flex justify-content-between border-top mt-5 pt-10">
        <div className="mr-2">
          <button
            type="button"
            className="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4"
            data-wizard-type="action-prev"
            onClick={() => {
              formWizard.goPrev();
            }}
          >
            Previous
          </button>
        </div>
        <div>
          <button
            className={`btn btn-success font-weight-bolder text-uppercase px-9 py-4 ${
              orders?.loading ? "spinner spinner-white spinner-left" : ""
            }`}
            disabled={
              cart && cart.length > 0 && !orders?.loading ? false : true
            }
            data-wizard-type="action-submit"
            type="submit"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

export default StepThree;
