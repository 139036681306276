import { actionTypes } from "../actions/attendance";

const initialLessonState = {
  list: [],
  lessonAttendance: [],
  meta: {},
};

const classesReducer = (state = initialLessonState, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list, meta } = action.payload;
      return {
        ...state,
        list,
        meta: { ...meta },
      };
    }

    case actionTypes.SetListByLessons: {
      const { lessonAttendance } = action.payload;
      return {
        ...state,
        lessonAttendance,
      };
    }

    default:
      return state;
  }
};

export default classesReducer;
