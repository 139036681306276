export const isPublicPage = (history) => {
  return (history.location.pathname.includes("/enrolment/") &&
    history.location.pathname.includes("/quest")) ||
    (history.location.pathname.includes("/enrolment/") &&
      history.location.pathname.includes("/ginger")) ||
    (history.location.pathname.includes("/weekly/") &&
      history.location.pathname.includes("/ginger")) ||
    (history.location.pathname.includes("/weekly/") &&
      history.location.pathname.includes("/quest")) ||
    (history.location.pathname.includes("/birthday/") &&
      history.location.pathname.includes("/quest")) ||
    (history.location.pathname.includes("/birthday/") &&
      history.location.pathname.includes("/ginger")) ||
    (history.location.pathname.includes("/booking/") &&
      history.location.pathname.includes("/quest")) ||
    (history.location.pathname.includes("/booking/") &&
      history.location.pathname.includes("/ginger")) ||
    (history.location.pathname.includes("/inflatable/") &&
      history.location.pathname.includes("/quest")) ||
    (history.location.pathname.includes("/inflatable/") &&
      history.location.pathname.includes("/ginger")) ||
    (history.location.pathname.includes("/bookacoach/") &&
      history.location.pathname.includes("/quest")) ||
    (history.location.pathname.includes("/bookacoach/") &&
      history.location.pathname.includes("/ginger"))||
    (history.location.pathname.includes("/book-a-coach-half-form/") &&
      history.location.pathname.includes("/quest")) ||
    (history.location.pathname.includes("/book-a-coach-half-form/") &&
      history.location.pathname.includes("/ginger")) ||
    (history.location.pathname.includes("/birthdayupdate/") &&
      history.location.pathname.includes("/quest")) ||
    (history.location.pathname.includes("/birthdayupdate/") &&
      history.location.pathname.includes("/ginger")) ||
    (history.location.pathname.includes("/play/") &&
      history.location.pathname.includes("/quest")) ||
    (history.location.pathname.includes("/play/") &&
      history.location.pathname.includes("/ginger")) ||
    history.location.pathname.includes("/payment-link/") ||
    history.location.pathname.includes("/unsubscribe/") ||
    history.location.pathname.includes("/public-shop") ||
    history.location.pathname.includes("/editbookacoach")
    ? true
    : false;
};
