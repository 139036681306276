import toastr from "toastr";
import "toastr/nuget/content/content/toastr.scss";

toastr.options = {
  closeButton: false,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: "toast-top-right",
  preventDuplicates: false,
  onclick: null,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "5000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
};

class Toaster {
  success = (message) => {
    toastr.success(message);
  };

  error = (message) => {
    toastr.error(message);
  };

  info = (message) => {
    toastr.info(message);
  };
}

export const toast = new Toaster();
