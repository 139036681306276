import {
  getAll,
  deleteTeam,
  create,
  getSelected,
  updateSelected,
} from "../../services/teams";
import { actionTypes, actions } from "../actions/teams";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList(payload) {
    try {
      const result = yield call(getAll, payload.payload);
      return yield put(
        actions.setState({
          list: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.UpdateTeam, function* updateSelectedTeam(
    payload
  ) {
    try {
      const result = yield call(updateSelected, payload.payload);
      yield put({
        type: actionTypes.GetList,
        payload: {
          role: "Admin",
        },
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.GetSelectedTeam, function* getSelectedTeam(
    payload
  ) {
    try {
      const result = yield call(getSelected, payload.payload);
      return yield put(
        actions.getSelectedTeam({
          selected: result.data,
        })
      );
    } catch (error) {
      console.log(error.response);
    }
  });

  yield takeLatest(actionTypes.CreateTeam, function* createTeam(payload) {
    try {
      const result = yield call(create, payload.payload.values);
      yield put({
        type: actionTypes.GetList,
        payload: {
          role: "Admin",
        },
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.DeleteTeam, function* deleteSelectedTeam(
    payload
  ) {
    try {
      const result = yield call(deleteTeam, payload.payload);
      yield put({
        type: actionTypes.GetList,
        payload: {
          role: "Admin",
        },
      });
      toast.success(result.data.message);
      return yield put(
        actions.setState({
          meta: null,
        })
      );
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });
}
