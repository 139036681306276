import {
  createEnrolment,
  getAll,
  getOne,
  getPrevEnrolmentByParent,
  getFutureEnrolmentByParent,
  getEnrolmentByParent,
  getEnrolmentByClass,
  exportCSVFile,
  getGraph,
  addMessage,
  editMessage,
  deleteMessage,
  getUpcomingEnrolment,
  updateEnrolment,
  getCancelledAll,
  getSelectedPage,
  gettEnrolmentAmount,
  deleteLesson,
  getAllStudentsByLesson,
  getParentByMultipleClasses,
  getFilteredListData,
  getFilteredListGraphData,
  getFilteredListCompareData,
  getEnrolmentsByLocationOrVenue,
  getReportsByAge,
  getReportsCancellations,
  getUnpaidEnrolmentsFromLink,
  getEnrolmentsInFutureLessons,
  activeEnrolmentsReport,
  fullPaymentsEnrolmentsReport,
  getallenrolments,
  pendingPaymentsEnrolmentsReport,
  nextMonthPaymentsEnrolmentsReport,
  getHearAboutUsReports,
} from "../../services/enrolments";
import { loaderActions } from "../actions/loader";
import { actionTypes, actions } from "../actions/enrolment";
import { put, takeLatest, call, takeEvery } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    try {
      const result = yield call(getAll);
      return yield put(
        actions.setState({
          list: result.data.results,
          count: result.data.count,
        })
      );
    } catch (error) {
      console.log(error);
      console.log(error.response);
      if (error?.response?.data?.error) {
        return toast.error(error.response.data.error);
      }
    }
  });

  yield takeLatest(actionTypes.GetAllEnrolment, function* getList({payload}) {
    try {
      const result = yield call(getallenrolments, payload);
      return yield put(
        actions.setState({
          allActiveEnrolments: result.data.results,
        })
      );
    } catch (error) {
      console.log(error);
      console.log(error.response);
      if (error?.response?.data?.error) {
        return toast.error(error.response.data.error);
      }
    }
  });

  yield takeLatest(actionTypes.GetActiveEnrolmentReport, function* getList() {
    try {
      const result = yield call(activeEnrolmentsReport);
      console.log("12345678765check", result)
      return yield put(
        actions.SetActiveEnrolmentReport({
          activeEnrolmentsReports: result.data,
        })
      );
    } catch (error) {
      console.log(error);
      console.log(error.response);
      if (error?.response?.data?.error) {
        return toast.error(error.response.data.error);
      }
    }
  });

  yield takeLatest(actionTypes.GetFullPaymentsEnrolmentReport, function* getList() {
    try {
      const result = yield call(fullPaymentsEnrolmentsReport);
      return yield put(
        actions.setState({
          fullPaymentsEnrolmentsReports: result.data,
        })
      );
    } catch (error) {
      console.log(error);
      console.log(error.response);
      if (error?.response?.data?.error) {
        return toast.error(error.response.data.error);
      }
    }
  });


  yield takeLatest(actionTypes.GetPendingPaymentsEnrolmentReport, function* getList() {
    try {
      const result = yield call(pendingPaymentsEnrolmentsReport);
      return yield put(
        actions.setState({
          pendingPaymentsEnrolmentsReports: result.data,
        })
      );
    } catch (error) {
      console.log(error);
      console.log(error.response);
      if (error?.response?.data?.error) {
        return toast.error(error.response.data.error);
      }
    }
  });

  yield takeLatest(actionTypes.GetNextMonthPaymentsEnrolmentReport, function* getList() {
    try {
      const result = yield call(nextMonthPaymentsEnrolmentsReport);
      return yield put(
        actions.setState({
          nextMonthPaymentsEnrolmentsReports: result.data,
        })
      );
    } catch (error) {
      console.log(error);
      console.log(error.response);
      if (error?.response?.data?.error) {
        return toast.error(error.response.data.error);
      }
    }
  });

  yield takeLatest(
    actionTypes.GetEnrolmentsOfFutureLessons,
    function* getEnrolmentsOfFutureLessons({ payload }) {
      try {
        yield put(
          actions.SetLoadingFutureEnrolments({
            loading: true,
          })
        );
        const result = yield call(getEnrolmentsInFutureLessons, payload);
        yield put(
          actions.SetLoadingFutureEnrolments({
            loading: false,
          })
        );
        return yield put(
          actions.setState({
            futureLessonEnrolments: result.data,
          })
        );
      } catch (error) {
        yield put(
          actions.SetLoadingFutureEnrolments({
            loading: false,
          })
        );
        yield put(
          actions.setState({
            futureLessonEnrolments: [],
          })
        );
        console.log(error);
        console.log(error.response);
        if (error?.response?.data?.error) {
          return toast.error(error.response.data.error);
        }
      }
    }
  );

  yield takeLatest(actionTypes.GetAllUnpaidPaymentsLink, function* getList() {
    try {
      const result = yield call(getUnpaidEnrolmentsFromLink);
      return yield put(
        actions.SetReportsByCancellations({
          unpaidPayments: result.data,
        })
      );
    } catch (error) {
      console.log(error);
      console.log(error.response);
      if (error?.response?.data?.error) {
        return toast.error(error.response.data.error);
      }
    }
  });

  yield takeLatest(actionTypes.GetSelectedPage, function* getSelectedPageList(
    payload
  ) {
    yield put(
      actions.SetLoadingEnrolments({
        loading: true,
      })
    );
    try {
      const result = yield call(getSelectedPage, payload);
      yield put(
        actions.SetSelectedPage({
          pageEnrolments: result?.data?.results,
          count: result?.data?.count,
        })
      );
      yield put(
        actions.SetLoadingEnrolments({
          loading: false,
        })
      );
    } catch (error) {
      yield put(
        actions.SetLoadingEnrolments({
          loading: false,
        })
      );
      return toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.GetFilteredList, function* getFilteredList(
    payload
  ) {
    try {
      yield put(
        actions.SetFilteredList({
          loading: true,
        })
      );
      const result = yield call(getFilteredListData, payload);
      return yield put(
        actions.SetFilteredList({
          progress: { ...result.data, type: payload.payload.type },
          loading: false,
        })
      );
    } catch (error) {
      yield put(
        actions.SetFilteredList({
          loading: false,
        })
      );
      return toast.error(error.response.data.error);
    }
  });

  yield takeLatest(
    actionTypes.GetFilteredGraphData,
    function* getFilteredListGraph(payload) {
      try {
        yield put(
          actions.SetFilteredGraphData({
            loading: true,
          })
        );
        const result = yield call(getFilteredListGraphData, payload);
        return yield put(
          actions.SetFilteredGraphData({
            graphData: result.data,
            loading: false,
          })
        );
      } catch (error) {
        yield put(
          actions.SetFilteredGraphData({
            loading: false,
          })
        );
        return toast.error(error.response.data.error);
      }
    }
  );

  yield takeLatest(
    actionTypes.GetFilteredCompareData,
    function* getFilteredListCompare(payload) {
      try {
        yield put(
          actions.SetFilteredCompareData({
            loading: true,
          })
        );
        const result = yield call(getFilteredListCompareData, payload);
        return yield put(
          actions.SetFilteredCompareData({
            compareData: result.data,
            loading: false,
          })
        );
      } catch (error) {
        yield put(
          actions.SetFilteredCompareData({
            loading: false,
          })
        );
        return toast.error(error.response.data.error);
      }
    }
  );

  yield takeLatest(
    actionTypes.GetCancelledList,
    function* getFullCancelledList() {
      try {
        const result = yield call(getCancelledAll);
        return yield put(
          actions.SetCancelledList({
            cancelledList: result.data.results,
          })
        );
      } catch (error) {
        return toast.error(error.response.data.error);
      }
    }
  );

  yield takeLatest(actionTypes.AddMessage, function* addNewMessage(payload) {
    try {
      const result = yield call(addMessage, payload.payload);

      toast.success(result.data.message);

      return yield put(
        actions.setSelected({
          selected: result.data.results,
        })
      );
    } catch (error) {
      return toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.EditMessage, function* editNewMessage(payload) {
    try {
      const result = yield call(editMessage, payload.payload);

      toast.success(result.data.message);

      return yield put(
        actions.setSelected({
          selected: result.data.results,
        })
      );
    } catch (error) {
      return toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.DeleteMessage, function* deleteNewMessage(
    payload
  ) {
    try {
      const result = yield call(deleteMessage, payload.payload);

      toast.success(result.data.message);

      return yield put(
        actions.setSelected({
          selected: result.data.results,
        })
      );
    } catch (error) {
      return toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.ExportCsvFile, function* exportCsvFileItem(
    payload
  ) {
    try {
      toast.success("Your download will start shortly!");
      const result = yield call(exportCSVFile, payload);
      return window.open(result.data.results, "_blank");
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.GetSelected, function* getSelected(payload) {
    try {
      const result = yield call(getOne, payload);

      return yield put(
        actions.setSelected({
          selected: result.data.results,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.GetEnrolmentByParent, function* getEnrolmentList(
    payload
  ) {
    try {
      const result = yield call(getEnrolmentByParent, payload);

      return yield put(
        actions.SetEnrolmentByParent({
          enrolmentsByParent: result.data.results,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.GetGraph, function* getGraphList(payload) {
    try {
      const result = yield call(getGraph, payload.payload);
      return yield put(
        actions.setGraph({
          graphs: result.data.results,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(
    actionTypes.GetUpcomingEnrolment,
    function* getUpcomingEnrolmentList(payload) {
      try {
        const result = yield call(getUpcomingEnrolment, payload.payload);

        return yield put(
          actions.setState({
            list: result.data.results,
            count: result.data.count,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  );

  yield takeLatest(actionTypes.GetEnrolmentByClass, function* getEnrolmentList(
    payload
  ) {
    try {
      const result = yield call(getEnrolmentByClass, payload.payload);

      return yield put(
        actions.SetEnrolmentByClass({
          enrolmentsByClass: result.data.results,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(
    actionTypes.GetPrevEnrolmentByParent,
    function* getEnrolmentList(payload) {
      try {
        const result = yield call(getPrevEnrolmentByParent, payload);

        return yield put(
          actions.SetPrevEnrolmentByParent({
            prevEnrolmentsByParent: result.data.results,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  );

  yield takeLatest(
    actionTypes.GetFutureEnrolmentByParent,
    function* getEnrolmentList(payload) {
      try {
        const result = yield call(getFutureEnrolmentByParent, payload);

        return yield put(
          actions.SetFutureEnrolmentByParent({
            futureEnrolmentsByParent: result.data.results,
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  );

  yield takeLatest(
    actionTypes.CreateEnrolment,
    function* CreateEnrolmentPublicPages({ payload }) {
      console.log(payload);
      try {
        const result = yield call(createEnrolment, payload.values);

        if (
          payload.values.paymentOption === "monthly" &&
          payload.values.type === "childcare"
        ) {
          if (result?.data?.createdTransactions.length === 0) {
            yield put(
              actions.setState({
                childcareMessage: `We've got your request to book into Childcare Fun Soccer.<br/>
              You first payment will be billed on the first Sunday in January.`,
              })
            );
          } else if (
            result?.data?.createdTransactions.some(
              (transaction) =>
                transaction.reason === "Booking monthly installment."
            )
          ) {
            yield put(
              actions.setState({
                childcareMessage: `We've got your request to book into Childcare Fun Soccer.
              We've billed you for the remaining sessions in the month (up to the first Sunday of next month).<br/>
              All future payments will be billed on the first Sunday of each month.`,
              })
            );
          } else if (
            result?.data?.createdTransactions.some(
              (transaction) =>
                transaction.reason !== "Booking monthly installment." ||
                transaction.reason === "Product Purchase"
            )
          ) {
            yield put(
              actions.setState({
                childcareMessage: `We've got your request to book into Childcare Fun Soccer.
              You first payment will be billed on the first Sunday in January.<br/>
              We've taken payment for your new soccer kit, and we'll be sending it out to you soon.`,
              })
            );
          }
        }
        if (
          payload.values.paymentOption === "full" &&
          payload.values.type === "childcare"
        ) {
          if (
            result?.data?.createdTransactions.some(
              (transaction) =>
                transaction.reason === "Booking Payment Paid in Full."
            ) &&
            payload.values.childs &&
            payload.values.childs.some(
              (child) =>
                child.merchandise === "yes" && parseInt(child.quantity) > 0
            )
          ) {
            yield put(
              actions.setState({
                childcareMessage: `Thanks for your booking. We've emailed you the details.We've got your request to book into Childcare Fun Soccer.<br/>`,
              })
            );
          }
        }

        toast.success("Enrolment successfully created!");

        // resetForm(initialFormValues);

        payload.resetFormValues.resetForm(
          payload.resetFormValues.initialFormValues
        );
      } catch (error) {
        console.log(error);
        //       console.log(error.response);
        //       setSpin(false);
        //       console.log(error.response);
        //       if (error?.response?.data && error?.response?.data?.message) {
        //         setEnrolmentError(error.response.data.message);
        //         return toast.error(error.response.data.message);
        //       }
        //       if (
        //         error?.response?.data?.code === 11000 &&
        //         error?.response?.data?.name === "MongoError"
        //       ) {
        //         return toast.error("Email already exist.");
        //       }
        //       toast.error(
        //         "Enrolment cannot be created right now. Please try after some time."
        //       );
        //     });
      }
    }
  );

  yield takeLatest(
    actionTypes.GetEnrolmentAmount,
    function* enrolmentAmountToPay({ payload }) {
      try {
        const result = yield call(gettEnrolmentAmount, payload);
        return yield put(
          actions.setState({
            totalAmountToPay: result.data.totalAmountToPay,
          })
        );
      } catch (error) {}
    }
  );

  yield takeLatest(
    actionTypes.UpdateEnrolment,
    function* UpdateSingleEnrolment({ payload }) {
      try {
        const result = yield call(updateEnrolment, payload);

        if (payload.changeEnrolmentStatus) {
          toast.success(
            `Status updated successfully to ${payload.enrolmentStatus}`
          );

          yield put({
            type: actionTypes.GetEnrolmentByParent,
            parentId: payload.parent._id,
          });

          yield put({
            type: actionTypes.GetList,
          });
        } else {
          toast.success(result.data.message);
        }

        yield put({
          type: actionTypes.GetEnrolmentByParent,
          parentId: payload.parent._id,
        });

        return;
      } catch (error) {
        if (
          error.response.data.code === 11000 &&
          error.response.data.name === "MongoError"
        ) {
          return toast.error("Email already exist.");
        }
        if (error?.response?.data?.error) {
          toast.error(error?.response?.data?.error);
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        }
        return yield put(
          actions.setState({
            meta: {
              list: {
                loading: false,
              },
            },
          })
        );
      }
    }
  );

  yield takeLatest(actionTypes.ShowModal, function* showModal() {
    yield put(
      actions.setState({
        show: true,
      })
    );
  });

  yield takeLatest(actionTypes.HideModal, function* hideModal() {
    yield put(
      actions.setState({
        show: false,
      })
    );
  });

  yield takeLatest(
    actionTypes.DeleteLesson,
    function* deleteSelectedLessonFromEnrolment(payload) {
      try {
        const result = yield call(deleteLesson, payload.payload);

        toast.success(result.data.message);

        return yield put(
          actions.setSelected({
            selected: result.data.updatedEnrolment,
          })
        );
      } catch (error) {
        return toast.error(error.response.data.error);
      }
    }
  );

  yield takeLatest(
    actionTypes.GetEnrolmentsOfALesson,
    function* getStudentsOfALesson({ payload }) {
      try {
        const result = yield call(getAllStudentsByLesson, payload);

        return yield put(
          actions.setState({
            enrolmentInLesson: result.data,
          })
        );
      } catch (error) {
        if (error?.response?.data?.error) {
          return toast.error(error.response.data.error);
        }
      }
    }
  );

  yield takeLatest(
    actionTypes.GetParentsByMultipleClassesOrLocation,
    function* getAllParentsByClasses({ payload }) {
      yield put(
        actions.SetLoading({
          loading: true,
        })
      );
      try {
        const result = yield call(getParentByMultipleClasses, payload);

        yield put(
          actions.SetLoading({
            loading: false,
          })
        );
        return yield put(
          actions.setState({
            parents: result.data,
          })
        );
      } catch (error) {
        yield put(
          actions.SetLoading({
            loading: false,
          })
        );
        if (error?.response?.data?.error) {
          return toast.error(error.response.data.error);
        }
      }
    }
  );

  yield takeLatest(
    actionTypes.GetEnrolmentsByLocationOrVenue,
    function* getEnrolmentsByLocation() {
      yield put(
        actions.SetEnrolmentByLocationLoader({
          loading: true,
        })
      );
      try {
        const result = yield call(getEnrolmentsByLocationOrVenue);
        yield put(
          actions.SetEnrolmentByLocationLoader({
            loading: false,
          })
        );

        return yield put(
          actions.SetEnrolmentsByLocationOrVenue({
            enrolmentsByLocation: result.data,
          })
        );
      } catch (error) {
        yield put(
          actions.SetEnrolmentByLocationLoader({
            loading: false,
          })
        );

        if (error?.response?.data?.error) {
          return toast.error(error.response.data.error);
        }
      }
    }
  );

  yield takeLatest(actionTypes.GetReportsByAge, function* fetchReportsByAge(
    payload
  ) {
    yield put(
      actions.SetEnrolmentByLocationLoader({
        loading: true,
      })
    );
    try {
      const result = yield call(getReportsByAge, payload);
      yield put(
        actions.SetEnrolmentByLocationLoader({
          loading: false,
        })
      );

      return yield put(
        actions.SetReportsByAge({
          reportsByAge: result.data,
        })
      );
    } catch (error) {
      yield put(
        actions.SetEnrolmentByLocationLoader({
          loading: false,
        })
      );

      if (error?.response?.data?.error) {
        return toast.error(error.response.data.error);
      }
    }
  });

  yield takeLatest(
    actionTypes.GetReportsByCancellations,
    function* fetchReportsCancellations(payload) {
      yield put(
        actions.SetEnrolmentByLocationLoader({
          loading: true,
        })
      );
      try {
        const result = yield call(getReportsCancellations, payload);

        yield put(
          actions.SetEnrolmentByLocationLoader({
            loading: false,
          })
        );

        console.log(result.data);
        // cancelledInSelectedPeriod

        const child_no_longer_interested = result?.data?.cancelledEnrolmentsInSelectedPeriod?.filter(
          (reason) => reason.child_no_longer_interested
        )?.length;
        const coach = result?.data?.cancelledEnrolmentsInSelectedPeriod?.filter(
          (reason) => reason.coach && !reason.child_no_longer_interested
        )?.length;
        const customer_service = result?.data?.cancelledEnrolmentsInSelectedPeriod?.filter(
          (reason) =>
            reason.customer_service &&
            !reason.child_no_longer_interested &&
            !reason.coach
        )?.length;
        const price = result?.data?.cancelledEnrolmentsInSelectedPeriod?.filter(
          (reason) =>
            reason.price &&
            !reason.customer_service &&
            !reason.child_no_longer_interested &&
            !reason.coach
        )?.length;
        const other = result?.data?.cancelledEnrolmentsInSelectedPeriod?.filter(
          (reason) =>
            reason.other &&
            reason.other_reason &&
            !reason.price &&
            !reason.customer_service &&
            !reason.child_no_longer_interested &&
            !reason.coach
        )?.length;

        const total =
          other + price + customer_service + coach + child_no_longer_interested;

        const no_reason_given =
          parseInt(result?.data?.cancelledInSelectedPeriod) - total;
        // reportsObject["other_reason"];
        return yield put(
          actions.SetReportsByCancellations({
            reportsByCancellations: {
              ...result.data,
              createdData: {
                other,
                price,
                customer_service,
                coach,
                child_no_longer_interested,
                no_reason_given,
              },
            },
          })
        );
      } catch (error) {
        console.log(error);
        yield put(
          actions.SetEnrolmentByLocationLoader({
            loading: false,
          })
        );

        yield put(
          actions.SetReportsByCancellations({
            reportsByCancellations: {},
          })
        );

        if (error?.response?.data?.error) {
          return toast.error(error.response.data.error);
        }
      }
    }
  );

  yield takeLatest(actionTypes.GetHearAboutUsReport, function* getHearAboutUsReport(
    payload
  ) {
    yield put(
      actions.SetHearAboutUsReportLoading({
        loading: true,
      })
    );
    try {
      const result = yield call(getHearAboutUsReports, payload);
      yield put(
        actions.SetHearAboutUsReportLoading({
          loading: false,
        })
      );
      return yield put(
        actions.SetHearAboutUsReport({
          hearAboutUsReport: result?.data,
        })
      );
    } catch (error) {
      yield put(
        actions.SetHearAboutUsReportLoading({
          loading: false,
        })
      );
      console.log(error);
    }
  });
}
