import {
  getAll,
  create,
  updateSelected,
  deleteOrder,
  getSelected,
  changeStatus,
  exportCSVFile,
} from "../../services/orders";
import { actionTypes, actions } from "../actions/orders";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList() {
    try {
      const result = yield call(getAll);
      return yield put(
        actions.setState({
          list: result.data,
          // meta: null,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.GetSelectedOrder, function* getSelectedOrder(
    payload
  ) {
    try {
      const result = yield call(getSelected, payload.payload);
      if (result)
        return yield put(
          actions.SetSelectedOrder({
            selected: result.data,
          })
        );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.ExportCsvFile, function* exportCsvFileItem(
    payload
  ) {
    try {
      toast.success("Your download will start shortly!");
      const result = yield call(exportCSVFile, payload);
      return window.open(result.data.results, "_blank");
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.CreateOrders, function* createOrder(payload) {
    yield put(
      actions.SetLoader({
        loading: true,
      })
    );
    try {
      const result = yield call(create, payload.payload);

      yield put({
        type: "products/SET_CART",
        payload: {
          cart: [],
        },
      });

      if (payload?.payload?.publicShop) {
        yield put(
          actions.SetLoader({
            loading: false,
          })
        );
        payload.history.push(
          `/${payload?.payload?.uuid}/public-shop/thankyou/${payload.payload.business}`
        );
      } else {
        yield put(
          actions.SetLoader({
            loading: false,
          })
        );
        yield put({
          type: actionTypes.GetList,
        });
        if (payload.payload.fromParentShop) {
          payload.history.push(`/thankyou/order/${payload.payload.business}`);
        }
      }

      toast.success("Order successfully created.");
    } catch (error) {
      yield put(
        actions.SetLoader({
          loading: false,
        })
      );
      if (error?.response?.data?.error) {
        toast.error(error?.response?.data?.error);
      } else {
        toast.error("Something went wrong. Please try after some time.");
      }
    }
  });

  yield takeLatest(actionTypes.DeleteOrders, function* deleteSelectedOrder(
    payload
  ) {
    try {
      const result = yield call(deleteOrder, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
      return yield put(
        actions.setState({
          meta: null,
        })
      );
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.ChangeStatus, function* chngeOrderStatus(
    payload
  ) {
    try {
      const result = yield call(changeStatus, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });

      yield put({
        type: actionTypes.GetSelectedOrder,
        payload: { id: payload.payload.orderId },
      });

      toast.success(result.data.message);
      return yield put(
        actions.setState({
          meta: null,
        })
      );
    } catch (error) {
      toast.error("Something went wrong. Please try after some time.");
    }
  });

  yield takeLatest(actionTypes.UpdateOrder, function* updateSelectedOrder(
    payload
  ) {
    try {
      const result = yield call(updateSelected, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });
}
