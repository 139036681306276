import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "../../components/Toastr";
import ParentShopLeft from "../ParentPortal/ParentShop/components/ParentShopLeft";
import ParentShopRight from "../ParentPortal/ParentShop/components/ParentShopRight";

const ParentShop = ({ products, parent, cart, match }) => {
  console.log(products);
  const dispatch = useDispatch();
  const history = useHistory();
  const [filteredProducts, setFilteredProducts] = useState([]);

  const [selectedSizes, setSelectedSizes] = useState([]);
  const [selectedQuantity, setSelectedQuantity] = useState([]);

  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    dispatch({
      type: "products/GET_PRODUCTS_BY_UUID",
      uuid: match.params.uuid,
    });
  }, []);

  useEffect(() => {
    if (cart) {
      setCartItems([...cart]);
    } else {
      setCartItems([]);
    }
  }, [cart]);

  useEffect(() => {
    if (Array.isArray(products)) {
      console.log({ products });
      let tempProducts = products?.filter(
        (prod) =>
          prod.business && prod.business.toLowerCase() === match.params.business
      );

      tempProducts = [...tempProducts];
      let tempArray = new Array(tempProducts.length).fill("");

      setSelectedQuantity(tempArray);
      setSelectedSizes(tempArray);
      // setFilteredProducts(tempProducts);
      setFilteredProducts(tempProducts);
    }
  }, [products]);

  const addToCart = ({ qty, size, product }) => {
    let tempItems = [...cartItems];
    tempItems.push({ qty, size, product });
    dispatch({
      type: "products/SET_CART",
      payload: {
        cart: tempItems,
      },
    });
  };

  const removeFromCart = ({ qty, size, product, index }) => {
    let tempItems = [...cartItems];
    tempItems.splice(index, 1);
    dispatch({
      type: "products/SET_CART",
      payload: {
        cart: tempItems,
      },
    });
  };

  return (
    <>
      <div className="row" style={{ padding: "20px" }}>
        <div className="col-md-8 col-lg-9">
          <ParentShopLeft
            filteredProducts={filteredProducts}
            selectedSizes={selectedSizes}
            setSelectedSizes={setSelectedSizes}
            selectedQuantity={selectedQuantity}
            setSelectedQuantity={setSelectedQuantity}
            toast={toast}
            addToCart={addToCart}
          />
        </div>
        <div className="col-md-4 col-lg-3">
          <ParentShopRight
            cartItems={cartItems}
            dispatch={dispatch}
            history={history}
            toast={toast}
            removeFromCart={removeFromCart}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  products: state.products.publicProducts,
  cart: state.products.cart,
  parent: state.auth.user,
});

export default connect(mapStateToProps)(ParentShop);
