export const actionTypes = {
  GetList: "lessons/GET_LIST",
  GetLessonsByEnrolments:"lessons/GET_LESSONS_BY_ENROLMENTS",
  SetAllLessonsByEnrolment: "lessons/SET_LESSONS_BY_ENROLMENTS",
  GetGroupList: "lessons/GET_GROUP_LIST",
  SetState: "lessons/SET_STATE",
  CreateLessons: "lessons/CREATE_LESSONS",
  UpdateLessons: "lessons/UPDATE_LESSONS",
  GetLessonsByLocation: "lessons/GET_LESSONS_BY_LOCATION",
  GetTutorLessons: "lessons/GET_TUTOR_LESSONS",
  SetTutorLessons: "lessons/SET_TUTOR_LESSONS",
  GetLessonsByClass: "lessons/GET_LESSONS_BY_CLASS",
  SetLessonsByClass: "lessons/SET_LESSONS_BY_CLASS",
  GetSelectedLesson: "lessons/GET_SELECTED_LESSON",
  SetSelectedLesson: "lessons/SET_SELECTED_LESSON",
  DeleteLesson: "lessons/DELETE_LESSON",
  AddMessage: "lessons/ADD_MESSAGE",
  EditMessage: "lessons/EDIT_MESSAGE",
  DeleteMessage: "lessons/DELETE_MESSAGE",
  GetUpcoming: "lessons/GET_UPCOMING",
  SetUpcoming: "lessons/SET_UPCOMING",
  GetToday: "lessons/GET_TODAY",
  SetToday: "lessons/SET_TODAY",
  GetLessonsbyTutor: "lessons/GET_LESSONS_BY_TUTOR",
  SetLessonsbyTutor: "lessons/SET_LESSONS_BY_TUTOR",
  GetLessonsReportByDate: "lessons/GET_LESSONS_REPORT_BY_DATE",
  SetLessonsReportByDate: "lessons/SET_LESSONS_REPORT_BY_DATE",
  SetReportsLoading: "lessons/SET_REPORTS_LOADING",
  SetLoaderLessons: "lessons/SET_LOADER_LESSONS",
  SetPreviousLessons: "lessons/SET_PREVIOUS_LESSONS",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  SetAllLessonsByEnrolment: (payload) => ({ type: actionTypes.SetAllLessonsByEnrolment, payload }),
  SetPreviousLessons: (payload) => ({
    type: actionTypes.SetPreviousLessons,
    payload,
  }),
  SetLoaderLessons: (payload) => ({
    type: actionTypes.SetLoaderLessons,
    payload,
  }),
  SetReportsLoading: (payload) => ({
    type: actionTypes.SetReportsLoading,
    payload,
  }),
  SetLessonsReportByDate: (payload) => ({
    type: actionTypes.SetLessonsReportByDate,
    payload,
  }),
  CreateLessons: (payload) => ({ type: actionTypes.CreateLessons, payload }),
  UpdateLessons: (payload) => ({ type: actionTypes.UpdateLessons, payload }),
  SetTutorLessons: (payload) => ({
    type: actionTypes.SetTutorLessons,
    payload,
  }),
  GetLessonsByLocation: (payload) => ({
    type: actionTypes.GetLessonsByLocation,
    payload,
  }),
  GetUpcoming: (payload) => ({
    type: actionTypes.GetUpcoming,
    payload,
  }),
  SetUpcoming: (payload) => ({
    type: actionTypes.SetUpcoming,
    payload,
  }),
  GetToday: (payload) => ({
    type: actionTypes.GetToday,
    payload,
  }),
  SetToday: (payload) => ({
    type: actionTypes.SetToday,
    payload,
  }),
  GetLessonsByClass: (payload) => ({
    type: actionTypes.GetLessonsByClass,
    payload,
  }),
  SetLessonsByClass: (payload) => ({
    type: actionTypes.SetLessonsByClass,
    payload,
  }),

  GetLessonsByEnrolments: (payload) => ({
    type: actionTypes.GetLessonsByEnrolments,
    payload,
  }),

  GetSelectedLesson: (payload) => ({
    type: actionTypes.GetSelectedLesson,
    payload,
  }),
  SetSelectedLesson: (payload) => ({
    type: actionTypes.SetSelectedLesson,
    payload,
  }),
  DeleteLesson: (payload) => ({
    type: actionTypes.DeleteLesson,
    payload,
  }),
  AddMessage: (payload) => ({
    type: actionTypes.AddMessage,
    payload,
  }),
  EditMessage: (payload) => ({
    type: actionTypes.EditMessage,
    payload,
  }),
  DeleteMessage: (payload) => ({
    type: actionTypes.DeleteMessage,
    payload,
  }),
  GetLessonsbyTutor: (payload) => ({
    type: actionTypes.GetLessonsbyTutor,
    payload,
  }),
  SetLessonsbyTutor: (payload) => ({
    type: actionTypes.SetLessonsbyTutor,
    payload,
  }),
};
