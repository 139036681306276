import baseApi from "../api/baseApi";

// const authToken = () =>
//   JSON.parse(localStorage.getItem("persist:auth"))
//     ? JSON.parse(localStorage.getItem("persist:auth")).authToken
//     : "";

// baseApi.defaults.headers.common["Authorization"] = authToken()
//   ? JSON.parse(authToken()).token
//   : "";

export function getAll({ payload }) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  if (payload && payload.page && payload.limit) {
    return baseApi.get(`/classes/all`, {
      params: {
        page: payload.page,
        limit: payload.limit,
        type: payload?.type,
        keyword: payload.keyword,
        service: payload.service,
      },
    });
  } else {
    return baseApi.get(`/classes/all`);
  }
}

export function getSearchedClasses({ payload }) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get(`/classes/search-classes`, {
    params: {
      keyword: payload.keyword,
      service: payload.service,
      page: payload.page,
      limit: payload.limit,
      coach: payload.coach,
      type: payload?.type,
      date: payload?.date,
    },
  });
}

export function getClassesByService({ payload }) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  if (Array.isArray(payload?.service)) {
    return baseApi.get(`/classes/getClassesByServiceMulti`, {
      params: {
        services: payload?.service,
      },
    });
  } else {
    console.log({ payload });
    return baseApi.get(`/classes/getClassesByService/${payload.service}`, {
      params: { classStatus: payload?.classStatus, enrolmentPage: payload?.enrolmentPage, filter: payload?.filter, pageType: payload?.pageType },
    });
  }
}

export function getClassesByServiceEnrolment({ payload }) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  if (Array.isArray(payload?.service)) {
    return baseApi.get(`/classes/getClassesByServiceMulti`, {
      params: {
        services: payload?.service,
      },
    });
  } else {
    console.log({ payload });
    return baseApi.get(`/classes/getOnlyClassesByService/${payload.service}`, {
      params: { classStatus: payload?.classStatus, enrolmentPage: payload?.enrolmentPage, filter: payload?.filter, pageType: payload?.pageType },
    });
  }
}

export function getLessonsByClassesEnrolment({ payload }) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

    console.log({ payload });
    return baseApi.get(`/classes/getClassById/${payload.classId}`);
}

export function getClassesByServiceForCommunications({ payload }) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  if (Array.isArray(payload?.service)) {
    return baseApi.get(`/classes/getClassesByServiceMultiForCommunications`, {
      params: {
        services: payload?.service,
      },
    });
  } else {
    console.log({ payload });
    return baseApi.get(`/classes/getClassesByService/${payload.service}`, {
      params: { filter: payload?.filter },
    });
  }
}

export function duplicateRecord({ payload }) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get(`/classes/duplicate/${payload.id}`);
}

export function exportCSVFile(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get(`/classes/exportcsvfile/${payload.payload}`);
}

export function getOne({ payload }) {
  return baseApi.get(`/classes/get/${payload?.classId}`);
}

export function getAllClassesByLocation(payload) {
  console.log(payload.term);
  if (payload.tag || payload.term) {
    return baseApi.get(
      `/classes/location/${payload.locationId}/${payload.uuid}/${payload.type}`,
      {
        params: { tag: payload.tag, term: payload.term },
      }
    );
  } else {
    return baseApi.get(
      `/classes/location/${payload.locationId}/${payload.uuid}/${payload.type}`
    );
  }
}

export function getByOrgIdAndLocation(payload) {
  console.log(payload.term);
  if (payload.tag || payload.term) {
    return baseApi.get(
      `/classes/getByOrgIdAndLocation/${payload.locationId}/${payload.id}/${payload.type}`,
      {
        params: { tag: payload.tag, term: payload.term },
      }
    );
  } else {
    return baseApi.get(
      `/classes/getByOrgIdAndLocation/${payload.locationId}/${payload.id}/${payload.type}`
    );
  }
}

export function getAllLessonsByClass(payload) {
  const authToken = () =>
    localStorage && JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get(`/classes/getlessonsbyclass/${payload.classId}`);
}

export function getClassesByLocationPrivate(payload) {
  const authToken = () =>
    localStorage && JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get(
    `/classes/getClassesByLocationOrVenue/${payload.locationId}/${payload.type}`
  );
}

// export function getById(payload) {
//   return baseApi.get(`/lessons/get/${payload.id}`);
// }

export function createClass({ payload }) {
  const authToken = () =>
    localStorage && JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.post("/classes/create", payload);
}

export function sendCSVFile(payload) {
  let params = new FormData();
  params.append("csvFile", payload.csvFile);
  params.append("classType", payload.classType);

  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.post(`/classes/sendcsvfile`, params);
}

// export function updateLesson(payload) {
//   return baseApi.put(`lessons/update/${payload.eventData.id}`, payload);
// }

// export function deleteLesson(payload) {
//   return baseApi.delete(`lessons/delete/${payload.id}`);
// }

export function getSessionAll(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  if (payload && payload.services) {
    return baseApi.get(`/classes/sessionreport`, {
      params: { services: payload.services , 
        type: payload.type
      },
    });
  } else {
    return baseApi.get("/classes/sessionreport");
  }
}

export const getClassesWithEnrolPercentage = (payload) => {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get(`/classes/allClassAccToEnrolments`, {
    params: {
      filter: payload?.filter,
      service: payload?.service,
    },
  });
};


export const getAllClassesByActiveEnrolments = (payload) => {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get(`/classes/allClassAccToActiveEnrolments`);
};
