export const actionTypes = {
  GetList: "inflatable/GET_LIST",
  GetGroupList: "inflatable/GET_GROUP_LIST",

  GetPastInflatable: "inflatable/GET_PAST_LIST",
  SetPastInflatable: "inflatable/SET_PAST_LIST",

  GetFutureInflatable: "inflatable/GET_UPCOMING_LIST",
  SetFutureInflatable: "inflatable/SET_UPCOMING_LIST",

  SetState: "inflatable/SET_STATE",
  CreateInflatable: "inflatable/CREATE_INFLATABLE",
  DeleteInflatable: "inflatable/DELETE_INFLATABLE",

  GetSelectedInflatable: "inflatable/GET_SELECTED_INFLATABLE",
  SetSelectedInflatable: "inflatable/SET_SELECTED_INFLATABLE",

  GetLocationSurcharge: "inflatable/GET_LOCATION_SURCHARGE",
  SetLocationSurcharge: "inflatable/SET_LOCATION_SURCHARGE",

  GetCancelledList: "inflatable/GET_CANCELLED_LIST",
  SetCancelledList: "inflatable/SET_CANCELLED_LIST",

  UpdateInflatable: "inflatable/UPDATE_INFLATABLE",
  SetLoader: "inflatable/SET_LOADER",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  SetLoader: (payload) => ({ type: actionTypes.SetLoader, payload }),
  CreateInflatable: (payload) => ({
    type: actionTypes.CreateInflatable,
    payload,
  }),
  UpdateInflatable: (payload) => ({
    type: actionTypes.UpdateInflatable,
    payload,
  }),
  GetSelectedInflatable: (payload) => ({
    type: actionTypes.GetSelectedInflatable,
    payload,
  }),
  SetSelectedInflatable: (payload) => ({
    type: actionTypes.SetSelectedInflatable,
    payload,
  }),
  GetList: (payload) => ({
    type: actionTypes.GetList,
    payload,
  }),
  GetPastInflatable: (payload) => ({
    type: actionTypes.GetPastInflatable,
    payload,
  }),
  SetPastInflatable: (payload) => ({
    type: actionTypes.SetPastInflatable,
    payload,
  }),
  GetFutureInflatable: (payload) => ({
    type: actionTypes.GetFutureInflatable,
    payload,
  }),
  SetFutureInflatable: (payload) => ({
    type: actionTypes.SetFutureInflatable,
    payload,
  }),
  GetLocationSurcharge: (payload) => ({
    type: actionTypes.GetLocationSurcharge,
    payload,
  }),
  SetLocationSurcharge: (payload) => ({
    type: actionTypes.SetLocationSurcharge,
    payload,
  }),

  GetCancelledList: (payload) => ({
    type: actionTypes.GetCancelledList,
    payload,
  }),
  SetCancelledList: (payload) => ({
    type: actionTypes.SetCancelledList,
    payload,
  }),
};
