import { actionTypes } from "../actions/coupon";

const initialCouponReducer = {
  list: [],
  selected: {},
  meta: {},
};

const couponReducer = (state = initialCouponReducer, action) => {
  switch (action.type) {
    case actionTypes.SetState: {
      const { list } = action.payload;
      return {
        ...state,
        list,
      };
    }

    case actionTypes.SetSelectedCoupon: {
      return {
        ...state,
        selected: { ...action.payload.selected },
      };
    }

    default:
      return state;
  }
};

export default couponReducer;
