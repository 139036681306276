export const actionTypes = {
  GetAgeList: 'age/GET_LIST',
  SetAgeList: 'age/SET_LIST',
  CreateAge: 'age/CREATE_AGE',
  DeleteAge: 'age/DELETE_AGE',
  GetSelectedAge: 'age/GET_SELECTED_AGE',
  SetSelectedAge: 'age/SET_SELECTED_AGE',
  UpdateAge: 'age/UPDATE_AGE',
}

export const actions = {
  GetAgeList: (payload) => ({
    type: actionTypes.GetAgeList,
    payload,
  }),
  SetAgeList: (payload) => ({
    type: actionTypes.SetAgeList,
    payload,
  }),
  CreateAge: (payload) => ({
    type: actionTypes.CreateAge,
    payload,
  }),
  DeleteAge: (payload) => ({
    type: actionTypes.DeleteAge,
    payload,
  }),
  GetSelectedAge: (payload) => ({
    type: actionTypes.GetSelectedAge,
    payload,
  }),
  SetSelectedAge: (payload) => ({
    type: actionTypes.SetAgeList,
    payload,
  }),
}
