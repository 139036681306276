import {
  exportAllTransaction,
  refundReports,
} from "../../services/transaction";
import { actionTypes, actions } from "../actions/transaction";
import { put, takeLatest, call } from "redux-saga/effects";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList({ payload }) {
    yield put(
      actions.SetLoader({
        loading: true,
      })
    );
    try {
      const result = yield call(exportAllTransaction, payload);

      yield put(
        actions.SetLoader({
          loading: false,
        })
      );

      return yield put(
        actions.setState({
          list: result.data,
        })
      );
    } catch (error) {
      yield put(
        actions.SetLoader({
          loading: false,
        })
      );
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.GetReportList, function* getReport({ payload }) {
    yield put(
      actions.SetReportLoader({
        loading: true,
      })
    );
    try {
      const result = yield call(refundReports, payload);

      yield put(
        actions.SetReportLoader({
          loading: false,
        })
      );

      return yield put(
        actions.setState({
          refundReport: result.data,
        })
      );
    } catch (error) {
      yield put(
        actions.SetReportLoader({
          loading: false,
        })
      );
      console.log(error);
    }
  });
}
