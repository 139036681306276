export const actionTypes = {
  GetList: "teams/GET_LIST",
  GetGroupList: "teams/GET_GROUP_LIST",
  SetState: "teams/SET_STATE",
  CreateTeam: "teams/CREATE_TEAM",
  EditTeam: "teams/EDIT_TEAM",
  DeleteTeam: "teams/DELETE_TEAM",
  GetSelectedTeam: "teams/GET_SELECTED_TEAM",
  UpdateTeam: "teams/UPDATE_TEAM",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  createTeam: (payload) => ({
    type: actionTypes.CreateTeam,
    payload,
  }),
  editTeam: (payload) => ({
    type: actionTypes.EditTeam,
    payload,
  }),
  getSelectedTeam: (payload) => ({
    type: actionTypes.GetSelectedTeam,
    payload,
  }),
  getList: (payload) => ({
    type: actionTypes.GetList,
    payload,
  }),
};
