export const actionTypes = {
  GetList: "crons/GET_LIST",
  SetState: "crons/SET_STATE",
  GetSelectedCron: "crons/GET_SELECTED_CRON",
  UpdateCrons: "crons/UPDATE_CRONS",
};

export const actions = {
  setState: (payload) => ({ type: actionTypes.SetState, payload }),

  getSelectedCoupon: (payload) => ({
    type: actionTypes.GetSelectedCron,
    payload,
  }),
  UpdateCoupon: (payload) => ({
    type: actionTypes.UpdateCrons,
    payload,
  }),
};
