export const actionTypes = {
  GetList: "trials/GET_LIST",
  CreateTrials: "trials/CREATE_TRIALS",
  ShowModal: "trials/SHOW_MODAL",
  HideModal: "trials/HIDE_MODAL",
  SetState: "trials/SET_STATE",
  SetLoading: "trials/SET_LOADING",
  GetAllList: "trials/GET_ALL_LISTS",
  SetAllTrailsState: "trials/SET_ALL_TRAILS",
  GetTrialsReportsData: "trials/GET_TRIALS_REPORTS_DATA",
  SetTrialsReportsData: "trials/SET_TRIALS_REPORTS_DATA",
  SetReportsLoad: "trials/SET_REPORTS_LOAD",
};

export const actions = {
  CreateTrials: (payload) => ({
    type: actionTypes.CreateTrials,
    payload,
  }),
  setState: (payload) => ({ type: actionTypes.SetState, payload }),
  loading: (payload) => ({ type: actionTypes.SetLoading, payload }),
  SetAllTrailsState: (payload) => ({ type: actionTypes.SetAllTrailsState, payload }),
  SetReportsLoad: (payload) => ({ type: actionTypes.SetReportsLoad, payload }),
  SetTrialsReportsData: (payload) => ({
    type: actionTypes.SetTrialsReportsData,
    payload,
  }),
};
