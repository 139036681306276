import { createTrial, getAll, getAllTrials, getTrialReportsData } from "../../services/trials";
import { actionTypes, actions } from "../actions/trials";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList({ payload }) {
    yield put(
      actions.loading({
        loading: true,
      })
    );
    try {
      const result = yield call(getAll, payload);
      yield put(
        actions.loading({
          loading: false,
        })
      );
      return yield put(
        actions.setState({
          list: result.data,
        })
      );
    } catch (error) {
      yield put(
        actions.loading({
          loading: false,
        })
      );
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.GetAllList, function* getAllList({ payload }) {
    try {
      const result = yield call(getAllTrials, payload);
      return yield put(
        actions.SetAllTrailsState({
          allList: result.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.CreateTrials, function* CreateTrial({
    payload,
  }) {
    try {
      const result = yield call(createTrial, payload);
      toast.success(result.data.message);
      return yield put(
        actions.setState({
          groups: result.data,
          meta: null,
        })
      );
    } catch (error) {
      if (
        error.response.data.code === 11000 &&
        error.response.data.name === "MongoError"
      ) {
        return toast.error("Email already exist.");
      }
      toast.error(error.response.data.error);
      return yield put(
        actions.setState({
          meta: {
            list: {
              loading: false,
            },
          },
        })
      );
    }
  });

  yield takeLatest(actionTypes.ShowModal, function* showModal() {
    yield put(
      actions.setState({
        show: true,
      })
    );
  });

  yield takeLatest(actionTypes.HideModal, function* hideModal() {
    yield put(
      actions.setState({
        show: false,
      })
    );
  });

  yield takeLatest(actionTypes.GetTrialsReportsData, function* getTrialReportData({ payload }) {
    yield put(
        actions.SetReportsLoad({
          loading: true,
        })
      );
      try {
        const result = yield call(getTrialReportsData, payload);
        yield put(
          actions.SetReportsLoad({
            loading: false,
          })
        );
        return yield put(
          actions.SetTrialsReportsData({
            reportsData: result.data,
          })
        );
      } catch (error) {
        yield put(
          actions.SetReportsLoad({
            loading: false,
          })
        );
        toast.error(error.response.data.error);
      }
    });
}
