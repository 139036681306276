import {
  getAll,
  deleteFeedback,
  create,
  getSelected,
  updateSelected,
  changeStatus,
  getTutorList,
  getParentList,
} from "../../services/feedback";
import { actionTypes, actions } from "../actions/feedback";
import { put, takeLatest, call } from "redux-saga/effects";
import { toast } from "../../app/components/Toastr";

export default function* saga() {
  yield takeLatest(actionTypes.GetList, function* getList({ payload }) {
    try {
      const result = yield call(getAll, payload);
      return yield put(
        actions.SetState({
          list: result.data.results,
          count: result.data.count,
        })
      );
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.GetTutorList, function* getTutorFeedbackList(
    payload
  ) {
    try {
      const result = yield call(getTutorList, payload.payload);
      return yield put(
        actions.SetTutorList({
          tutorList: result.data,
        })
      );
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.GetParentList, function* getParentFeedbackList(
    payload
  ) {
    try {
      const result = yield call(getParentList, payload.payload);
      return yield put(
        actions.SetParentList({
          parentList: result.data,
        })
      );
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.UpdateFeedback, function* updateSelectedFeedback(
    payload
  ) {
    try {
      const result = yield call(updateSelected, payload.payload);
      if (payload.payload.feedback_table == undefined) {
        yield put({
          type: actionTypes.GetList,
        });
      }
      if (payload.payload.tutorId) {
        yield put({
          type: actionTypes.GetTutorList,
          payload: {
            id: payload.payload.tutorId,
          },
        });
      }

      if (payload.payload.parentId) {
        yield put({
          type: actionTypes.GetParentList,
          payload: {
            id: payload.payload.parentId,
          },
        });
      }
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.ChangeStatus, function* changeStatusOfFeedback(
    payload
  ) {
    try {
      const result = yield call(changeStatus, payload.payload);
      if (payload.payload.feedback_table == undefined) {
        yield put({
          type: actionTypes.GetList,
        });
      }
      if (payload.payload.parentId) {
        yield put({
          type: actionTypes.GetParentList,
          payload: {
            id: payload.payload.parentId,
          },
        });
      }
      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(
    actionTypes.GetSelectedFeedback,
    function* getSelectedFeedback(payload) {
      try {
        const result = yield call(getSelected, payload.payload);
        return yield put(
          actions.getSelectedFeedback({
            selected: result.data,
          })
        );
      } catch (error) {
        console.log(error.response);
      }
    }
  );

  yield takeLatest(actionTypes.CreateFeedback, function* createFeedback(
    payload
  ) {
    try {
      const result = yield call(create, payload.payload);
      yield put({
        type: actionTypes.GetParentList,
        payload: {
          id: payload.payload.parent,
        },
      });

      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });

  yield takeLatest(actionTypes.DeleteFeedback, function* deleteSelectedFeedback(
    payload
  ) {
    try {
      const result = yield call(deleteFeedback, payload.payload);
      yield put({
        type: actionTypes.GetList,
      });

      toast.success(result.data.message);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  });
}
