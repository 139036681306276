import moment from "moment";
import baseApi from "../api/baseApi";
import { DATE_FORMAT } from "../app/Common/commonMethods";

// const authToken = () =>
//   JSON.parse(localStorage.getItem("persist:auth"))
//     ? JSON.parse(localStorage.getItem("persist:auth")).authToken
//     : "";

// baseApi.defaults.headers.common["Authorization"] = authToken()
//   ? JSON.parse(authToken()).token
//   : "";

export function getAll({ payload }) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  if (payload && payload.business !== undefined) {
    return baseApi.get("/students/all", {
      params: {
        business: payload.business,
        page: payload.pagination.page,
        limit: payload.pagination.limit,
      },
    });
  } else {
    if (
      !JSON.parse(authToken()).token ||
      JSON.parse(authToken()).token === undefined
    ) {
      baseApi.defaults.headers.common["Authorization"] = payload.auth;
    }
    return baseApi.get("/students/all");
  }
}

export function getBirthdayList({ payload }) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get("/students/get-student-birthday-list", {
    params: {
      startDate: moment(payload.startDate, "YYYY-MM-DD").format(DATE_FORMAT),
      endDate: moment(payload.endDate, "YYYY-MM-DD").format(DATE_FORMAT),
      business: payload.business,
    },
  });
}

export const searchStudent = ({ payload }) => {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get("/students/student-search", {
    params: {
      business: payload.business,
      search: payload.search,
    },
  });
};

export function getSelected(payload) {
  if (payload.id) {
    const authToken = () =>
      JSON.parse(localStorage.getItem("persist:auth"))
        ? JSON.parse(localStorage.getItem("persist:auth")).authToken
        : "";

    baseApi.defaults.headers.common["Authorization"] = authToken()
      ? JSON.parse(authToken()).token
      : "";
    return baseApi.get(`/students/get/${payload.id}`);
  }
}

export function addMessage(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.post("/students/addmessage", payload);
}

export function editMessage(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.post("/students/editmessage", payload);
}

export function deleteMessage(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.post("/students/deletemessage", payload);
}

export function exportCSVFile(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get(`/students/exportcsvfile/${payload.payload}`);
}

export function create(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.post(`/students/create`, payload);
}

export function getStudentsByParent(payload) {
  console.log({ payload });
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.get(`/students/parent/${payload.parentId}`);
}

export function deleteStudent(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.delete(`/students/delete/${payload.id}`);
}

export function updateSelected(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.put(`/students/update/${payload.id}`, payload);
}
