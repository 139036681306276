import baseApi from "../api/baseApi";

export function getAll() {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get("/settings/all");
}

export function getSelected({ payload }) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get(`/settings/get`, {
    params: { business: payload.business },
  });
}

export function getSelectedSetting({ payload }) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get(`/settings/getSetting`, {
    params: { business: payload.business },
  });
}

export function getSimpleSetting({ payload }) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.get(`/settings/getsimple`);
}

export function getMailChimpList() {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";
  return baseApi.get(`/settings/getmailchimp`);
}

export function getMailChimpSelected(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get(`/settings/getmailchimplist/${payload.payload}`);
}

export function getsimplePublicSettings({ payload }) {
  return baseApi.get(
    `/settings/getpublic/${payload.organization}/${payload.business}`
  );
}

export function getLogoAndLinks({ payload }) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.get(`/settings/getLogoAndLinks`, {
    params: { business: payload.business },
  });
}

export function getPublicSettings({ payload }) {
  console.log({ payload });
  return baseApi.get(`/settings/get/${payload.uuid}/${payload.business}`);
}

export function create(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  let formdata = new FormData();
  for (let key in payload) {
    formdata.append(key, payload[key]);
  }

  return baseApi.post(`/settings/update`, formdata);
}

export function deleteStudent(payload) {
  const authToken = () =>
    JSON.parse(localStorage.getItem("persist:auth"))
      ? JSON.parse(localStorage.getItem("persist:auth")).authToken
      : "";

  baseApi.defaults.headers.common["Authorization"] = authToken()
    ? JSON.parse(authToken()).token
    : "";

  return baseApi.delete(`/settings/delete/${payload.id}`);
}

export function getDistance(payload) {
  return baseApi.get(`/calculate-distance`, { params: payload });
}
